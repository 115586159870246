import styles from './style.module.scss';

import emptyDesktop from "../../../../assets/backgronds/emptyPage/EmptyDesktop.webp";
import {useLocation, useNavigate} from "react-router-dom";


const EmptyPageDesktop = ({lang}) => {
    const location = useLocation().pathname;
    const navigate = useNavigate();

    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <h1>
                    {lang[location].heading}
                </h1>
            </div>
            <div className={styles.flex_row_divs}>
                <div className={styles.paragraph_div}>
                    <p>
                        {lang[location].paragraph}
                    </p>
                    <button
                        className={styles.back_btn}
                        onClick={() => {
                            navigate("/catalog")
                        }}
                    >
                        {lang[location].buyBtn}
                    </button>
                </div>
                <img src={emptyDesktop} alt={"img"}/>
            </div>
        </div>
    );
};

export default EmptyPageDesktop;