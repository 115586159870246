import styles from "./style.module.scss";

import specialOffersImg from "../../../../assets/icons/homePage/commonIcons/specialOffers.png";

import {useEffect, useState} from "react";

import en from "../../../../assets/lang/en/HomePage/HeroSection/en.json";
import am from "../../../../assets/lang/am/HomePage/HeroSection/en.json";
import ru from "../../../../assets/lang/ru/HomePage/HeroSection/en.json";

import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import SearchBar from "../../../molecul/Layout/SearchBar";
import {CategorySection} from "../index";

const HeroSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);

    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);


    const navigate = useNavigate();


    if (isMobile) {
        return (
            <div className={styles.hero_section_mobile}>
                <div className={styles.container_mobile}>
                    <div className={styles.left_mobile}>
                        <div className={styles.search_bar_div}>
                            <SearchBar/>
                        </div>
                        <div
                            className={styles.raw_section_heading_mobile}
                            onClick={() => {
                                navigate("/catalog", {
                                    state: {
                                        productState: "raw"
                                    }
                                })
                            }}
                        >
                            <div>
                                <hr/>
                                <h2>
                                    {langObj.rawSectionHeading}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_mobile}>
                        <div
                            className={styles.cooked_section_heading_mobile}
                            onClick={() => {
                                navigate("/catalog", {
                                    state: {
                                        productState: "cooked"
                                    }
                                })
                            }}
                        >
                            <div>
                                <h2>
                                    {langObj.cookedSectionHeading}
                                </h2>
                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>
                <CategorySection />
            </div>
        );
    }

    return (
        <div className={styles.hero_section}>
            <div className={styles.container}>

                <div className={styles.left}>
                    {/*<img src={rawImage} alt={'rawImage'} className={styles.raw_img}/>*/}
                    <div className={styles.left_section}>
                        <div className={styles.raw_nav_div}>
                            <button
                                className={styles.special_offers_btn}
                                onClick={() => {
                                    navigate('/catalog', {
                                        state: {
                                            specialOffers: true,
                                        }
                                    });
                                }}
                            >
                                <img src={specialOffersImg} alt={'specialOffers'}/>
                                <span>{langObj.specialOffersBtn}</span>
                            </button>
                            <span onClick={() => {
                                navigate('/catalog')
                            }}>
                                {langObj.menuBtn}
                            </span>
                            <span onClick={() => {
                                navigate('/about_us')
                            }}>
                                {langObj.aboutPageBtn}
                            </span>
                            <span onClick={() => {
                                navigate('/contact_us')
                            }}
                            >
                                {langObj.contactsBtn}
                            </span>
                            <span>
                                {langObj.blogBtn}
                            </span>

                        </div>
                        <div
                            className={styles.raw_section_heading}
                            onClick={() => {
                                navigate("/catalog", {
                                    state: {
                                        productState: "raw"
                                    }
                                })
                            }}
                        >
                            <div>
                                <hr/>
                                <h2>
                                    {langObj.rawSectionHeading}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.right}>
                    <div
                        className={styles.cooked_section_heading}
                        onClick={() => {
                            navigate("/catalog", {
                                state: {
                                    productState: "cooked"
                                }
                            })
                        }}
                    >
                        <div>
                            <h2>
                                {langObj.cookedSectionHeading}
                            </h2>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
            <CategorySection/>
        </div>
    )
        ;
};

export default HeroSection;