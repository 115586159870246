import {useDispatch, useSelector} from "react-redux";
import { openPopup } from "../../../redux/features/popup/openLoginPopupSlice";
import { LoginSection } from "../LoginPage";
import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/Login/en.json";
import am from "../../../assets/lang/am/Login/en.json";
import ru from "../../../assets/lang/ru/Login/en.json";


const LoginPopUp = () => {
    const dispatch = useDispatch();
    const closePopup = () => {
        dispatch(openPopup({
            popupOpen: false,
            fromNavbar: ''
        }));
    };

    useEffect(() => {
        document.body.style.overflow = "hidden"; // disable scrolling on the page
        const enableScroll = () => {
            document.body.style.overflow = "auto";
        }
        return enableScroll;// re-enable scrolling on the page

    }, []);

    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);
    return(
        <div className={styles.popupContainer} onClick={closePopup}>
            <div className={styles.popupBody} onClick={(e) => {e.stopPropagation()}}>
               <LoginSection closePopup={closePopup} lang={langObj}/>
            </div>
        </div>
    )
}

export default LoginPopUp;