import {useSelector} from "react-redux";
import CartSectionDesktop from "./Desktop";
import CartSectionMobile from "./Mobile";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/CartPage/en.json";
import am from "../../../assets/lang/am/CartPage/en.json";
import ru from "../../../assets/lang/ru/CartPage/en.json";
const CartSection = () => {

    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, [])
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    if (isMobile){
        return(
            <CartSectionMobile langObj={langObj}/>
        )
    }else{
        return (
            <CartSectionDesktop langObj={langObj}/>
        )
    }
};

export default  CartSection;