import {useSelector} from 'react-redux';

import {useQuery, gql} from '@apollo/client';

import DesktopNavbar from './Desktop';
import MobileNavbar from './Mobile';
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/layout/Navbar/en.json";
import am from "../../../assets/lang/am/layout/Navbar/en.json";
import ru from "../../../assets/lang/ru/layout/Navbar/en.json";

const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
    }
    userFavoriteProducts(userId: $userId){
        _id
        productId
      }
  }
`;


const Navbar = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const _id = isAuthenticated ? stateUser.user._id : '';

    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const {loading, data, error} = useQuery(GET_CART_ITEMS, {
        variables: {userId: _id}
    });

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);

    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);
    if (isMobile) {
        return (
            <MobileNavbar
                isAuthenticated={isAuthenticated}
                data={data}
                loading={loading}
                error={error}
                lang={langObj}
            />
        );
    }
    ;

    return (
        <DesktopNavbar
            isAuthenticated={isAuthenticated}
            data={data}
            loading={loading}
            error={error}
            langPack={langObj}
        />
    );
};


export default Navbar;