import styles from './style.module.scss';
import CheckoutProductSectionDesktop from "../../CheckoutPage/ProductSection/Desktop";
import closeIcon from '../../../../assets/icons/OrdersPage/closeIcon.svg';
import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import CheckoutProductSectionMobile from "../../CheckoutPage/ProductSection/Mobile";

const OrderPopUp = ({data, closePopup}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    useEffect(() => {
        document.body.style.overflow = "hidden"; // disable scrolling on the page
        const enableScroll = () => {
            document.body.style.overflow = "auto";
        }
        return enableScroll;// re-enable scrolling on the page

    }, []);

    return (
        <div className={styles.popup_container} onClick={closePopup}>
            <div className={isMobile ? styles.popup_body_mobile : styles.popup_body} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.close_btn_div}>
                    <button onClick={closePopup}>
                        <img src={closeIcon} alt={'close'}/>
                    </button>
                </div>
                {
                    isMobile
                        ?
                        <div className={styles.mobile_product_section}>
                            <CheckoutProductSectionMobile data={data}/>
                        </div>
                        :
                        <CheckoutProductSectionDesktop data={data}/>
                }
            </div>
        </div>
    )
};

export default OrderPopUp