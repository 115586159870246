import styles from './style.module.scss';

import {useForm} from "../../../hooks/useForm";
import {useEffect, useState} from 'react';
import {RegistrationSection, LoginSection, VerificationSection} from '../../molecul/LoginPage';
import {useRegisterUser} from '../../../hooks/login/useRegister';
import {useParams} from 'react-router-dom';
import { useSelector} from 'react-redux';
import en from "../../../assets/lang/en/Login/en.json";
import am from "../../../assets/lang/am/Login/en.json";
import ru from "../../../assets/lang/ru/Login/en.json";
import useSendOtp from "../../../hooks/login/verification/useSendOtp";
import ForgotPassword from "../../molecul/LoginPage/ForgotPassword";


const SignInSection = () => {

    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    let section;
    const [sectionNumber, setSectionNumber] = useState(1);
    const routerPath = useParams().router;

    useEffect(() => {
        if (routerPath === "registration") {
            setSectionNumber(2);
        }else if(routerPath === "forgotPassword"){
            setSectionNumber(4);
        }
    }, [routerPath])

    const handleChangeSection = async () => {
       await registerUser()
    }

    const {changeHandler, setInputValue, onSubmit, values} = useForm(handleChangeSection, {
        fullName: '',
        tel: '',
        email: '',
        password: '',
        otpCode: ''
    });
    const {registerUser} = useRegisterUser({values});
    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);

   const verifyCallback = () => {
       setSectionNumber(3);
   }

   const {sendOtp, smsLoading} = useSendOtp({tel: values.tel, callback: verifyCallback})

    const asyncSendFunction = async () => {
        if(!smsLoading){
            await sendOtp();
        }
    };
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    switch (sectionNumber) {
        case 1:
            section = <LoginSection
                lang={langObj}
                changeSection={(number) => {
                    setSectionNumber(number)
                }}
            />;
            break;
        case 2:
            section = <RegistrationSection
                lang={langObj}
                changeSection={(number) => {
                    setSectionNumber(number)
                }}
                changeHandler={changeHandler}
                values={values}
                onSubmit={onSubmit}
                setInputValue={setInputValue}
                asyncSendFunction={asyncSendFunction}
            />;
            break;
        case 3:
            section = <VerificationSection
                lang={langObj}
                onSubmit={onSubmit}
                changeSection={(number) => {
                    setSectionNumber(number)
                }}
                setInputValue={setInputValue}
                values={values}
                asyncSendFunction={asyncSendFunction}
            />;
            break;
        case 4:
            section = <ForgotPassword
                lang={langObj}
                changeSection={(number) => {
                    setSectionNumber(number)
                }}
            />;
            break;
        default:
            break;
    }
    return (

        <div className={isMobile ? styles.signin_section_mobile : styles.signin_section}>
            {section}
        </div>
    );
};

export default SignInSection;
