import OrdersSection from "../../component/organism/Orders";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const Orders = () => {
    const lang = useSelector(state => state.globalLang.lang);
    return(
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.orders : lang === "RU" ? ru.orders : en.orders}</title>
                <meta name="description" content="
                    The Meat House-ի Կայքի օգտատիրոջ պատվերները
                "/>
            </Helmet>
            <OrdersSection />
        </div>
    );
};

export default Orders;