import styles from './style.module.scss';


import FaqSection from "../../../molecul/FaqSection";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import en from "../../../../assets/lang/en/HomePage/FaqSection/en.json";
import am from "../../../../assets/lang/am/HomePage/FaqSection/en.json";
import ru from "../../../../assets/lang/ru/HomePage/FaqSection/en.json";
import {useLocation} from "react-router-dom";

const HomeFaqSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const location = useLocation().pathname;
    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    return (
        <div className={isMobile || location !== "/" ? styles.main_section + ' ' +  styles.without_background : styles.main_section }>
            <div className={isMobile ? styles.section_heading_mobile : styles.section_heading}>
                <div>
                    <h2>
                        {langObj.sectionHeading}
                    </h2>
                    <hr/>
                </div>
            </div>


            <FaqSection lang={langObj}/>

        </div>
    );
};

export default HomeFaqSection;