import {loginSuccess} from '../../../redux/features/user/userSlice';

import {useDispatch, useSelector} from 'react-redux';

import {gql} from '@apollo/client';

import {useMutation} from '@apollo/client';

import {openPopup} from '../../../redux/features/popup/openLoginPopupSlice';
import {notifyUser} from "../../../redux/features/popup/notifyMessage";


// Define the GraphQL mutation for logging in

const LOGIN_USER = gql`
    mutation Mutation(
        $loginInput: LoginInput
    ) {
        loginUser(
            loginInput: $loginInput
        ) {
            token 
        }
    }
`;


// Define the Redux Toolkit async thunk for logging in
export const useLoginUser = ({values, navigate, handleChangeLoadingStatus}) => {

    const popupState = useSelector(state => state.isPopupOpen.isPopupOpen);
    const lang = useSelector(state => state.globalLang.lang);

    const closePopup = () => {
        dispatch(openPopup({
            popupOpen: false,
            fromNavbar: ''
        }));
    };
    const fromNavbar = popupState.fromNavbar;
    const dispatch = useDispatch();
    // Use the Apollo Client to send a login mutation to the server
    const [loginUser, {loading}] = useMutation(LOGIN_USER, {
        update(proxy, {data: {loginUser: userData}}) {

            const token = userData.token;

            dispatch(loginSuccess(token));
            // Store the token in local storage
            localStorage.setItem('token', token);
            if (closePopup) {
                if (fromNavbar && fromNavbar !== "withoutRouting") {
                    navigate(fromNavbar)
                } else if (fromNavbar === "withoutRouting") {
                    closePopup();
                }
                closePopup();
            } else {
                navigate("/");
            }
        },
        onError({graphQLErrors}) {

            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                handleChangeLoadingStatus(false);
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {loginInput: values}
    })

    return {
        loginUser,
        loading
    };
};
