import OrderDoneSection from "../../component/organism/OrderDone";

const OrderDone = () => {

    return(
        <div>
            <OrderDoneSection/>
        </div>
    );
};

export default OrderDone;