import {useSelector} from 'react-redux';

import ProductSlider from "../../../../molecul/ProductSlider";
import styles from "../style.module.scss";
import {useEffect, useState} from "react";
import en from "../../../../../assets/lang/en/HomePage/ProductSection/en.json";
import am from "../../../../../assets/lang/am/HomePage/ProductSection/en.json";
import ru from "../../../../../assets/lang/ru/HomePage/ProductSection/en.json";


const CookedProductsSection = ({loading, error, data, userFavoriteProducts, userCartProducts}) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser =  useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';

    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);


    return(
        <div className={styles.main_section_cooked}>
            <div className={isMobile ? styles.cooked_section_heading_mobile : styles.cooked_section_heading}>
                <div>
                    <hr/>
                    <h2>
                        {langObj.headingCookedSection}
                    </h2>
                </div>
            </div>
            <ProductSlider
                data={data}
                loading={loading}
                error={error}
                products={data ? data : undefined}
                favorites={userFavoriteProducts ? userFavoriteProducts : undefined}
                cartProducts={userCartProducts ? userCartProducts : undefined}
                sliderHeading={langObj.headingCookedSection}
                state={"cooked"}

            />
        </div>
    );
};

export default CookedProductsSection;