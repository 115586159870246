import MobileFooter from "./Mobile";
import DesktopFooter from "./Desktop";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/layout/Footer/en.json";
import am from "../../../assets/lang/am/layout/Footer/en.json";
import ru from "../../../assets/lang/ru/layout/Footer/en.json";

const Footer = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);


    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);

    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    if(isMobile){
        return(
            <MobileFooter lang={langObj}/>
        )
    }
    return (
        <DesktopFooter lang={langObj}/>
    )

}

export default Footer;