import styles from "./style.module.scss";
import specialOffersEnabled from "../../../../assets/backgronds/catalog/specialOffersEnabled.png";
import specialOffersDisabled from "../../../../assets/backgronds/catalog/specialOffersDisabled.png";
import categoriesIcon from "../../../../assets/icons/catalogPage/Category.webp";

const SpecialOffersMenuMobile = ({value, changeState, openSideBar,lang}) => {
    return(
        <div className={styles.main_section}>
            <div
                onClick={() => {
                    changeState({
                        ...value,
                        specialOffers: !value.specialOffers
                    });
                }}
                className={value.specialOffers ?  styles.special_offers : styles.special_offers + " " + styles.enable_font_color}
            >
                {
                    value.specialOffers ?
                        <img src={specialOffersDisabled} alt={"specialOffers"}/>
                        :
                        <img src={specialOffersEnabled} alt={"specialOffers"}/>
                }
                <span>
                   {lang.specialOffers}
                </span>
            </div>

            <button
                className={styles.catalogBtn}
                onClick={() => {
                    openSideBar();
                }}
            >
                <img src={categoriesIcon} alt={"categoriesIcon"}/>
            </button>
        </div>
    );
};
export default SpecialOffersMenuMobile;