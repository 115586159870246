import styles from "./style.module.scss";
import paymentMethod from "../../../../assets/icons/CheckoutPage/PaymentMethod.png";
import idramIcon from "../../../../assets/icons/CheckoutPage/idram.png";
import telcellIcon from "../../../../assets/icons/CheckoutPage/telcell.png";

import {useLocation, useNavigate} from "react-router-dom";
import CheckoutProductSectionDesktop from "../../../molecul/CheckoutPage/ProductSection/Desktop";
import {useEffect, useRef, useState} from "react";
import CustomDatePicker from "../../../atom/CustomDatePicker";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import useRemoveCartItem from "../../../../hooks/product/cart/useRemoveCartItem";
import md5 from "blueimp-md5";
import {notifyUser} from "../../../../redux/features/popup/notifyMessage";
import deliveryIcon from "../../../../assets/icons/ProductPage/CommonIcons/deliveryIcon.png";
import VerificationPopUp from "../../../molecul/CheckoutPage/VerificationPopUp";
import useSendOtp from "../../../../hooks/login/verification/useSendOtp";


const CREATE_ORDER = gql`
    mutation Mutation(
        $orderInput: OrderInput
    ) {   
        createNewOrder(
          orderInput: $orderInput
        ) {
            _id
            orderNumber
            date
            totalPrice
            deliveryTime
            redirectUrl
        }
    }
`;

const GET_CART_ITEMS_WITH_IDS = gql`
  query($userId: ID!, $cartItemIds: [ID!]!) {
    userCartProductsWithIds(userId: $userId, _ids: $cartItemIds){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
    }
  }
`;

const CheckoutSectionDesktop = ({lang}) => {
        const paymentLang = useSelector(state => state.globalLang.lang);
        const {removeCartItem} = useRemoveCartItem();
        const navigate = useNavigate();
        const {state} = useLocation();
        const user = useSelector(state => state.user).user;
        const userId = user ? user._id : "without account";
        const [isVerifyPopUpOpen, setIsVerifyPopUpOpen] = useState(false)
        const dispatch = useDispatch();
        const navigateData = state && !state.productIds && state.map((item) => {
            delete item['__typename'];
            delete item['_id'];
            return item;
        });

        const [productData, setProductData] = useState(state && state.productIds ? [] : navigateData);
        const [totalPrice, setTotalPrice] = useState(0);


        const [getProducts, {error, loading, data, refetch}] = useLazyQuery(GET_CART_ITEMS_WITH_IDS, {
            fetchPolicy: 'network-only',
        });


        useEffect(() => {
            setTimeout(() => window.scrollTo(0, 0), 10);
            refetch();

            if ((!state || !state.productIds) && !navigateData) {
                navigate('/');
            }
            if ((productData === [] || (productData && productData.length === 0)) && !!state.productIds) {
                const cartIds = state.productIds;
                if (cartIds) {
                    setInitialState({
                        ...initialState, ['cartProductsIds']: cartIds,
                    });
                    getProducts({
                        variables: {
                            userId: userId, cartItemIds: cartIds,
                        }
                    })
                }

            } else if (state && state[0]) {
                const deliveryPrice = Number(state[0].price) >= 30000 ? 0 : 1000;
                setTotalPrice(Number(state[0].price) + deliveryPrice);
                setInitialState({
                    ...initialState, ['totalPrice']: Number(state[0].price) + deliveryPrice,
                });
            }
        }, []);


        const [selectedPaymentOption, setSelectedPaymentOption] = useState('withCash');
        const [formError, setFormError] = useState(false);


        const currentDate = new Date();


        const allFieldsRef = useRef([])

        const pushRef = (el) => {
            if (el && !allFieldsRef.current.includes(el)) {
                allFieldsRef.current.push(el);
            }
        };


        // date settings
        currentDate.setDate(currentDate.getDate());


// Minimum date: current date
        const minDate = currentDate.toISOString().substring(0, 10);

// Maximum date: end of next year

        currentDate.setHours(currentDate.getHours() + 2); // add 2 hours to the current time

// Minimum time: 2 hours ahead of the current time
        const minTime = currentDate.toTimeString().slice(0, 5);

        const productIds = state && !state.productIds ? state !== {} && state.map(item => item.productId) : '';
        const [initialState, setInitialState] = useState({
            userId: userId,
            productIds: productIds,
            totalPrice: totalPrice,
            productData: productData,
            userName: user ? user.fullName : '',
            paymentMethod: selectedPaymentOption,
            tel: user ? user.tel : '',
            email: user ? user.email : '',
            region: 'Yerevan',
            address: '',
            deliveryTime: dayjs(minDate + "T" + minTime).$d.toLocaleString(),
            notes: '',
        })
        const changeHandler = event => {
            setInitialState({...initialState, [event.target.name]: event.target.value});
        };

        const changeNumber = (telNumber) => {
            if (telNumber.length === 9 && telNumber.slice(0, 1) === "0") {
                return "+374" + telNumber.slice(1);
            } else if (telNumber.length === 8 && telNumber.slice(0, 1) !== "0" && telNumber.slice(0, 4) !== "+374" && telNumber.slice(0, 2) !== "00") {
                return "+374" + telNumber;
            } else if (telNumber.length === 11 && telNumber.slice(0, 3) === "374") {
                return "+" + telNumber;
            } else if (telNumber.length === 12 && telNumber.slice(0, 4) === "+374") {
                return telNumber;
            } else if (telNumber.length === 13 && telNumber.slice(0, 5) === "00374") {
                return "+" + telNumber.slice(2);
            }
            return telNumber;
        }
        const checkFieldsValue = () => {
            allFieldsRef.current.map((el) => {
                if (!el.value && el.name !== 'email' && el.name !== 'tel' && !el.getElementsByTagName("input").tel) {
                    el.style.border = "1px solid red";
                } else if (el.name === 'email' && (!el.value || !isValidEmail(el.value))) {
                    el.style.border = "1px solid red";
                } else if (el.name === 'tel') {
                    const telChecked = changeNumber(el.value);
                    if (!telChecked || telChecked.length < 12 || telChecked.slice(0, 4) !== "+374") {
                        el.style.border = "1px solid red";
                    } else {
                        el.style.border = "1px solid #000000";
                    }
                } else {
                    el.style.border = "1px solid #000000";
                }
            });
        };
        useEffect(() => {
            if (!loading && data && data.userCartProductsWithIds) {
                setProductData(data.userCartProductsWithIds);
                let totalSum = 0
                data.userCartProductsWithIds.map(item => {
                    totalSum = totalSum + Number(item.price);

                });
                const deliveryPrice = totalSum >= 30000 ? 0 : 1000;
                setTotalPrice(totalSum + deliveryPrice);
                const cartData = JSON.parse(JSON.stringify(data.userCartProductsWithIds));
                cartData.map(object => {
                    delete object['_id'];
                    delete object['__typename'];
                });
                const ids = data && data.userCartProductsWithIds.map(item => item.productId);
                setInitialState({
                    ...initialState,
                    ['productData']: cartData,
                    ['productIds']: ids,
                    ['totalPrice']: totalSum + deliveryPrice
                });
            }
        }, [data, loading]);

        const handleOptionClick = (option) => {
            setSelectedPaymentOption(option);
            setInitialState({
                ...initialState, ['paymentMethod']: option,
            });
        };
        const isValidEmail = (email) => {
            return /\S+@\S+\.\S+/.test(email);
        };

        const inputChangeHandler = (e) => {
            changeHandler(e);
            if (formError) {
                checkFieldsValue();
            }
        };
        const [createNewOrder, {loading: btnDisabled}] = useMutation(CREATE_ORDER, {
            update(proxy, {data: {createNewOrder: orderData}}) {
                if (initialState.paymentMethod === "withCash") {
                    const handleBeforeUnload = () => {
                        window.history.replaceState({}, '', window.location.href);
                        window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                    };

                    window.addEventListener('beforeunload', handleBeforeUnload);
                    navigate("/order_done", {
                        state: {
                            navigateData: orderData,
                        }
                    });
                    if (state.productIds) {
                        state.productIds.map(async (cartItem) => {
                            await removeCartItem({
                                variables: {
                                    removeCartItemInput: {
                                        cartItemId: cartItem,
                                    },
                                },
                            });
                        });
                    }
                } else if (initialState.paymentMethod === "cashLess") {
                    const ameriaLanguage = paymentLang === "ENG" ? 'en' : paymentLang === "RU" ? 'ru' : 'am';
                    const redirectUrl = orderData.redirectUrl + ameriaLanguage
                    // Remove history entry and redirect to home page
                    const handleBeforeUnload = () => {
                        window.history.replaceState({}, '', window.location.href);
                        window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                    };

                    window.addEventListener('beforeunload', handleBeforeUnload);

                    window.location.assign(redirectUrl);
                }
            }, onError({graphQLErrors}) {

                if (graphQLErrors && graphQLErrors[0].message) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: graphQLErrors[0].message,
                    }));
                }
            }
        });
        const randomNumber = () => {
            return Math.floor(10000000 + Math.random() * 90000000);
        };
        const checkFalseValue = () => {
            const initialStateCopy = {...initialState};
            delete initialStateCopy["notes"];

            const containsFalsy = Object.values(initialStateCopy).some(item => !item || (Array.isArray(item) && item.length === 0));
            const telToCheck = changeNumber(initialState.tel);
            return containsFalsy || !isValidEmail(initialState.email) || telToCheck.length !== 12 || telToCheck.slice(0, 4) !== "+374";
        }
        const crateAnOrder = async () => {
            const telChecked = changeNumber(initialState.tel)
            if (checkFalseValue()) {
                const formTop = allFieldsRef.current[0].getBoundingClientRect().top;
                window.scrollTo(0, window.pageYOffset + formTop - 50);
                setFormError(true);
                checkFieldsValue();
            } else {
                if (initialState.paymentMethod === "withCash" && !btnDisabled) {
                    await createNewOrder({
                        variables: {
                            orderInput: {
                                ...initialState,
                                tel: telChecked,
                            },
                        },
                    })
                } else if (initialState.paymentMethod === "idram") {
                    let productTitle = "";
                    initialState.productData.map(item => {
                        productTitle = productTitle + item.productTitle.en + " ";
                    });
                    const transactionId = randomNumber();
                    setInitialState({
                        ...initialState,
                        transactionId: transactionId,
                    });
                    const data = {
                        EDP_LANGUAGE: paymentLang === "ENG" ? 'EN' : paymentLang === "RU" ? 'RU' : 'AM',
                        EDP_REC_ACCOUNT: "110002489",
                        EDP_DESCRIPTION: productTitle,
                        EDP_AMOUNT: initialState.totalPrice.toString(),
                        EDP_BILL_NO: transactionId.toString(),
                    };
                    // Create a new FormData object
                    const formData = new FormData();

                    // Append the data to the FormData object
                    for (const key in data) {
                        formData.append(key, data[key]);
                    }

                    // Create a hidden form element
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = 'https://banking.idram.am/Payment/GetPayment'; // Replace with the actual bank URL

                    // Append the FormData object to the form
                    for (const [key, value] of formData) {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    }

                    document.body.appendChild(form);
                    await createNewOrder({
                        variables: {
                            orderInput: {
                                ...initialState,
                                tel: telChecked,
                                transactionId: transactionId,
                            },
                        },
                    });
                    // Remove history entry and redirect to home page
                    const handleBeforeUnload = () => {
                        window.history.replaceState({}, '', window.location.href);
                        window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                    };

                    window.addEventListener('beforeunload', handleBeforeUnload);
                    await form.submit();
                } else if (initialState.paymentMethod === 'telcell') {
                    let productTitle = "";
                    initialState.productData.map(item => {
                        productTitle = productTitle + item.productTitle.en + " ";
                    });
                    const transactionId = randomNumber();
                    setInitialState({
                        ...initialState,
                        tel: telChecked,
                        transactionId: transactionId,
                    });
                    const data = {
                        action: "PostInvoice",
                        issuer: "meathouse.am@gmail.com",
                        currency: "֏",
                        price: initialState.totalPrice.toString(),
                        product: btoa(JSON.stringify(productTitle)),
                        issuer_id: btoa(JSON.stringify(transactionId)),
                        valid_days: "1",
                        lang: paymentLang === "ENG" ? 'en' : paymentLang === "RU" ? 'ru' : 'am',
                        security_code: md5(
                            "FjesAKOE8UxZsbWU6fuSuazQbCr1CVWeMaZLZ9v2wKLbCsjgPKiPF9dPpL94dNp8rxdGTlbjqSwcKhkp4Sm9Zkfv1XijFLDmwsnqQgfhpnlMwPXY5iIEpGlJtnN13bhb"
                            + "meathouse.am@gmail.com"
                            + "֏"
                            + initialState.totalPrice.toString()
                            + btoa(JSON.stringify(productTitle))
                            + btoa(JSON.stringify(transactionId))
                            + "1"
                        )
                    };

                    // Create a new FormData object
                    const formData = new FormData();

                    // Append the data to the FormData object
                    for (const key in data) {
                        formData.append(key, data[key]);
                    }

                    // Create a hidden form element
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = 'https://telcellmoney.am/invoices'; // Replace with the actual bank URL

                    // Append the FormData object to the form
                    for (const [key, value] of formData) {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    }

                    document.body.appendChild(form);
                    await createNewOrder({
                        variables: {
                            orderInput: {
                                ...initialState,
                                tel: telChecked,
                                transactionId: transactionId,
                            },
                        },
                    });
                    // Remove history entry and redirect to home page
                    const handleBeforeUnload = () => {
                        window.history.replaceState({}, '', window.location.href);
                        window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                    };

                    window.addEventListener('beforeunload', handleBeforeUnload);

                    await form.submit();
                } else if (initialState.paymentMethod === "cashLess") {
                    await createNewOrder({
                        variables: {
                            orderInput: {
                                ...initialState,
                                tel: telChecked,
                            },
                        },
                    });
                }
            }
        }

        const openPopUp = () => {
            setIsVerifyPopUpOpen(true)
        }
        const telToCheck = changeNumber(initialState.tel);
        const {sendOtp, smsLoading} = useSendOtp({tel: telToCheck, callback: openPopUp})

        const asyncSendFunction = async () => {
            if(!smsLoading){
                await sendOtp();
            }
        };
        const onSubmit = async () => {
            const telChecked = changeNumber(initialState.tel);
            if (user && user.tel === telChecked) {
                await crateAnOrder();
            } else {
                if (checkFalseValue()) {
                    const formTop = allFieldsRef.current[0].getBoundingClientRect().top;
                    window.scrollTo(0, window.pageYOffset + formTop - 50);
                    setFormError(true);
                    checkFieldsValue();
                } else {
                    if (!smsLoading) await sendOtp();
                }
            }
        }
        const CheckedTelSpan = () => {
            const telChecked = changeNumber(initialState.tel);
            if (formError && (telChecked.length !== 12 || telChecked.slice(0, 4) !== "+374") && telChecked.length !== 0) {
                return (
                    <span>{lang.validPhoneNumber}</span>
                )
            }
        }

        return (
            <div className={styles.cart_section}>
                <div className={styles.main_order_section}>
                    <div className={styles.first_section}>
                        <div className={styles.section_heading}>
                            <div>
                                <hr/>
                                <h2>
                                    {lang.heading}
                                </h2>
                            </div>
                        </div>
                        <div className={styles.delivery_price}>
                            <img src={deliveryIcon} alt={'delivery'} className={styles.delivery_icon}/>
                            {
                                totalPrice >= 30000
                                    ?
                                    lang.free
                                    :
                                    1000 + " ֏"
                            }
                        </div>
                        <CheckoutProductSectionDesktop data={productData}/>
                    </div>
                    <div className={styles.payment_method_section}>
                        <div className={styles.order_heading}>
                            <img src={paymentMethod} alt={"orderIcon"}/>
                            {lang.paymentMethod}
                        </div>
                        <div className={styles.with_cash_div} onClick={() => {
                            handleOptionClick('withCash');
                        }}>
                            <label className={styles.radio_btn + ' ' + styles.radio_margin}>
                                <input name="radio" type="radio" checked={selectedPaymentOption === "withCash"}
                                       readOnly={true}/>
                                <span>  {lang.cash}</span>
                            </label>
                        </div>
                        <div className={styles.cash_less_div} onClick={() => {
                            handleOptionClick('cashLess');
                        }}>
                            <label className={styles.radio_btn + ' ' + styles.radio_margin}>
                                <input type="radio" checked={selectedPaymentOption === "cashLess"} readOnly={true}/>
                                <span> {lang.cashless}</span>
                            </label>

                        </div>
                        <div className={styles.cash_less_idram_div} onClick={() => {
                            handleOptionClick('idram');
                        }}>
                            <label className={styles.radio_btn}>
                                <input name="radio" type="radio" checked={selectedPaymentOption === "idram"}
                                       readOnly={true}/>
                                <span></span>
                            </label>
                            <img src={idramIcon} alt={"idramIcon"}/>
                        </div>
                        <div className={styles.cash_less_idram_div} onClick={() => {
                            handleOptionClick('telcell');
                        }}>
                            <label className={styles.radio_btn}>
                                <input type="radio" checked={selectedPaymentOption === "telcell"} readOnly={true}/>
                                <span/>
                            </label>
                            <img src={telcellIcon} alt={"telcellIcon"}/>
                        </div>
                    </div>
                </div>
                <div className={styles.order_form_heading}>
                    {lang.formHeading}
                </div>
                <div className={styles.order_form}>
                    <div className={styles.row_inputs_div}>
                        <div className={styles.input_div}>
                            <label>
                                {lang.formName}
                            </label>
                            <input
                                maxlength="20"
                                type={"text"}
                                name={"userName"}
                                value={initialState.userName}
                                onChange={(event) => {
                                    const input = event.target.value;
                                    setInitialState({
                                        ...initialState,
                                        [event.target.name]: input.replace(/[^A-Za-zА-Яа-яԱ-Ֆա-ֆ\s]/g, '')
                                    });
                                    if (formError) {
                                        checkFieldsValue();
                                    }
                                }}
                                defaultValue={user ? user.fullName : ''}
                                ref={pushRef}
                            />
                            {formError && initialState.userName.length === 0 &&
                                <span>{lang.requiredField}</span>}
                        </div>
                        <div className={styles.input_div}>
                            <label>
                                {lang.formNumber}
                            </label>
                            <div className={styles.input_div}>
                                <input
                                    maxlength="13"
                                    type={"text"}
                                    placeholder={"+374XXXXXXXX"}
                                    name={"tel"}
                                    ref={pushRef}
                                    value={initialState.tel}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        if (formError) {
                                            checkFieldsValue();
                                        }
                                        setInitialState({...initialState, "tel": input.replace(/[^0-9+]/g, '')});
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === '.') e.preventDefault();
                                        else if (
                                            e.target.value.length > 12
                                            && e.key !== 'Backspace'
                                            && e.key !== 'Control'
                                            && e.key !== 'a'
                                            && e.key !== 'ArrowLeft'
                                            && e.key !== 'ArrowRight'
                                        ) e.preventDefault();
                                    }}
                                />
                            </div>
                            {formError && initialState.tel.length === 0 && <span>{lang.requiredField}</span>}
                            <CheckedTelSpan/>
                        </div>
                        <div className={styles.input_div}>
                            <label>
                                {lang.formEmail}
                            </label>
                            <input
                                type={"text"}
                                name={"email"}
                                onChange={inputChangeHandler}
                                defaultValue={user ? user.email : ''}
                                ref={pushRef}
                            />
                            {formError && initialState.email.length === 0 && <span>{lang.requiredField}</span>}
                            {formError && !isValidEmail(initialState.email) && initialState.email.length !== 0 &&
                                <span>{lang.validEmail}</span>
                            }
                        </div>
                    </div>


                    <div className={styles.row_inputs_div}>
                        <div className={styles.input_div}>
                            <label>
                                {lang.formRegion}
                            </label>
                            <input
                                type={"text"}
                                name={"region"}
                                // onChange={inputChangeHandler}
                                ref={pushRef}
                                // onClick={() => {
                                //     setRegionDropdownOpen(true)
                                // }}
                                className={styles.region_dropdown_toggle}
                                value={lang.region}
                                readOnly={true}
                            />
                            {/*{regionDropdownOpen &&*/}
                            {/*    <div className={styles.region_dropdown} style={{*/}
                            {/*        width: allFieldsRef.current[0].clientWidth ,*/}
                            {/*    }}>*/}
                            {/*        <div>erevan</div>*/}
                            {/*        <div>charbax</div>*/}
                            {/*        <div>masiv</div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {formError && initialState.region.length === 0 && <span>{lang.requiredField}</span>}
                        </div>
                        <div className={styles.input_div}>
                            <label>
                                {lang.formAddress}
                            </label>
                            <input
                                type={"text"}
                                name={"address"}
                                onChange={inputChangeHandler}
                                ref={pushRef}
                            />
                            {formError && initialState.address.length === 0 && <span>{lang.requiredField}</span>}
                        </div>
                        <div className={styles.input_date_div}>
                            <label>
                                {lang.formTime}
                            </label>
                            <CustomDatePicker
                                date={dayjs(minDate + "T" + minTime)}
                                changeStateValue={(value) => {
                                    setInitialState({
                                        ...initialState, ['deliveryTime']: value.$d.toLocaleString(),
                                    });
                                }}/>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>
                            {lang.formNote}
                        </label>
                        <textarea name={"notes"} onChange={changeHandler}/>
                    </div>
                    <div className={styles.checkout_div}>
                        <div className={styles.total_price} onClick={() => {
                        }}>
                            <span>{lang.amountToBePaid}</span>
                            {totalPrice + " ֏"}
                        </div>
                        <button
                            className={btnDisabled ? styles.checkout_btn + " " + styles.cursor_loading : styles.checkout_btn}
                            onClick={onSubmit}
                            disabled={btnDisabled}
                        >
                            {lang.checkOutBtn}
                        </button>
                    </div>
                </div>
                {
                    isVerifyPopUpOpen &&
                    <VerificationPopUp
                        closePopUp={() => {
                            setIsVerifyPopUpOpen(false);
                        }}
                        callback={crateAnOrder}
                        tel={telToCheck}
                        asyncSendFunction={asyncSendFunction}
                    />
                }
            </div>
        );
    }
;

export default CheckoutSectionDesktop;





