import AboutSection from "../../component/organism/About";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
const About = () => {
    const lang = useSelector(state => state.globalLang.lang);

    return (
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.aboutUs : lang === "RU" ? ru.aboutUs : en.aboutUs}</title>
                <meta name="description" content="
                  The Meat House-ն առաջարկում է պրեմիում դասի տավարի և նուրբ թռչնի մսից մինչև հյութեղ, բերանի մեջ հալվող,
                   անուշաբույր խոզի ու գառան միս ու շատ ավելին։ Անկախ նրանից, թե դուք պլանավորում եք
                    հանգստյան օրերին խորոված, ընտանեկան ընթրիք կամ պարզապես ցանկանում եք խոհանոցում իմպրովիզներ անել,
                    The Meat House-ի ընտրանին կբավարարի ամենաքմահաճ գուրմանների նախասիրությունները:
                "/>
            </Helmet>
            <AboutSection/>
        </div>
    );
};

export default About;