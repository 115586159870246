import styles from './style.module.scss';
import Skeleton from '@mui/material/Skeleton';


import {useNavigate} from 'react-router-dom'

import {useSelector} from 'react-redux';

import totalPriceBg from "../../../../assets/backgronds/cart/totalPriceBg.png"

import arrowLeft from "../../../../assets/icons/cartPage/commonIcons/arrowLeft.svg";
import arrowRight from "../../../../assets/icons/cartPage/commonIcons/arrowRight.svg";

import {useQuery, gql} from '@apollo/client';
import CartPagePriceCalculator from '../../../atom/CartPagePriceCalculator';
import {useEffect, useState} from "react";
import {Checkbox} from "@mui/material";
import EmptyPage from "../../../molecul/EmptyPage";


const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
    }
  }
`;

const CartSectionDesktop = ({langObj}) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const _id = isAuthenticated ? stateUser.user._id : '';

    const [totalPrice, setTotalPrice] = useState(0);

    const [checkoutData, setCheckoutData] = useState([]);

    const navigate = useNavigate();
    const lang = useSelector(state => state.globalLang.lang);
    const {loading, error, data, refetch} = useQuery(GET_CART_ITEMS, {
        variables: {userId: _id}
    });

    const handleClick = (productId, state) => {
        navigate(`/catalog/${productId}`, {
            state: {
                productState: state,
            }
        });
    };
    useEffect(() => {
        if (!loading && !error && data && data.userCartProducts && checkoutData.length > 0) {
            const newDataArray = [];
            data.userCartProducts.map((item) => {
                if (!!checkoutData.includes(item._id)) {
                    newDataArray.push(item._id);
                }

            });
            setCheckoutData(newDataArray);
        }
    }, [data]);

    useEffect(() => {
        let totalSum = 0;
        data && data.userCartProducts && data.userCartProducts.map((item) => {
            totalSum += item.price;
        })
        setTotalPrice(totalSum)
        const ids = data && data.userCartProducts.map(item => item._id);
        if (!loading && data && data.userCartProducts) {
            setCheckoutData(ids);
        }
    }, [loading])


    useEffect(() => {
        refetch();
    },[data])
    useEffect(() => {
        let totalSum = 0;
        if (!loading && !error && data && data.userCartProducts) {
            data.userCartProducts.map((item) => {
                if (checkoutData.includes(item._id)) {
                    totalSum += item.price;
                }

            })
            setTotalPrice(totalSum);
        }

    }, [checkoutData])

    if (loading) {
        return (
            <Skeleton/>
        );
    }


    if (data && data.userCartProducts && data.userCartProducts.length === 0) {
        return (
            <>
                <EmptyPage/>
            </>
        )
    }

    const handleChangeCheckoutProducts = (item) => {
        const newDataArray = [...checkoutData];
        if (!!newDataArray.includes(item)) {
            const itemIndex = newDataArray.indexOf(item);
            newDataArray.splice(itemIndex, 1);
            setCheckoutData(newDataArray);
        } else {
            newDataArray.push(item);
            setCheckoutData(newDataArray);
        }
    };

    const handleAddAllProducts = (queryData) => {
        if (checkoutData.length === data.userCartProducts.length) {
            setCheckoutData([]);
        } else {
            setCheckoutData(queryData);
        }
    }
    return (
        <div className={styles.cart_section}>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h2>
                        {langObj.heading}
                    </h2>
                </div>
            </div>
            <div className={styles.cart_item_table_div}>
                <div className={styles.cart_item_heading_div}>
                    <div
                        className={styles.cart_item_heading + " " + styles.small_div + " " + styles.checkbox_heading_div}>
                        <Checkbox
                            sx={{
                                "&:hover": {
                                    boxShadow: 3
                                },
                                "&.Mui-checked": {
                                    color: '#730101'
                                },
                                "& .MuiSvgIcon-root": {
                                    color: '#FFFFFF',
                                    fontSize: '30px'
                                },
                            }}
                            size={"medium"}

                            readOnly={true}
                            checked={data && data.userCartProducts && checkoutData.length === data.userCartProducts.length}
                            onClick={() => {
                                const ids = data && data.userCartProducts.map(item => item._id);
                                handleAddAllProducts(data && ids);
                            }}
                        />
                    </div>
                    <div className={styles.cart_item_heading + " " + styles.big_div}>
                        {langObj.productHeading}
                    </div>
                    <div className={styles.cart_item_heading + " " + styles.medium_div}>
                        {langObj.priceHeading}
                    </div>
                    <div className={styles.cart_item_heading + " " + styles.medium_div}>
                        {langObj.quantityHeading}
                    </div>
                    <div className={styles.cart_item_heading + " " + styles.medium_div}>
                        {langObj.totalPriceHeading}
                    </div>
                    <div className={styles.cart_item_heading + " " + styles.small_div}>
                        {langObj.removeBtnHeading}
                    </div>
                </div>
                <div className={styles.cart_items_table}>
                    {data.userCartProducts && data.userCartProducts.map((item, id) => {
                        return (
                            <div
                                className={styles.cart_item_div}
                                key={id}>
                                <div className={styles.cart_item + " " + styles.small_div + " " + styles.checkbox_div}>
                                    <Checkbox
                                        sx={{
                                            "&:hover": {
                                                boxShadow: 3
                                            },
                                            "&.Mui-checked": {
                                                color: '#730101'
                                            },
                                            "& .MuiSvgIcon-root": {
                                                color: '#FFFFFF',
                                                fontSize: '2rem'
                                            },
                                        }}
                                        size={"medium"}

                                        readOnly={true}
                                        checked={!!checkoutData.includes(item._id)}
                                        onClick={() => {
                                            handleChangeCheckoutProducts(item._id);
                                        }}
                                    />
                                </div>
                                <div className={styles.cart_item + " " + styles.big_div}>
                                    <div className={styles.product_image_name}>
                                        <img src={item.productImages} alt="image" className={styles.product_images}
                                             onClick={() => {
                                                 handleClick(item.productId, item.state);
                                             }}/>
                                        <span>
                                             {lang === "ENG" ? item.productTitle.en : lang === "RU" ? item.productTitle.ru : item.productTitle.am}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.cart_item + " " + styles.medium_div}>
                                    {item.defaultPrice} ֏
                                </div>
                                <div className={styles.price_calculator}>
                                    <CartPagePriceCalculator
                                        saleType={item.saleType}
                                        lang={langObj}
                                        cartItemId={item._id}
                                        defaultPrice={Number(item.defaultPrice)}
                                        id={item.productId}
                                        itemQuantity={item.quantity}
                                        itemPrice={item.price}
                                        loading={loading}
                                        state={item.state}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={styles.total_price_div}>
                    <span>
                        {langObj.totalPriceHeading}
                    </span>

                    <div className={styles.total_price}>
                        <span>{totalPrice} ֏</span>
                        <img src={totalPriceBg} alt={"totalPrice"}/>
                    </div>
                </div>
            </div>

            <div className={styles.nav_btn_div}>
                <button className={styles.back_shop_btn} onClick={() => {
                    navigate('/catalog')
                }}>
                    <img src={arrowLeft} alt={'icon'}/>
                    {langObj.backToShopBtn}
                </button>
                <button className={styles.checkout_btn} onClick={() => {
                    if (checkoutData.length > 0) {
                        navigate('/checkout', {
                            state: {
                                productIds: checkoutData && checkoutData,
                            }
                        })
                    }
                }}>
                    {langObj.checkOutBtn}
                    <img src={arrowRight} alt={'icon'}/>
                </button>
            </div>
        </div>
    );
};

export default CartSectionDesktop;