import styles from './style.module.scss';

import fstx1 from '../../../../assets/backgronds/home/statsSection/fstx1.png';
import fstx2 from '../../../../assets/backgronds/home/statsSection/fstx2.png';
import rosemary from '../../../../assets/backgronds/home/statsSection/rosemary.webp';

import plate from '../../../../assets/backgronds/home/statsSection/plate1.webp';

import AnimatedNumber from "../../../atom/AnimatedNumber";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import en from "../../../../assets/lang/en/HomePage/StatsSection/en.json"
import am from "../../../../assets/lang/am/HomePage/StatsSection/en.json"
import ru from "../../../../assets/lang/ru/HomePage/StatsSection/en.json"


const StatsSection = ({loading, error, data}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    if (loading){
        return (
            <div>

            </div>
        );
    }else if(error){
        return (
            <div>
                error :)
            </div>
        );
    }

    if(isMobile){
        return (
            <div className={styles.stats_section_mobile}>
                <div className={styles.first_two_plates_mobile}>
                    <div className={styles.main_plate_div_mobile}>
                        <div className={styles.plate_on_flour_mobile + ' ' + styles.first_plate_mobile}>
                            <div className={styles.flour1_img_mobile}/>
                            <div className={styles.plate_img_mobile}/>
                            <div className={styles.fstx1_img_mobile}/>
                            <div className={styles.stats_number_mobile}>
                                <AnimatedNumber startNumber={0} endNumber={data && data.customersCount}/>
                            </div>
                            <h3>{langObj.firstPlate}</h3>
                        </div>
                    </div>
                    <div className={styles.main_plate_div_mobile }>
                        <div className={styles.plate_on_flour_mobile + ' ' + styles.second_plate_mobile}>
                            <div className={styles.flour2_img_mobile}/>
                            <div className={styles.plate_img_mobile}/>
                            <div className={styles.fstx2_img_mobile}/>
                            <div className={styles.stats_number_mobile}>
                                <AnimatedNumber startNumber={0} endNumber={5} />
                            </div>
                            <h3>{langObj.secondPlate}</h3>
                        </div>

                    </div>
                </div>
                <div className={styles.main_plate_div_mobile + ' ' + styles.third_plate_mobile}>
                    <div className={styles.plate_on_flour_mobile}>
                        <h3>{langObj.thirdPlate}</h3>
                        <div className={styles.flour3_img_mobile}/>
                        <div className={styles.plate_img_mobile}/>
                        <div className={styles.stats_number_mobile}>
                            <AnimatedNumber startNumber={0} endNumber={data && data.ordersCount}/>
                        </div>
                    </div>

                </div>
                <div className={styles.rosemary_mobile}/>
            </div>
        );
    }

    return (
        <div className={styles.stats_section}>
            <div className={styles.first_two_plates}>
                <div className={styles.main_plate_div}>
                    <div className={styles.plate_on_flour + ' ' + styles.first_plate}>
                        <div
                            className={styles.flour1_img}/>
                        <div className={styles.plate_img}/>
                        <img src={fstx1} alt="flour" className={styles.fstx1_img}/>
                        <div className={styles.stats_number}>
                            <AnimatedNumber startNumber={0} endNumber={data && data.customersCount}/>
                        </div>
                    </div>
                    <h3>{langObj.firstPlate}</h3>
                </div>
                <div className={styles.main_plate_div}>
                    <div className={styles.plate_on_flour + ' ' + styles.second_plate}>
                        <div
                            className={styles.flour2_img}/>
                        <div className={styles.plate_img}/>
                        <img src={fstx2} alt="flour" className={styles.fstx2_img}/>
                        <div className={styles.stats_number}>
                            <AnimatedNumber startNumber={0} endNumber={5}/>
                        </div>
                    </div>
                    <h3>{langObj.secondPlate}</h3>
                </div>
            </div>
            <div className={styles.main_plate_div + ' ' + styles.third_plate}>
                <h3>{langObj.thirdPlate}</h3>
                <div className={styles.plate_on_flour}>
                    <div
                        className={styles.flour3_img}/>
                    <div className={styles.plate_img}/>
                    <div className={styles.stats_number}>
                        <AnimatedNumber startNumber={0} endNumber={data && data.ordersCount}/>
                    </div>
                </div>

            </div>
            <div className={styles.rosemary}/>
        </div>
    );
};

export default StatsSection;