import styles from "./style.module.scss";
import {useState} from "react";

import {useSelector} from "react-redux";


import buyItNowIcon from "../../../assets/icons/homePage/commonIcons/buyItNowIcon.svg";
import cookedAddCartIcon from "../../../assets/icons/homePage/commonIcons/cookedAddCartIcon.webp";
import cookedRemoveCartIcon from "../../../assets/icons/homePage/commonIcons/removeCartIconCooked.png";
import minusIcon from "../../../assets/icons/homePage/commonIcons/minusIcon.svg";
import plusIcon from "../../../assets/icons/homePage/commonIcons/plusIcon.svg";

import {useEffect} from "react";
import useAddToCart from "../../../hooks/product/cart/useAddToCart";


import useUpdateCartItem from "../../../hooks/product/cart/useUpdateCartItem";
import useRemoveCartItem from "../../../hooks/product/cart/useRemoveCartItem";
import {useNavigate} from "react-router-dom";
import en from "../../../assets/lang/en/HomePage/ProductSection/en.json";
import am from "../../../assets/lang/am/HomePage/ProductSection/en.json";
import ru from "../../../assets/lang/ru/HomePage/ProductSection/en.json";


const PriceCalculator = ({defaultPrice, id, cartProducts, loading, productImages, productTitle, openPopup, state, saleType}) => {
    const changePrice = saleType === "byPieces" ? defaultPrice : defaultPrice / 2;
    const changeQuantity =  saleType === "byPieces" ? 1 : 0.5;
    const [cartItemId, setCartItemId] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(defaultPrice);
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const lang = useSelector(state => state.globalLang.lang);

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);


    const findCartElement = () => {
        if (!loading && cartProducts && cartProducts.length >= 1) {
            const elements = cartProducts ? cartProducts : [];
            if (id && elements && elements.length >= 1) {
                return elements.find(item => item.productId === id && item.state === state);
            }
        }
    }
    const [isAdded, setIsAdded] = useState(isAuthenticated && !!findCartElement());

    const {addItemToCart, addItemError, addItemLoading} = useAddToCart();
    const {updateCartItem, updateCartError, updateCartLoading} = useUpdateCartItem();
    const {removeCartItem, removeItemError, removeItemLoading} = useRemoveCartItem();

    const resetValues = () => {
        setIsAdded(false);
        setQuantity(1);
        setPrice(defaultPrice);
    }
    useEffect(() => {
        if (!loading && isAuthenticated && cartProducts) {
            setIsAdded(!!findCartElement());
            const elements = cartProducts ? cartProducts : [];
            if (id && elements && elements.length >= 1) {
                const item = elements.find(item => item.productId === id && item.state === state);
                if (item) {
                    setCartItemId(item._id);
                    setQuantity(item.quantity);
                    setPrice(item.price);
                } else {
                    resetValues();
                }
            } else {
                resetValues();
            }
        } else {
            resetValues();
        }
    }, [cartProducts, id, loading, state, isAdded]);

    useEffect(() => {
        if (!isAuthenticated) {
            resetValues();
        }
    }, [isAuthenticated, defaultPrice]);


    const handleChangeQuantity = async (type) => {
        if (!isButtonDisabled && type === "+") {
            if (!loading) {
                if (!isButtonDisabled
                    && !addItemError
                    && !addItemLoading
                    && !updateCartLoading
                    && !updateCartError
                    && !removeItemLoading
                    && !removeItemError
                ) {

                    if (isAdded && quantity) {
                        setIsButtonDisabled(true);
                        await updateCartItem({
                            variables: {
                                updateCartInput: {
                                    cartItemId: cartItemId,
                                    price: price + changePrice,
                                    quantity: quantity + changeQuantity,
                                    state: state,
                                }
                            },

                        });
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                        setTimeout(() => {
                            setIsButtonDisabled(false);
                        }, 150)
                    } else {
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                    }

                }
            }

        } else {
            if (!isButtonDisabled && quantity > 1) {
                if (quantity !== 1 && !loading) {
                    if (!isButtonDisabled
                        && !updateCartLoading
                        && !updateCartError
                        && !addItemError
                        && !addItemLoading
                        && !removeItemLoading
                        && !removeItemError
                    ) {
                        if (isAdded && quantity) {
                            setIsButtonDisabled(true);
                            await updateCartItem({
                                variables: {
                                    updateCartInput: {
                                        cartItemId: cartItemId,
                                        price: quantity === 1 ? price : price - changePrice,
                                        quantity: quantity === 1 ? quantity : quantity - changeQuantity,
                                        state: state,
                                    }
                                },
                            });
                            setQuantity(quantity - changeQuantity);
                            setPrice(price - changePrice);
                            setTimeout(() => {
                                setIsButtonDisabled(false);
                            }, 150);
                        } else {
                            setQuantity(quantity === 1 ? quantity : quantity - changeQuantity,);
                            setPrice(quantity === 1 ? price : price - changePrice);
                        }
                    }
                }

            }
        }

    };

    return (
        <div className={styles.calculator_section}>
            <div className={styles.calculator_div}>
                <div className={styles.quantityDiv} onKeyDown={(event) => {
                    event.stopPropagation();
                }}>
                    <button className={styles.btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={async (e) => {
                        e.preventDefault();
                        await handleChangeQuantity("-");
                    }}>
                            <span>
                               <img width="11" height="3" src={minusIcon} alt={"-"}/>
                            </span>
                    </button>
                    <div
                        className={styles.quantity}>{quantity}  {changeQuantity !== 1 ?  langObj.kg : langObj.piece}
                    </div>
                    <button className={styles.btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={async  (e) => {
                        e.preventDefault();
                        await handleChangeQuantity("+");
                    }}>
                            <span>
                                 <img width="11" height="11" src={plusIcon} alt={"+"}/>
                            </span>
                    </button>

                </div>
                <h4 className={styles.price}>
                    {price} ֏
                </h4>
            </div>
            <div className={styles.btn_flex_div}>
                {isAdded ?
                    <button
                        className={styles.remove_item_btn}
                        onClick={async () => {
                            if (
                                !isButtonDisabled
                                && !addItemLoading
                                && !updateCartLoading
                                && !updateCartError
                                && !removeItemLoading
                                && !removeItemError
                            ) {
                                await removeCartItem({
                                    variables: {
                                        removeCartItemInput: {
                                            cartItemId: cartItemId,
                                        },
                                    },
                                });
                            }
                        }}>
                        <span>{langObj.removeFromCart}</span>
                        <img src={cookedRemoveCartIcon} alt="icon"/>
                    </button>
                    :
                    <button
                        className={styles.add_btn}
                        onClick={async () => {
                            if (isAuthenticated) {

                                if (
                                    !isButtonDisabled
                                    && !addItemLoading
                                    && !updateCartLoading
                                    && !updateCartError
                                    && !removeItemLoading
                                    && !removeItemError
                                ) {
                                    setIsButtonDisabled(true);
                                    await addItemToCart({
                                        variables: {
                                            addToCartInput: {
                                                userId: user._id,
                                                productId: id,
                                                price: price,
                                                quantity: quantity,
                                                state: state,
                                                defaultPrice: defaultPrice,
                                                productImages: productImages,
                                                productTitle: productTitle,
                                                saleType: saleType,
                                            },
                                        },
                                    });
                                }
                            } else {
                                if (isMobile) {
                                    navigate("/sign_in")
                                } else {
                                    openPopup();
                                }
                            }
                            setTimeout(() => {
                                setIsButtonDisabled(false);
                            }, 300);
                        }}>
                        <span>{langObj.addBtn}</span>
                        <img  width="11" height="9" src={cookedAddCartIcon} alt="icon"/>
                    </button>}
                <button className={styles.buy_now_btn} onClick={() => {
                    navigate("/checkout", {
                        state: [{
                            state: state,
                            productTitle: productTitle,
                            productId: id,
                            quantity: quantity,
                            productImages: productImages,
                            defaultPrice: defaultPrice,
                            price: price
                        }]
                    })
                }}>
                    <span>{langObj.buyItNowBtn}</span>
                    <img  width="9" height="12" src={buyItNowIcon} alt="icon"/>
                </button>
            </div>
        </div>

    );
};

export default PriceCalculator;