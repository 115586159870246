import {
    HeroSection, RawProducts, CookedProducts, StatsSection, AboutUsSection, ReviewsSection, HomeFaqSection
} from "../../component/organism/Home";
import {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";



const HOME_PAGE_ITEMS = gql`
  query filteredProducts($limit: Int, $userId: ID!) {
    rawProducts(limit: $limit){
      _id
      productTitle{
        am
        en
        ru
      }
      price
      saleType
      productImages{
        productSmallRawImage
      }
    }
    cookedProducts(limit: $limit){
        _id
      productTitle{
        am
        en
        ru
      }
      saleType
      price
      preparationPrice
      productImages{
        productSmallCookedImage
      }
    }
    userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
    }
    shopStats{
      ordersCount
      customersCount
    }
  }
`;

const Home = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';



    useEffect(() => {
        setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}), 100);

    }, []);
    const {loading, error, data} = useQuery(HOME_PAGE_ITEMS, {
        variables: {
            userId: userId
        },
    });


    return (
        <div style={{
            marginBottom: '120px',
        }}>
            <Helmet>
                <title>The Meat House</title>
                <meta name="description" content="
                    The Meat House-ը Ձեզ է առաջարկում թարմ մսի լայն տեսականի՝
                    Տավարի, Խոզի, Գառան և Հավի ։
                    Մսի մասնագիտացված օնլայն խանութն առաջարկում է ինչպես թարմ այնպես էլ մանղալի վրա և փռում պատրաստված։ Առաքվում է շատ արագ և ճիշտ ժամանակին։
                "/>
            </Helmet>
            <HeroSection/>

            <RawProducts
                loading={loading}
                error={error}
                userCartProducts={!loading && !error && data.userCartProducts}
                userFavoriteProducts={!loading && !error && data.userFavoriteProducts}
                data={!loading && !error && data.rawProducts}
            />
            <CookedProducts
                loading={loading}
                error={error}
                userCartProducts={!loading && !error && data.userCartProducts}
                userFavoriteProducts={!loading && !error && data.userFavoriteProducts}
                data={!loading && !error && data.cookedProducts}
            />
            {/*<AllProductsSliderSection />*/}
            <StatsSection
                loading={loading}
                error={error}
                data={!loading && !error && data.shopStats}
            />
            <AboutUsSection/>
            <HomeFaqSection/>
            <ReviewsSection/>
        </div>
    );
};

export default Home;