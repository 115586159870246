import {useSelector} from "react-redux";
import CheckoutSectionDesktop from "./Desktop";
import CheckoutSectionMobile from "./Mobile";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/CheckoutPage/en.json";
import am from "../../../assets/lang/am/CheckoutPage/en.json";
import ru from "../../../assets/lang/ru/CheckoutPage/en.json";


const CheckoutSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, [])

    const stateLang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (stateLang === "ARM") {
            setLangObj(am);
        } else if (stateLang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [stateLang]);
    return (
        <div>
            {
                isMobile
                    ?
                    <CheckoutSectionMobile lang={langObj}/>
                    :
                    <CheckoutSectionDesktop lang={langObj}/>
            }
        </div>
    );
};

export default CheckoutSection;

