import styles from "../style.module.scss";

import {useEffect, useRef, useState} from "react";
import {useLoginUser} from "../../../../hooks/login/loginApi/useLoginUser";
import CustomLoginInput from "../../../atom/CustomLoginInput";
import {useNavigate} from "react-router-dom";
import backIcon from "../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";
import closePopupIcon from "../../../../assets/icons/layout/sidebar/closeIcon.svg";
import CustomPopupLoginInput from "../../../atom/CustomPopupLoginInput";
import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../../redux/features/popup/notifyMessage";

const LoginSection = ({lang, changeSection, closePopup}) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const signInBtnRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: "",
    });


    const changeHandler = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    };

    const {loginUser} = useLoginUser({
        values,
        closePopup,
        navigate,
        handleChangeLoadingStatus: setIsLoading,
    });

    const handleLogin = async () => {
        const checkArray = {...values};
        const containsFalsy = Object.values(checkArray).some(item => !item || (Array.isArray(item) && item.length === 0));
        if (!containsFalsy) await loginUser();
        else dispatch(notifyUser({
            barOpen: true,
            message: lang.emptyFieldWarning,
        }));
    };

    useEffect(() => {
        if (isLoading && closePopup) {
            signInBtnRef.current.style.opacity = 0.7;
            signInBtnRef.current.style.cursor = "wait";
        }
        if (!isLoading && closePopup) {
            signInBtnRef.current.style.opacity = 1;
            signInBtnRef.current.style.cursor = "pointer";
        }
    }, [isLoading, closePopup]);

    if (isMobile) {
        return (
            <div className={styles.main_section_div_mobile} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    if (closePopup) {
                        setIsLoading(true);
                        handleLogin();
                    } else {
                        handleLogin();
                    }
                }
            }}>
                <div className={styles.login_section_mobile}>
                    <div className={styles.back_btn_div_mobile}>
                        <button
                            className={styles.back_btn + " " + styles.position_relative + " " + styles.no_margin}
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            <img src={backIcon} alt={"icon"}/>
                            <span>{lang.backBtn}</span>
                        </button>
                    </div>
                    <h1 className={styles.heading_mobile}>
                        {lang.signInHeading}
                    </h1>
                    <div className={styles.inputdiv}>
                        <label htmlFor="email"
                               className={styles.label_mobile}>
                            {lang.emailLabel}
                        </label>

                        <CustomLoginInput
                            type="email"
                            name="email"
                            className={styles.loginInput}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            maxLength={"50"}
                        />

                    </div>
                    <div className={styles.inputdiv}>
                        <label htmlFor="password"
                               className={styles.label_mobile}>
                            {lang.passwordLabel}
                        </label>

                        <CustomLoginInput
                            type="password"
                            name="password"
                            className={styles.loginInput}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            maxLength={"50"}
                        />
                    </div>
                    <div className={styles.forgot_div}>
                        <button className={styles.recover_password_mobile} onClick={() => {
                            changeSection(4)
                        }}>
                            {lang.forgotPassword}
                        </button>
                    </div>

                    <button
                        ref={signInBtnRef}
                        className={styles.login_btn}
                    >
                        <div className={styles.plate_on_flour_mobile}>
                            <div className={styles.flour_img_mobile}/>
                            <div
                                className={styles.plate_img_mobile}
                                onClick={() => {
                                    if (closePopup) {
                                        setIsLoading(true);
                                        handleLogin();
                                    } else {
                                        handleLogin();
                                    }
                                }}
                            />
                            <div className={styles.login_text_mobile} onClick={() => {
                                if (closePopup) {
                                    setIsLoading(true);
                                    handleLogin();
                                } else {
                                    handleLogin();
                                }
                            }}>
                                {lang.signInBtn}
                            </div>
                        </div>
                    </button>

                    <div className={styles.register_btn_mobile}>
                    <span>
                       {lang.navigateSignUp.regular}
                    </span>
                        <button className={styles.register_btn_mobile}
                                onClick={() => {
                                    if (closePopup) {
                                        navigate("/sign_in/registration");
                                        closePopup();
                                    } else {
                                        changeSection(2);
                                    }
                                }}>
                            {lang.navigateSignUp.red}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={closePopup ? styles.main_section_div_popup : styles.main_section_div}
             onKeyDown={(event) => {
                 if (event.key === 'Enter') {

                     event.preventDefault();
                     if (closePopup) {
                         setIsLoading(true);
                         handleLogin();
                     } else {
                         handleLogin();
                     }
                 }
             }}>
            <div className={styles.login_section}>

                {!closePopup ?
                    <div className={styles.heading_div}>
                        <button className={styles.back_btn} onClick={() => {
                            navigate("/");
                        }}>
                            <img src={backIcon} alt={"icon"}/>
                            <span>{lang.backBtn}</span>
                        </button>
                        <h1 className={styles.heading}>
                            {lang.signInHeading}
                        </h1>
                    </div>
                    :
                    <div className={styles.heading_div_popup}>
                        <button className={styles.back_btn + " " + styles.back_btn_popup} onClick={() => {
                            closePopup();
                        }}>
                            <img src={closePopupIcon} alt={"closeIcon"}/>
                        </button>
                    </div>
                }
                {
                    closePopup &&
                    <h1 className={styles.heading + " " + styles.heading_popup_font}>
                        {lang.signInHeading}
                    </h1>
                }

                <div className={styles.inputdiv}>
                    <label htmlFor="email"
                           className={closePopup ? styles.label + " " + styles.label_popup_font : styles.label}>
                        {lang.emailLabel}
                    </label>
                    {
                        closePopup ?
                            <CustomPopupLoginInput
                                type="email"
                                name="email"
                                className={styles.loginInput}
                                onChange={changeHandler}
                                customInputHeight={"50px"}
                                maxLength={"50"}
                            />
                            :
                            <CustomLoginInput
                                type="email"
                                name="email"
                                className={styles.loginInput}
                                onChange={changeHandler}
                                customInputHeight={"50px"}
                                maxLength={"50"}
                            />
                    }

                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="password"
                           className={closePopup ? styles.label + " " + styles.label_popup_font : styles.label}>
                        {lang.passwordLabel}
                    </label>
                    {
                        closePopup ?
                            <CustomPopupLoginInput
                                type="password"
                                name="password"
                                className={styles.loginInput}
                                onChange={changeHandler}
                                customInputHeight={"50px"}
                                maxLength={50}
                            />
                            :
                            <CustomLoginInput
                                type="password"
                                name="password"
                                className={styles.loginInput}
                                onChange={changeHandler}
                                customInputHeight={"50px"}
                                maxLength={50}
                            />
                    }

                </div>
                <div className={styles.forgot_div}>
                    <button className={styles.recover_password} onClick={() => {
                        if (closePopup) {
                            navigate("/sign_in/forgotPassword");
                            closePopup();
                        } else {
                            changeSection(4);
                        }
                    }}>
                        {lang.forgotPassword}
                    </button>
                </div>

                {
                    closePopup
                        ?
                        <button ref={signInBtnRef} className={styles.login_popup_btn} onClick={() => {
                            setIsLoading(true);
                            handleLogin();
                        }}>
                            {lang.signInBtn}
                        </button>
                        :
                        <button
                            ref={signInBtnRef}
                            className={styles.login_btn}

                        >
                            <div className={styles.plate_on_flour}>
                                <div className={styles.flour_img}/>
                                <div
                                    className={styles.plate_img}
                                    onClick={() => {
                                        if (closePopup) {
                                            setIsLoading(true);
                                            handleLogin();
                                        } else {
                                            handleLogin();
                                        }
                                    }}
                                />
                                <div className={styles.login_text} onClick={() => {
                                    if (closePopup) {
                                        setIsLoading(true);
                                        handleLogin();
                                    } else {
                                        handleLogin();
                                    }
                                }}>
                                    {lang.signInBtn}
                                </div>
                            </div>
                        </button>
                }

                <div className={styles.register_btn}>
                    <span className={closePopup && styles.label_popup_font}>
                       {lang.navigateSignUp.regular}
                    </span>
                    <button
                        onClick={() => {
                            if (closePopup) {
                                navigate("/sign_in/registration");
                                closePopup();
                            } else {
                                changeSection(2);
                            }
                        }}
                    >
                        {lang.navigateSignUp.red}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginSection;
