import styles from './style.module.scss';
import en from '../../../../assets/lang/en/HomePage/AboutUs/en.json';
import am from '../../../../assets/lang/am/HomePage/AboutUs/en.json';
import ru from '../../../../assets/lang/ru/HomePage/AboutUs/en.json';

import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const AboutUsSection = () => {
    const navigate = useNavigate();

    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    return (
        <div className={isMobile ? styles.main_section_mobile : styles.main_section}>
            <div className={isMobile ? styles.section_heading_mobile : styles.section_heading}>
                <div>
                    <h2>
                        {langObj.sectionHeading}
                    </h2>
                    <hr/>
                </div>
            </div>
            <div className={isMobile ? styles.about_us_section_mobile : styles.about_us_section}>
                <div className={isMobile ? styles.paragraph_div_mobile : styles.paragraph_div}>
                    <p>
                        {langObj.sectionParagraph}
                    </p>
                    {
                        !isMobile &&
                        <button onClick={() => {
                            navigate('/about_us')
                        }}>
                            {langObj.readMoreBtn}
                        </button>
                    }
                </div>
                <div className={isMobile ? styles.about_us_img_mobile : styles.about_us_img_desktop}/>
                {
                    isMobile &&
                    <div className={styles.read_btn}>
                        <button onClick={() => {
                            navigate('/about_us')
                        }}>
                            {langObj.readMoreBtn}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default AboutUsSection;