import styles from './style.module.scss';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {useSelector} from "react-redux";
import DesktopSlider from "./DesktopSlider";
import MobileSlider from "./MobileSlider";

const CategorySection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);


if(isMobile){
    return (
        <div className={styles.slider_container_mobile}>
            <MobileSlider />
        </div>
    );
}

    return (
        <div className={styles.slider_container}>
            <DesktopSlider />
        </div>
    )


};

export default CategorySection;


