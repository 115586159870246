import styles from "./style.module.scss";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import {useEffect, useState} from "react";
import CartPagePriceCalculator from "../../../atom/CartPagePriceCalculator";

import useRemoveCartItem from "../../../../hooks/product/cart/useRemoveCartItem";
import {Checkbox} from "@mui/material";
import removeCartIcon from "../../../../assets/icons/cartPage/commonIcons/removeIcon.svg";
import totalPriceBg from "../../../../assets/backgronds/cart/totalPriceBg.png";
import arrowLeft from "../../../../assets/icons/cartPage/commonIcons/arrowLeft.svg";
import arrowRight from "../../../../assets/icons/cartPage/commonIcons/arrowRight.svg";
import EmptyPage from "../../../molecul/EmptyPage";


const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
    }
  }
`;

const CartSectionMobile = ({langObj}) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const _id = isAuthenticated ? stateUser.user._id : '';

    const [totalPrice, setTotalPrice] = useState(0);

    const [checkoutData, setCheckoutData] = useState([]);

    const navigate = useNavigate();
    const lang = useSelector(state => state.globalLang.lang);
    const {loading, error, data, refetch} = useQuery(GET_CART_ITEMS, {
        variables: {userId: _id}
    });
    const {removeCartItem} = useRemoveCartItem();
    const handleClick = (productId, state) => {
        navigate(`/catalog/${productId}`, {
            state: {
                productState: state,
            }
        });
    };

    useEffect(() => {
        if (!loading && !error && data && data.userCartProducts && checkoutData.length > 0) {
            const newDataArray = [];
            data.userCartProducts.map((item) => {
                if (!!checkoutData.includes(item._id)) {
                    newDataArray.push(item._id);
                }

            });
            setCheckoutData(newDataArray);
        }
    }, [data]);

    useEffect(() => {
        let totalSum = 0;
        data && data.userCartProducts && data.userCartProducts.map((item) => {
            totalSum += item.price;
        })
        setTotalPrice(totalSum)
        const ids = data && data.userCartProducts.map(item => item._id);
        if (!loading && data && data.userCartProducts) {
            setCheckoutData(ids);
        }
    }, [loading])

    useEffect(() => {
        refetch();
    },[data])

    useEffect(() => {
        let totalSum = 0;
        if (!loading && !error && data && data.userCartProducts) {
            data.userCartProducts.map((item) => {
                if (checkoutData.includes(item._id)) {
                    totalSum += item.price;
                }

            })
            setTotalPrice(totalSum);
        }

    }, [checkoutData])

    if (loading) {
        return (
            <Skeleton/>
        );
    }


    if (data && data.userCartProducts && data.userCartProducts.length === 0) {
        return (
            <>
                <EmptyPage />
            </>
        )
    }

    const handleChangeCheckoutProducts = (item) => {
        const newDataArray = [...checkoutData];
        if (!!newDataArray.includes(item)) {
            const itemIndex = newDataArray.indexOf(item);
            newDataArray.splice(itemIndex, 1);
            setCheckoutData(newDataArray);
        } else {
            newDataArray.push(item);
            setCheckoutData(newDataArray);
        }
    };



    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <div>
                    <h2>
                        {langObj.heading}
                    </h2>
                    <hr/>
                </div>
            </div>
            <div className={styles.cart_product_section}>
                {data.userCartProducts && data.userCartProducts.map((item, id) => {
                    return (
                        <div className={styles.cart_item_row_div} key={id}>
                            <Checkbox
                                sx={{
                                    "&:hover": {
                                        boxShadow: 3
                                    },
                                    "&.Mui-checked": {
                                        color: '#730101'
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: '#FFFFFF',
                                        fontSize: '2rem'
                                    },
                                }}
                                size={"medium"}

                                readOnly={true}
                                checked={!!checkoutData.includes(item._id)}
                                onClick={() => {
                                    handleChangeCheckoutProducts(item._id);
                                }}
                            />
                            <div className={styles.product_div}>
                                <div className={styles.first_section}>
                                    <div className={styles.product_img_div}>
                                        <div className={styles.cart_item_heading}>
                                            {lang === "ENG" ? item.productTitle.en : lang === "RU" ? item.productTitle.ru : item.productTitle.am}
                                        </div>
                                        <img src={item.productImages} alt={"img"} className={styles.product_img}
                                             onClick={() => {
                                                 handleClick(item.productId, item.state);
                                             }}/>
                                    </div>
                                    <div className={styles.item_heading_div}>

                                        <div className={styles.cart_item_price}>
                                            {langObj.priceMobile + " " + item.defaultPrice + " ֏"}
                                        </div>
                                        <CartPagePriceCalculator
                                            cartItemId={item._id}
                                            defaultPrice={Number(item.defaultPrice)}
                                            id={item.productId}
                                            itemQuantity={item.quantity}
                                            itemPrice={item.price}
                                            loading={loading}
                                            state={item.state}
                                            lang={langObj}
                                            saleType={item.saleType}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className={styles.remove_item_btn} onClick={() => {
                                removeCartItem({
                                    variables: {
                                        removeCartItemInput: {
                                            cartItemId: item._id,
                                        },
                                    },
                                });
                            }}>
                                <img src={removeCartIcon} alt="icon"/>
                            </button>
                        </div>
                    )
                })}

            </div>
            <div className={styles.checkout_section}>
                <div className={styles.total_price_div}>
                    <span>
                        {langObj.totalPriceHeading}
                    </span>

                    <div className={styles.total_price}>
                        <span>{totalPrice} ֏</span>
                        <img src={totalPriceBg} alt={"totalPrice"}/>
                    </div>
                </div>

                <div className={styles.nav_btn_div}>
                    <button className={styles.back_shop_btn} onClick={() => {
                        navigate('/')
                    }}>
                        <img src={arrowLeft} alt={'icon'}/>
                        {langObj.backToShopBtn}
                    </button>
                    <button className={styles.checkout_btn} onClick={() => {
                        if (checkoutData.length > 0) {
                            navigate('/checkout', {
                                state: {
                                    productIds: checkoutData && checkoutData,
                                }
                            })
                        }
                    }}>
                        {langObj.checkOutBtn}
                        <img src={arrowRight} alt={'icon'}/>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default CartSectionMobile;