import styles from './style.module.scss';

import {useState, useEffect} from 'react';

import removeIcon from '../../../../assets/icons/favoritePage/removeIcon.png';

import heartBg from '../../../../assets/backgronds/favorite/heartBgMobile.png';


import PriceCalculator from '../../../atom/PriceCalculator';

import useRemoveFavoriteItem from '../../../../hooks/product/favorite/useRemoveFavoriteItem';

import {useSelector} from 'react-redux';

import {useQuery, gql} from '@apollo/client';
import {Skeleton} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import prevIcon from "../../../../assets/icons/homePage/commonIcons/prevIcon.png";
import nextIcon from "../../../../assets/icons/homePage/commonIcons/nextIcon.png";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {A11y} from "swiper";
import EmptyPage from "../../../molecul/EmptyPage";


const GET_FAVORITE_ITEMS = gql`
  query userFavoriteProducts($userId: ID!) {
    userFavoriteProducts(userId: $userId){
        _id
        productId
        userId
        state
        product{
            _id
            state
            productTitle{
                am
                ru
                en
            }
            type
            price
            preparationPrice
            productImages{
                productSmallRawImage
                productSmallCookedImage
            }
            saleType
        }
      }
      userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
      }
  }
`;

const FavoriteSectionMobile = ({langObj}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);


    const [swiper, setSwiper] = useState();

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';

    const navigate = useNavigate();

    const lang = useSelector(state => state.globalLang.lang);

    const {loading, data, error, refetch} = useQuery(GET_FAVORITE_ITEMS, {
        variables: {userId: userId}
    });

    const handleClick = (productId, state) => {
        navigate(`/catalog/${productId}`,{
            state:{
                productState: state,
            }
        });
    }
//  eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        refetch();
    }, []);


    const {removeFavoriteItem} = useRemoveFavoriteItem({});

    if (loading) {
        return (
            <div>
                <Skeleton/>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                Sorry :)
            </div>
        );
    }


    if (data && data.userFavoriteProducts && data.userFavoriteProducts.length === 0) {
        return (
            <>
                <EmptyPage />
            </>
        );
    }

    return (
        <div className={styles.main_favorite_section}>
            <div className={styles.favorite_products_section}>
                <div className={styles.first_section}>
                    <div className={styles.section_heading}>

                        <hr/>
                        <h2>
                            {langObj.heading}
                        </h2>
                        <hr/>
                    </div>
                    <img src={heartBg} alt={"heart"}/>
                </div>
                <div className={styles.swiper_btn_div}>

                    <Swiper
                        onSwiper={(swiper) => {
                            setSwiper(swiper);
                        }}
                        threshold={10}
                        modules={[A11y]}
                        spaceBetween={20}
                        slidesPerView={1.4}
                        slidesPerGroup={1}

                        className={styles.swiper}
                    >
                        {data && data.userFavoriteProducts && data.userFavoriteProducts.map((favorite, id) => {
                            const element = favorite.product[0];
                            return (

                                <SwiperSlide className={styles.swiper_slide} key={id}>
                                    <div className={styles.sliderDiv}>
                                        <div
                                            className={styles.product_div} key={id}>
                                            <div
                                                className={styles.product_background_div}>
                                            </div>
                                            <div className={styles.product_img_div}>
                                                <img
                                                    src={favorite.state === 'cooked' ? element.productImages.productSmallCookedImage : element.productImages.productSmallRawImage}
                                                    alt={"img"} className={styles.product_img} onClick={() => {
                                                    handleClick(element._id, favorite.state);
                                                }}/>


                                            </div>
                                            <div className={styles.product_description}>
                                                <div className={styles.title_favorite_div}>
                                                    <div
                                                        className={styles.product_title}>
                                                        {lang === "ENG" ? element.productTitle.en : lang === "RU" ? element.productTitle.ru : element.productTitle.am}
                                                    </div>
                                                    <button
                                                        className={styles.remove_btn}
                                                        onClick={async () => {
                                                            if (isAuthenticated) {
                                                                await removeFavoriteItem({
                                                                    variables: {
                                                                        removeFromFavoriteInput: {
                                                                            favoriteItemId: favorite._id,
                                                                        },
                                                                    },
                                                                });
                                                            }
                                                        }}>
                                                        <img src={removeIcon} alt={"favorite"}/>
                                                    </button>
                                                </div>
                                                <PriceCalculator
                                                    id={element._id}
                                                    saleType={element.saleType}
                                                    productImages={
                                                        favorite.state === 'raw'
                                                            ?
                                                            element.productImages.productSmallRawImage
                                                            :
                                                            element.productImages.productSmallCookedImage
                                                    }
                                                    productTitle={element.productTitle}
                                                    defaultPrice={favorite.state === 'raw' ? Number(element.price) : Number(element.price) + Number(element.preparationPrice)}
                                                    state={favorite.state}
                                                    cartProducts={data.userCartProducts}
                                                    loading={loading}
                                                />


                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <div className={styles.swiper_nav_div}>
                        <button className={styles.swiper_nav_btn} onClick={() => {
                            swiper.slidePrev();
                        }}>
                            <img src={prevIcon} alt="prev"/>
                        </button>
                        <button className={styles.swiper_nav_btn}
                                onClick={() => {
                                    swiper.slideNext();
                                }}>
                            <img src={nextIcon} alt="next"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default FavoriteSectionMobile;