import styles from './style.module.scss';

import en from "../../../assets/lang/en/Settings/en.json";
import am from "../../../assets/lang/am/Settings/en.json";
import ru from "../../../assets/lang/ru/Settings/en.json";

import {useDispatch, useSelector} from "react-redux";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {loginSuccess} from "../../../redux/features/user/userSlice";
import {useNavigate} from "react-router-dom";

import {notifyUser} from "../../../redux/features/popup/notifyMessage";
import VerificationPopUp from "../../molecul/CheckoutPage/VerificationPopUp";
import useSendOtp from "../../../hooks/login/verification/useSendOtp";

const UPDATE_USER_DATA = gql`
    mutation Mutation(
        $userDataInput: UserDataInput
    ) {
        updateUserData(
            userDataInput:  $userDataInput
        ) {
            token
        }
    }
`;

const SettingsSection = () => {
    const user = useSelector(state => state.user).user;
    const userId = user ? user._id : "without account";
    const dispatch = useDispatch();

    const [isVerifyPopUpOpen, setIsVerifyPopUpOpen] = useState(false);

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [newPassInputDisabled, setNewPassInputDisabled] = useState(true);
    const [confirmPassInputDisabled, setConfirmPassInputDisabled] = useState(true);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [fieldVisibility, setFieldVisibility] = useState({
        oldPassword: "password",
        newPassword: "password",
        confirmPassword: "password",
    });

    const changeNumber = (telNumber) => {
        if (telNumber.length === 9 && telNumber.slice(0, 1) === "0") {
            return "+374" + telNumber.slice(1);
        } else if (telNumber.length === 8 && telNumber.slice(0, 1) !== "0" && telNumber.slice(0, 4) !== "+374" && telNumber.slice(0, 2) !== "00") {
            return "+374" + telNumber;
        } else if (telNumber.length === 11 && telNumber.slice(0, 3) === "374") {
            return "+" + telNumber;
        } else if (telNumber.length === 12 && telNumber.slice(0, 4) === "+374") {
            return telNumber;
        } else if (telNumber.length === 13 && telNumber.slice(0, 5) === "00374") {
            return "+" + telNumber.slice(2);
        }
        return telNumber;
    }
    const navigate = useNavigate();

    const confirmPassRef = useRef(null);
    const newPassRef = useRef(null);

    const lang = useSelector(state => state.globalLang.lang);

    const [langObj, setLangObj] = useState(en);

    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);


    const [changeData, setChangeData] = useState({
        userId: userId,
        newPassword: '',
        oldPassword: '',
        fullName: user.fullName,
        email: user.email,
        tel: user.tel,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (changeData.oldPassword.length > 0) {
            setNewPassInputDisabled(false);
        } else {
            setNewPassInputDisabled(true);
            setConfirmPassInputDisabled(true);
            newPassRef.current.value = '';
            confirmPassRef.current.value = '';

            setConfirmPassword('');
            setChangeData({
                ...changeData,
                newPassword: "",
            })
        }
    }, [changeData.oldPassword]);

    useEffect(() => {
        if (changeData.newPassword.length > 0) {
            setConfirmPassInputDisabled(false);
        } else {
            setConfirmPassInputDisabled(true);
            confirmPassRef.current.value = '';
            setConfirmPassword('');
        }
    }, [changeData.newPassword]);

    const [updateUserData, {loading}] = useMutation(UPDATE_USER_DATA, {
        update(proxy, {data: {updateUserData: userData}}) {
            const token = userData.token;
            dispatch(loginSuccess(token));

            localStorage.setItem('token', token);
            navigate("/");
        },
        onError({graphQLErrors}) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },

    })
    const changeHandler = (event) => {
        setChangeData({...changeData, [event.target.name]: event.target.value});
    };
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const openPopUp = () => {
        setIsVerifyPopUpOpen(true)
    }
    const telNumberString = changeNumber(changeData.tel);
    const {sendOtp, smsLoading} = useSendOtp({tel: telNumberString, callback: openPopUp})
    const handleSaveChanges = async () => {
        const telNumberString = changeNumber(changeData.tel.toString());
        if (changeData.oldPassword.length !== 0 && changeData.newPassword.length !== 0 && confirmPassword.length !== 0 && !loading) {
            if (changeData.newPassword !== confirmPassword) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.unlikePassword,
                }));
            } else {
                if (changeData.email.length !== 0 && !isValidEmail(changeData.email)) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: langObj.wrongEmailWarning,
                    }));
                } else if (changeData.tel.length !== 0 && (telNumberString.length !== 12 || telNumberString.slice(0, 4) !== "+374")) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: langObj.wrongTelWarning,
                    }));
                } else {
                    if (telNumberString !== user.tel) {
                        if (!smsLoading) await sendOtp();
                    } else {
                        await updateUserData({
                            variables: {
                                userDataInput: {
                                    ...changeData,
                                    email: changeData.email === user.email ? '' : changeData.email,
                                    tel: telNumberString,
                                }
                            }
                        });
                    }

                }
            }
        } else if (changeData.oldPassword.length === 0 && changeData.newPassword.length === 0 && confirmPassword.length === 0 && !loading) {
            if (changeData.email.length !== 0 && !isValidEmail(changeData.email)) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.wrongEmailWarning,
                }));
            } else if (telNumberString.length !== 0 && (telNumberString.length !== 12 || telNumberString.slice(0, 4) !== "+374")) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.wrongTelWarning,
                }));
            } else {
                if (telNumberString !== user.tel) {
                    if (!smsLoading) await sendOtp();
                } else {
                    await updateUserData({
                        variables: {
                            userDataInput: {
                                ...changeData,
                                email: changeData.email === user.email ? '' : changeData.email,
                                tel: telNumberString,
                            }
                        }
                    });
                }
            }
        } else {
            dispatch(notifyUser({
                barOpen: true,
                message: langObj.emptyPasswordField,
            }));
        }
    }

    const asyncSendFunction = async () => {
        if (!smsLoading) {
            await sendOtp();
        }
    };

    if (isMobile) {
        return (
            <div className={styles.main_section_mobile}>
                <h1 className={styles.section_heading_mobile}>
                    {langObj.sectionHeading}
                </h1>
                <div className={styles.settings_section_mobile}>

                    <div className={styles.input_div}>
                        <label>{langObj.nameLabel}</label>
                        <input
                            type={"text"}
                            name={"fullName"}
                            maxLength={50}
                            value={changeData.fullName}
                            onChange={(event) => {
                                const input = event.target.value;
                                setChangeData({
                                    ...changeData,
                                    [event.target.name]: input.replace(/[^A-Za-zА-Яа-яԱ-Ֆա-ֆ\s]/g, '')
                                });
                            }}
                        />
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.emailLabel}</label>
                        <input
                            maxLength={50}
                            type={"text"}
                            name={"email"}
                            className={styles.email_input}
                            value={changeData.email}
                            onChange={changeHandler}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.telLabel}</label>
                        <div className={styles.input_div}>
                            <input
                                maxlength="13"
                                type={"text"}
                                name={"tel"}
                                inputMode={'numeric'}
                                value={changeData.tel}
                                onChange={(event) => {
                                    const input = event.target.value;
                                    setChangeData({...changeData, "tel": input.replace(/[^0-9+]/g, '')});
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === '.') e.preventDefault();
                                    else if (
                                        e.target.value.length > 12
                                        && e.key !== 'Backspace'
                                        && e.key !== 'Control'
                                        && e.key !== 'a'
                                        && e.key !== 'ArrowLeft'
                                        && e.key !== 'ArrowRight'
                                    ) e.preventDefault();
                                }}
                            />
                        </div>
                    </div>


                    <div className={styles.input_div}>
                        <label>{langObj.oldPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.oldPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                name={"oldPassword"}
                                onChange={changeHandler}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.oldPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.oldPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.newPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.newPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={newPassRef}
                                name={"newPassword"}
                                onChange={changeHandler}
                                readOnly={newPassInputDisabled}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.newPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.newPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.confirmPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.confirmPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={confirmPassRef}
                                readOnly={confirmPassInputDisabled}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                }}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.confirmPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.confirmPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <button className={styles.save_btn_mobile} onClick={handleSaveChanges}>
                        {langObj.saveBtn}
                    </button>
                </div>
                {
                    isVerifyPopUpOpen &&
                    <VerificationPopUp
                        closePopUp={() => {
                            setIsVerifyPopUpOpen(false);
                        }}
                        callback={async () => {
                            await updateUserData({
                                variables: {
                                    userDataInput: {
                                        ...changeData,
                                        email: changeData.email === user.email ? '' : changeData.email,
                                        tel: telNumberString,
                                    }
                                }
                            });
                        }}
                        tel={telNumberString}
                        asyncSendFunction={asyncSendFunction}
                    />
                }
            </div>
        );
    }
    return (
        <div className={styles.main_section}>
            <h1 className={styles.section_heading}>
                {langObj.sectionHeading}
            </h1>
            <div className={styles.settings_section}>
                <div className={styles.section_row}>
                    <div className={styles.input_div}>
                        <label>{langObj.nameLabel}</label>
                        <input
                            maxLength={50}
                            type={"text"}
                            name={"fullName"}
                            value={changeData.fullName}
                            onChange={(event) => {
                                const input = event.target.value;
                                setChangeData({
                                    ...changeData,
                                    [event.target.name]: input.replace(/[^A-Za-zА-Яа-яԱ-Ֆա-ֆ\s]/g, '')
                                });
                            }}

                        />
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.emailLabel}</label>
                        <input
                            maxLength={50}
                            type={"text"}
                            name={"email"}
                            className={styles.email_input}
                            value={changeData.email}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.telLabel}</label>
                        <div className={styles.input_div}>
                            <input
                                maxlength="13"
                                placeholder={"+374XXXXXXXX"}
                                type={"text"}
                                name={"tel"}
                                value={changeData.tel}
                                onChange={(event) => {
                                    const input = event.target.value;
                                    setChangeData({...changeData, "tel": input.replace(/[^0-9+]/g, '')});
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === '.') e.preventDefault();
                                    else if (
                                        e.target.value.length > 12
                                        && e.key !== 'Backspace'
                                        && e.key !== 'Control'
                                        && e.key !== 'a'
                                        && e.key !== 'ArrowLeft'
                                        && e.key !== 'ArrowRight'
                                    ) e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section_row}>
                    <div className={styles.input_div}>
                        <label>{langObj.oldPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.oldPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                name={"oldPassword"}
                                onChange={changeHandler}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.oldPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.oldPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.newPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.newPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={newPassRef}
                                name={"newPassword"}
                                onChange={changeHandler}
                                readOnly={newPassInputDisabled}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.newPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.newPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.confirmPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                maxLength={50}
                                type={fieldVisibility.confirmPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={confirmPassRef}
                                readOnly={confirmPassInputDisabled}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                }}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.confirmPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.confirmPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                </div>
                <button className={styles.save_btn} onClick={handleSaveChanges}>
                    {langObj.saveBtn}
                </button>
            </div>
            {
                isVerifyPopUpOpen &&
                <VerificationPopUp
                    closePopUp={() => {
                        setIsVerifyPopUpOpen(false);
                    }}
                    callback={async () => {

                        await updateUserData({
                            variables: {
                                userDataInput: {
                                    ...changeData,
                                    email: changeData.email === user.email ? '' : changeData.email,
                                    tel: telNumberString,
                                }
                            }
                        });
                    }}
                    tel={telNumberString}
                    asyncSendFunction={asyncSendFunction}
                />
            }
        </div>
    );
};

export default SettingsSection;