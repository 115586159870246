import styles from './style.module.scss';

const ProductDescription = ({description, isMobile}) => {
    return (
        <div className={isMobile ? styles.review_section_mobile : styles.review_section}>
                {description && description}
        </div>
    );
};

export default ProductDescription;