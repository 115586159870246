import styles from "./style.module.scss";

import en from "../../../../../assets/lang/en/CheckoutPage/en.json";
import {useSelector} from "react-redux";

const CheckoutProductSectionMobile = ({data}) => {
    const lang = useSelector(state => state.globalLang.lang);
    return (
        <div className={styles.section_div}>
            <div className={styles.checkout_products_section}>
                {data && data.map((item, id) => {
                    return (
                        <div className={styles.product_div} key={id}>
                            <img src={item.productImages} alt={"img"} className={styles.product_img}/>
                            <div className={styles.item_heading_div}>
                                <div className={styles.cart_item_heading}>
                                    {lang === "ENG" ? item.productTitle.en : lang === "RU" ? item.productTitle.ru : item.productTitle.am}
                                </div>
                                <div className={styles.cart_item_price}>
                                    <span>{en.priceMobile} {item.defaultPrice + " ֏"}</span>
                                    <span>{item.quantity + " kg"}</span>
                                </div>
                                <div className={styles.total_price}>
                                    {en.totalMobile}{item.price + " ֏"}
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default CheckoutProductSectionMobile;