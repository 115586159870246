import en from "../../assets/lang/en/PagesTitles/en.json";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";

import ContactUsSection from "../../component/organism/ContactUs";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const ContactUs = () => {
    const lang = useSelector(state => state.globalLang.lang);

    return (
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.contactUs : lang === "RU" ? ru.contactUs : en.contactUs}</title>
                <meta name="description" content="
                  Հարցերի դեպքում կապ հաստատեք մեզ հետ կայքում +(374) 77 73 33 73 կամ contact@meathouse.am Էլ․ հասցեով
                "/>
            </Helmet>
            <ContactUsSection />
        </div>
    );
};

export default ContactUs;