import styles from './style.module.scss';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import {makeStyles} from "@mui/styles";



const useStyles = makeStyles({

    root: {
        '& :focus': {
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
            outline: 'none',
            border: 'none',
            width: '100%',
        },
        '& .MuiInputBase-input': {
            padding: '10px',
            width: '100%',
            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px', // change text color
        },
        '& .MuiInput-underline:before': {
            width: '100%',
            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
        },
        '& .MuiInput-underline:after': {
            width: '100%',

            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
        },
        '& .MuiOutlinedInput-input': {
            boxSizing: 'border-box',
            height: '50px',
            width: '100%',
            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
            fontSize: "14px",
        },
        '& .MuiOutlinedInput-input:focus': {
            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
        },
        '& .MuiFormControl-root': {
            width: '100%',
            outline: 'none',
            border: 'none',
        },
        '& .MuiFormControl-root:focus': {
            outline: 'none',
            border: 'none',
        },
        '& .MuiTextField-root:focus': {
            outline: 'none',
            border: 'none',
        },
        '& .MuiTextField-root': {
            width: '100%',
            outline: 'none',
            backgroundColor: '#FFFFFF',
            color: '#223D40',
            borderRadius: '5px',
        },
        '& .Mui-error': {
            outline: 'none',
            border: 'none',
        },

        '& .MuiInputBase-colorPrimary': {
            width: '100%',
            outline: 'none',
            border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            color: '#223D40',
            borderRadius: '5px',
            border: '1px solid #000000 !important',
        },
        '& .MuiPickersClockPointer-pointer': {
            backgroundColor: 'red', // Change the clock color here
        },

    },
    dialog: {
        '& .MuiClock-pin ': {
            backgroundColor: 'red !important',
        },
    },

});


const CustomDatePicker = ({date, changeStateValue}) => {


    const classes = useStyles();

    const shouldDisableTime = (
        value,
        view,
    ) => view === 'hours' && value.hour() > 21 || value.hour() < 10


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{height: '50px'}}>
            <MobileDateTimePicker
                sx={{
                    width: '100%',
                    height: '50px',
                }}
                defaultValue={date}
                minDateTime={date}
                shouldDisableTime={shouldDisableTime}
                className={classes.root}
                onChange={(e) => {
                    changeStateValue(e);
                }}
            />
        </LocalizationProvider>
    )
};

export default CustomDatePicker;