import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import { loginSuccess } from "../../redux/features/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {notifyUser} from "../../redux/features/popup/notifyMessage";

const REGISTER_USER = gql`
    mutation Mutation(
        $registerInput: RegisterInput
    ) {
        registerUser(
            registerInput: $registerInput
        ) {
            token
        }
    }
`;

export const useRegisterUser= ({values}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lang = useSelector(state => state.globalLang.lang);

    const [registerUser, {loading}] = useMutation(REGISTER_USER, {
        update(proxy, { data: { registerUser: userData }}) {
            const token = userData.token;
            dispatch(loginSuccess(token));

            localStorage.setItem('token', token);
            navigate("/");
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: { registerInput: values}
    })
    return {
        registerUser,
        loading
    };
};

