import {useState} from "react";

export const useForm = (callback, initialState = {}) => {
    const [values, setValues] = useState(
        initialState
    );
    const changeHandler = event => {
        setValues({...values, [event.target.name]: event.target.value});
    };

    const setInputValue = (name, value) => {
        setValues({...values, [name]: value});
    };

    const onSubmit = () => {
        callback();
    };
    return {
        changeHandler,
        onSubmit,
        setInputValue,
        values
    };
};