
import {gql, useQuery} from '@apollo/client';

import {useSelector} from 'react-redux';

import ProductSlider from "../../ProductSlider";
import styles from "../style.module.scss";

const GET_SIMILAR_PRODUCTS = gql`
  query($userId: ID!, $productId: [ID!]){
    productsWithIds(_ids: $productId) {
      _id
      state
      productTitle{
        am
        en
        ru
      }
      type
      price
      saleType
      preparationPrice
      productImages{
        productSmallRawImage
        productSmallCookedImage
      }
    }
    userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
    userCartProducts(userId: $userId){
      _id
      price
      quantity
      productId
      productImages
      productTitle{
          en
          am
          ru
      }
      defaultPrice
      state
      saleType
      }
  }
`;

function RecentlyViewed({currentItemId, currentProductState, lang}) {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const recentlyViewedArray = useSelector((state) => state.userViewedItems.recentlyViewed);
    const recentlyViewedArrayCopy = [...recentlyViewedArray];


    const arrayString = recentlyViewedArrayCopy.map(JSON.stringify);
    const itemString = JSON.stringify({"productId": currentItemId.toString(), "productState": currentProductState.toString()});
    const index = arrayString.indexOf(itemString);
    if (index !== -1) {
        recentlyViewedArrayCopy.splice(index, 1);
    }
    const productIds = recentlyViewedArrayCopy.map(item => item.productId);
    const productStates = recentlyViewedArrayCopy.map(item => item.productState);
    const updatedProductIds = [...productIds];


    const { loading, error, data } = useQuery(GET_SIMILAR_PRODUCTS, {
        variables: {
            userId: userId,
            productId: updatedProductIds
        }
    });

    return (
        <div className={styles.main_section}>
            {
                !loading && !error &&  data.productsWithIds.length !== 0 &&
                <div className={isMobile ? styles.section_heading_mobile : styles.section_heading}>
                    <div>
                        <hr/>
                        <h2>
                            {lang.headingRecentlyViewedSection}
                        </h2>
                    </div>
                </div>
            }
            <ProductSlider
                data={data}
                loading={loading}
                error={error}
                products={data ? data.productsWithIds : undefined}
                favorites={data ? data.userFavoriteProducts : undefined}
                cartProducts={data ? data.userCartProducts : undefined}
                sliderHeading={lang.headingRecentlyViewedSection}
                fromSimilar={false}
                fromRecently={productStates}
            />
        </div>
    );
}

export default RecentlyViewed;