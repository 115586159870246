import styles from "./style.module.scss";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";

const CustomLoginInput = ({
                              onChange,
                              onClick,
                              onKeyDown,
                              style,
                              className,
                              id,
                              value,
                              defaultValue,
                              placeholder,
                              type,
                              name,
                              customInputHeight,
                              min,
                              max,
                              inputMode,
                              maxLength,
    ref
                          }) => {
    const [inputType, setInputType] = useState(type);
    const inputRef = useRef(null);

    const handleTypeChange = () => {
        if (inputType === "password") {
            setInputType("text")
            return;
        }
        setInputType("password")
    }

    useEffect(() => {
        setInterval(() => {
            let value = inputRef.current && inputRef.current.value;
            if(value && value != '') {
                inputRef.current.value = value;
            }
        }, 50)
    }, []);
    return (
        <div className={styles.customInputDiv} style={{
            padding: type === "password" && "0 10px 0 0",
            height: customInputHeight && customInputHeight,
        }}>
            <input
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                style={style}
                className={type === "email" ? className + " " + styles.email_input : className + " " + styles.customInput}
                id={id}
                name={name}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={inputType}
                autoComplete="off"
                min={min}
                max={max}
                maxLength={maxLength}
                ref={inputRef}
                inputMode={inputMode}
            />
            {type === 'password' &&
                <span onClick={handleTypeChange}>{inputType === 'text' ? <Visibility/> : <VisibilityOff/>}</span>}
        </div>
    )
}


export default CustomLoginInput;