import CartSection from "../../component/organism/Cart";
import {Helmet} from "react-helmet";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";
import {useSelector} from "react-redux";


const Cart = () => {
    const lang = useSelector(state => state.globalLang.lang);
    return (
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.cart : lang === "RU" ? ru.cart : en.cart}</title>
                <meta name="description" content="
                  The Meat House օգտատերի զամբյուղ
                "/>
            </Helmet>
            <CartSection />
        </div>
    );
};

export default Cart;