import styles from "./style.module.scss";

import am from "../../../assets/lang/am/OrderDone/RejectedSection/am.json";
import en from "../../../assets/lang/en/OrderDone/RejectedSection/en.json";
import ru from "../../../assets/lang/ru/OrderDone/RejectedSection/ru.json";

import RejectedMeat from "../../../assets/backgronds/orderDone/rejectedMeat.webp";

import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const RejectedSection = () => {
    const navigate = useNavigate();

    const lang = useSelector(state => state.globalLang.lang);

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    if(isMobile){
        return (
            <div className={styles.main_section_mobile}>
                <div className={styles.section_heading_mobile}>
                    <div>
                        <hr/>
                        <h1>
                            {langObj.sectionHeading}
                        </h1>
                        <hr/>
                    </div>
                </div>
                <div className={styles.centered_div_mobile}>
                    <img src={RejectedMeat} alt={"meatImg"}/>
                    <p className={styles.paragraph_mobile}>
                        {langObj.paragraph}
                    </p>
                    <div className={styles.btns_div_mobile}>
                        <button className={styles.menu_btn_mobile} onClick={() => {
                            navigate("/catalog");
                        }}>
                            {langObj.menuBtn}
                        </button>
                        <button className={styles.contact_us_btn_mobile} onClick={() => {
                            navigate("/contact_us");
                        }}>
                            {langObj.contactUsBtn}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h1>
                        {langObj.sectionHeading}
                    </h1>
                </div>
            </div>
            <div className={styles.centered_div}>
                <img src={RejectedMeat} alt={"meatImg"}/>
                <p className={styles.paragraph}>
                    {langObj.paragraph}
                </p>
                <div className={styles.btns_div}>
                    <button className={styles.menu_btn} onClick={() => {
                        navigate("/catalog");
                    }}>
                        {langObj.menuBtn}
                    </button>
                    <button className={styles.contact_us_btn} onClick={() => {
                        navigate("/contact_us");
                    }}>
                        {langObj.contactUsBtn}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RejectedSection;