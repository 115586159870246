import ProductPreviewSection from "../../component/organism/Product/ProductPreview";


const ProductPage = () => {

    return(
        <div>
            <ProductPreviewSection />
        </div>
    );
};


export default ProductPage;