import ResetPasswordSection from "../../component/organism/ResetPassword";

const ResetPassword = () => {
    return(
        <>
            <ResetPasswordSection />
        </>
    );
};

export default ResetPassword;