import FavoriteSection from "../../component/organism/Favorite";
import {Helmet} from "react-helmet";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";
import {useSelector} from "react-redux";

const Favorite = () => {
    const lang = useSelector(state => state.globalLang.lang);
    return (
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.favorite : lang === "RU" ? ru.favorite : en.favorite}</title>
                <meta name="description" content="
                  The Meat House օգտատերի նախընտրած ապրանքներ
                "/>
            </Helmet>
           <FavoriteSection/>
        </div>
    );
};

export default Favorite;