import SignInSection from "../../component/organism/Login";
import {Helmet} from "react-helmet";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";
import {useSelector} from "react-redux";

const LoginPage = () => {
    const lang = useSelector(state => state.globalLang.lang);
    return(
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.login : lang === "RU" ? ru.login : en.login}</title>
                <meta name="description" content="
                    The Meat House-ի Կայքի մուտք
                "/>
            </Helmet>
            <SignInSection/>
        </div>
    );
};


export default LoginPage;