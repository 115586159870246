import {useSelector} from "react-redux";
import FavoriteSectionMobile from "./Mobile";
import FavoriteSectionDesktop from "./Deskop";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/FavoritePage/en.json";
import am from "../../../assets/lang/am/FavoritePage/en.json";
import ru from "../../../assets/lang/ru/FavoritePage/en.json";

const FavoriteSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, [])

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    return (
        <div>
            {isMobile
                ?
                <FavoriteSectionMobile langObj={langObj}/>
                :
                <FavoriteSectionDesktop langObj={langObj}/>
            }
        </div>
    );
};


export default FavoriteSection;