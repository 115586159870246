import styles from './style.module.scss';

import React, {useEffect, useRef, useState} from "react";
import closeIcon from "../../../../../assets/icons/layout/sidebar/closeIcon.svg";

import en from "../../../../../assets/lang/en/HomePage/HeroSection/en.json";
import ru from "../../../../../assets/lang/ru/HomePage/HeroSection/en.json";
import am from "../../../../../assets/lang/am/HomePage/HeroSection/en.json";

import specialOffersImg from "../../../../../assets/icons/homePage/commonIcons/specialOffers.png";
import {Link, useNavigate} from "react-router-dom";
import arrowUpIcon from "../../../../../assets/icons/layout/navbar/ArrowUp.png";
import arrowDownIcon from "../../../../../assets/icons/layout/navbar/ArrowDown.png";
import {changeLang} from "../../../../../redux/features/lang/changeLangSlice";
import {useDispatch, useSelector} from "react-redux";

const langArray = ["ENG", "ARM", "RU"];
const MobileSideBar = ({closeSidebar, isOpen}) => {
    const [langDropDownIsOpen, setLangDropDownIsOpen] = useState(false);
    const lang = useSelector(state => state.globalLang.lang);
    const [chosenLang, setChosenLang] = useState(lang);
    const navigate = useNavigate();
    const langDropdownRef = useRef(null);
    const langBtnRef = useRef(null);

    const dispatch = useDispatch();

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);
    const backBtnFunc = (event) => {
        event.preventDefault();
        closeSidebar();
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isOpen) {
            window.addEventListener('popstate', backBtnFunc);
            document.body.style.overflow = "hidden"; // disable scrolling on the page
        } else {
            document.body.style.overflow = "auto"; // re-enable scrolling on the page
            window.removeEventListener('popstate', backBtnFunc)
        }
    }, [isOpen]);

    useEffect(() => {
        dispatch(changeLang(chosenLang));
    },[chosenLang]);

    useEffect(() => {
        const handleClickOutside = (event) => {

            if (!langDropdownRef?.current?.contains(event.target) && !langBtnRef?.current?.contains(event.target)) {
                setLangDropDownIsOpen(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={isOpen ? styles.sidebar_open : styles.sidebar}>
            <div className={styles.close_sidebar_btn_div}>
                <button onClick={closeSidebar} className={styles.close_sidebar_btn}>
                    <img  width="27" height="27" src={closeIcon} alt={"closeIcon"}/>
                </button>
            </div>

                <div className={styles.row_div}>
                    <button
                        className={styles.special_offers_btn}
                        onClick={() => {
                            navigate('/catalog', {
                                state:{
                                    specialOffers: true,
                                }
                            });
                            closeSidebar();
                        }}
                    >
                        <div className={styles.specialOffersImg}/>
                        <span>{langObj.specialOffersBtn}</span>
                    </button>
                    <div>
                    <button className={styles.lang_btn} ref={langBtnRef} onClick={() => {
                        setLangDropDownIsOpen(!langDropDownIsOpen);
                    }}>
                        <span>{chosenLang}</span>
                        {
                            langDropDownIsOpen ?
                                <img width="13" height="8" src={arrowUpIcon} alt={'open'}/>
                                :
                                <img width="13" height="8" src={arrowDownIcon} alt={'close'}/>
                        }
                    </button>
                    {langDropDownIsOpen &&
                        <div
                            className={styles.lang_dropdown_menu}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            ref={langDropdownRef}
                        >
                            {
                                langArray.map((item, id) => {
                                        if (item !== chosenLang) {
                                            return (
                                                <button key={id} onClick={() => {
                                                    setChosenLang(item);
                                                    setLangDropDownIsOpen(false)
                                                }}>
                                                    {item}
                                                </button>
                                            )
                                        }
                                    }
                                )
                            }

                        </div>
                    }
                </div>
            </div>
            <div className={styles.sidebar_section}>
                <Link to={'/catalog'} onClick={()=>{
                    closeSidebar();
                }}>
                    {langObj.menuBtn}
                </Link>
                <Link to={'/about_us'} onClick={()=>{
                    closeSidebar();
                }}>
                    {langObj.aboutPageBtn}
                </Link>
                <Link to={'/contact_us'} onClick={()=>{
                    closeSidebar();
                }}>
                    {langObj.contactsBtn}
                </Link>
                <Link onClick={()=>{
                    closeSidebar();
                }}>
                    {langObj.blogBtn}
                </Link>
            </div>
        </div>
    )
}

export default MobileSideBar;