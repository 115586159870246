import {useMutation, gql} from '@apollo/client';

import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";


const GET_FAVORITE_ITEMS = gql`
  query userFavoriteProducts($userId: ID!) {
    userFavoriteProducts(userId: $userId){
           _id
          productId
          state
    }
  }
`;

const ADD_ITEM_TO_FAVORITE = gql`
    mutation Mutation(
        $addToUserFavoriteInput: AddFavoriteItemInput
    ) {
        addToUserFavorite(
            addToUserFavoriteInput: $addToUserFavoriteInput
        ) {
          _id
          productId
          state
        }
    }
`;


const useAddFavoriteItem = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};
    const dispatch = useDispatch();

    const [addItemToFavorite, {
        loading: addFavoriteLoading,
        error: addFavoriteError
    }] = useMutation(ADD_ITEM_TO_FAVORITE, {
            update: (cache, {data: {addToUserFavorite}}) => {
                const {userFavoriteProducts} = cache.readQuery({
                    query: GET_FAVORITE_ITEMS,
                    variables: {userId: user._id},
                });
                cache.writeQuery({
                    query: GET_FAVORITE_ITEMS,
                    variables: {userId: user._id},
                    data: {userFavoriteProducts: [...userFavoriteProducts, addToUserFavorite]},
                });
            },
            onError({graphQLErrors}) {
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: graphQLErrors[0].message,
                    }));
                }
            }
        },
    );

    return {addItemToFavorite, addFavoriteLoading, addFavoriteError};
}

export default useAddFavoriteItem;