import styles from "./style.module.scss";
import {useState} from "react";

import {useSelector} from "react-redux";


import removeCartIcon from "../../../assets/icons/cartPage/commonIcons/removeIcon.svg";

import minusIcon from "../../../assets/icons/cartPage/commonIcons/minusIcon.svg";
import plusIcon from "../../../assets/icons/cartPage/commonIcons/plusIcon.svg";

import {useEffect} from "react";

import useUpdateCartItem from "../../../hooks/product/cart/useUpdateCartItem";
import useRemoveCartItem from "../../../hooks/product/cart/useRemoveCartItem";


const CartPagePriceCalculator = ({lang, defaultPrice, cartItemId, itemQuantity, itemPrice, loading, state, saleType}) => {
    const changePrice = saleType === "kg" ? defaultPrice / 2 : defaultPrice;
    const changeQuantity =  saleType === "kg" ? 0.5 : 1;

    const [quantity, setQuantity] = useState(changeQuantity);


    const [price, setPrice] = useState(Number(defaultPrice) / 2);


    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const {updateCartItem, updateCartError, updateCartLoading} = useUpdateCartItem();
    const {removeCartItem, removeItemError, removeItemLoading} = useRemoveCartItem();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    useEffect(() => {
        if (!loading && itemQuantity) {
            setQuantity(itemQuantity);
            setPrice(itemPrice);
        }
    }, [itemQuantity, itemPrice, loading]);


    const handleChangeQuantity = async (type) => {
        if (!isButtonDisabled && type === "+") {
            if (!loading) {
                if (!isButtonDisabled
                    && !updateCartLoading
                    && !updateCartError
                    && !removeItemLoading
                    && !removeItemError
                ) {

                    if ( quantity) {
                        setIsButtonDisabled(true);
                        await updateCartItem({
                            variables: {
                                updateCartInput: {
                                    cartItemId: cartItemId,
                                    price: price + changePrice,
                                    quantity: quantity + changeQuantity,
                                    state: state,
                                }
                            },

                        });
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                        setTimeout(() => {
                            setIsButtonDisabled(false);
                        }, 150)
                    } else {
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                    }

                }
            }

        } else {
            if (!isButtonDisabled && quantity > changeQuantity) {
                if (quantity !== 1 && !loading) {
                    if (!isButtonDisabled
                        && !updateCartLoading
                        && !updateCartError
                        && !removeItemLoading
                        && !removeItemError
                    ) {
                        if (quantity) {
                            setIsButtonDisabled(true);
                            await updateCartItem({
                                variables: {
                                    updateCartInput: {
                                        cartItemId: cartItemId,
                                        price: quantity === 1 ? price : price - changePrice,
                                        quantity: quantity === 1 ? quantity : quantity - changeQuantity,
                                        state: state,
                                    }
                                },
                            });
                            setQuantity(quantity - changeQuantity);
                            setPrice(price - changePrice);
                            setTimeout(() => {
                                setIsButtonDisabled(false);
                            }, 150);
                        } else {
                            setQuantity(quantity === 1 ? quantity : quantity - changeQuantity,);
                            setPrice(quantity === 1 ? price : price - changePrice);
                        }
                    }
                }

            }
        }

    };


    if (isMobile) {
        return (
            <div className={styles.calculator_section_mobile}>
                <div className={styles.quantity_div_mobile} onKeyDown={(event) => {
                    event.stopPropagation();
                }}>
                    <button className={styles.calculator_btn_mobile} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("-");
                    }}>
                        <img src={minusIcon} alt={'-'}/>
                    </button>
                    <div className={styles.quantity_mobile}>
                        {quantity} {changeQuantity !== 1 ?  lang.kg : lang.piece}
                    </div>
                    <button className={styles.calculator_btn_mobile} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("+");
                    }}>
                        <img src={plusIcon} alt={'+'}/>
                    </button>

                </div>

                <div className={styles.price_mobile}>
                    {lang.totalMobile + " " +price} ֏
                </div>
            </div>


        );
    }

    return (
        <div className={styles.calculator_section_desktop}>
            <div className={styles.calculator_div}>
                <div className={styles.quantity_div} onKeyDown={(event) => {
                    event.stopPropagation();
                }}>
                    <button className={styles.calculator_btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("-");
                    }}>
                        <img src={minusIcon} alt={'-'}/>
                    </button>
                    <div className={styles.quantity}>
                        {quantity} {changeQuantity !== 1 ?  lang.kg : lang.piece}
                    </div>
                    <button className={styles.calculator_btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("+");
                    }}>
                        <img src={plusIcon} alt={'+'}/>
                    </button>

                </div>
            </div>
            <div className={styles.price}>
                {price} ֏
            </div>
            <div className={isMobile ? styles.display_none : styles.remove_item_btn_div}>
                <button className={styles.remove_item_btn} onClick={async () => {
                    await removeCartItem({
                        variables: {
                            removeCartItemInput: {
                                cartItemId: cartItemId,
                            },
                        },
                    });
                }}>
                    <img src={removeCartIcon} alt="icon"/>
                </button>
            </div>
        </div>


    );
};

export default CartPagePriceCalculator;