import SettingsSection from "../../component/organism/Settings";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";

const Settings = () => {
    const lang = useSelector(state => state.globalLang.lang);
    return(
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.settings : lang === "RU" ? ru.settings : en.settings}</title>
                <meta name="description" content="
                    The Meat House-ի Կայքի օգտատիրոջ կարգավորումներ։
                "/>
            </Helmet>
            <SettingsSection />
        </div>
    );
};

export default Settings;