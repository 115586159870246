import styles from "../../style.module.scss";
import backIcon from "../../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";
import {useSelector} from "react-redux";
import OtpInput from 'react-otp-input';
import closeIcon from "../../../../../assets/icons/catalogPage/sideBarClose.png";
import {useEffect, useRef, useState} from "react";



const VerificationSection = ({
                                 changeSection,
                                 lang,
                                 onSubmit,
                                 setInputValue,
                                 values,
                                 asyncSendFunction,
                                 closePopUp,
                                 callback,
                                 loading,
                                 setOtpCode,
                                 otpCode
}) => {
    const language = useSelector(state => state.globalLang.lang);
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const [btnDisabled, setBtnDisabled] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setBtnDisabled(false);
        }, 30000);

    }, []);
    const otpToCheck = values && values.otpCode ? values.otpCode : otpCode;

    const otpInputRef = useRef(null);

    useEffect(() => {
        const handleInput = (e) => {
            if (e.inputType === 'insertText') {
                // iOS autofill was triggered; prevent navigation
                e.preventDefault();
            }
        };

        window.addEventListener('input', handleInput);

        return () => {
            window.removeEventListener('input', handleInput);
        };
    }, []);
    if (isMobile) {
        return (
            <form className={styles.main_section_div_mobile} onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <div className={styles.login_section_mobile}>
                    <div className={styles.back_btn_div_mobile}>
                        {
                            closePopUp
                                ?
                                <div
                                    className={styles.back_btn + " " + styles.position_relative_right + " " + styles.no_margin}
                                    onClick={() => {
                                        closePopUp()
                                    }}
                                >
                                   <img src={closeIcon} alt={"close"}/>
                                </div>

                                :
                                <div
                                    className={styles.back_btn + " " + styles.position_relative_left + " " + styles.no_margin}
                                    onClick={() => {
                                        changeSection(2);
                                    }}
                                >
                                    <img src={backIcon} alt={"icon"}/>
                                    <span>{lang.backBtn}</span>
                                </div>
                        }

                    </div>
                    <h1 className={styles.heading_mobile_ru  + " " +  styles.verification_heading_size}>
                        {lang.confirmHeading}
                    </h1>

                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label}>
                            {lang.otpLabel}
                        </label>
                        <div className={styles.key_inp_frame}>
                            <OtpInput
                                ref={otpInputRef}
                                onChange={(code) => {
                                    if(closePopUp) {
                                        setOtpCode(code);
                                    }else {
                                        setInputValue("otpCode", code);
                                    }
                                }}
                                numInputs={5}
                                onPaste={(event) => {
                                    const data = event.clipboardData.getData('text');
                                    if(closePopUp) {
                                        setOtpCode(data.slice(0,5));
                                    }else {
                                        setInputValue("otpCode", data.slice(0,5));
                                    }
                                }}
                                value={otpToCheck}
                                inputType={"number"}
                                inputStyle={styles.key_inp}
                                renderInput={(props) => <input {...props} type={"text"}/>}
                                shouldAutoFocus={true}
                            />
                        </div>
                    </div>
                    {
                        language === "RU"
                            ?
                            <button
                                className={styles.login_popup_btn}
                                onClick={() => {
                                    if (otpToCheck.length === 5) {
                                        if(closePopUp) {
                                            if(!loading) callback();
                                        }else {
                                            onSubmit();
                                        }
                                    }
                                }}
                            >
                                {lang.confirmBtn}
                            </button>
                            :
                            <button
                                className={styles.login_btn}

                            >
                                <div className={styles.plate_on_flour_mobile}>
                                    <div className={styles.flour_img_mobile}/>
                                    <button
                                        className={styles.plate_img_mobile}
                                        onClick={() => {
                                            if (otpToCheck.length === 5) {
                                                if(closePopUp) {
                                                    if(!loading) callback();
                                                }else {
                                                    onSubmit();
                                                }
                                            }
                                        }}
                                    />
                                    <div className={styles.login_text_mobile} onClick={() => {
                                        if (otpToCheck.length === 5) {
                                            if(closePopUp) {
                                                if(!loading) callback();
                                            }else {
                                                onSubmit();
                                            }
                                        }
                                    }}>
                                        {lang.confirmBtn}
                                    </div>
                                </div>
                            </button>
                    }
                    <div className={styles.register_btn_mobile}>
                    <span>
                       {lang.resendCode.regular}
                    </span>
                        <button disabled={btnDisabled} onClick={() => {
                            asyncSendFunction()
                            setBtnDisabled(true);
                            setTimeout(() => {
                                setBtnDisabled(false);
                            }, 30000);
                        }}>
                            {lang.resendCode.red}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
    return (
        <form className={styles.main_section_div} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}
              onKeyDown={(e) => {
                  if (e.key === "Enter" && otpToCheck.length === 5) {
                      e.preventDefault();
                      e.stopPropagation();
                      if (otpToCheck.length === 5) {
                          if(closePopUp) {
                              if(!loading) callback();
                          }else {
                              onSubmit();
                          }
                      }
                  }
              }}
        >
            <div className={styles.login_section + " " + styles.forgot_pass_gap}>
                    {
                        closePopUp
                            ?
                            <div className={styles.heading_div}>
                                <h1 className={styles.heading}>
                                    {lang.confirmHeading}
                                </h1>
                                <button
                                    className={styles.back_btn + " " + styles.position_absolute_right }
                                    onClick={() => {
                                        closePopUp()
                                    }}
                                >
                                    <img src={closeIcon} alt={"close"}/>
                                </button>

                            </div>


                            :
                            <div className={styles.heading_div}>
                                <button className={styles.back_btn} onClick={() => {
                                    changeSection(2);
                                }}>
                                    <img src={backIcon} alt={"icon"}/>
                                    <span>{lang.backBtn}</span>
                                </button>
                                <h1 className={styles.heading}>
                                    {lang.confirmHeading}
                                </h1>
                            </div>
                    }

                <div className={styles.inputdiv}>
                    <label htmlFor="name" className={styles.label}>
                        {lang.otpLabel}
                    </label>
                    <div className={styles.key_inp_frame}>
                        <OtpInput
                            ref={otpInputRef}
                            onChange={(code) => {
                                if(closePopUp) {
                                    setOtpCode(code);
                                }else {
                                    setInputValue("otpCode", code);
                                }
                            }}
                            numInputs={5}
                            onPaste={(event) => {
                                const data = event.clipboardData.getData('text');
                                if(closePopUp) {
                                    setOtpCode(data.slice(0,5));
                                }else {
                                    setInputValue("otpCode", data.slice(0,5));
                                }
                            }}
                            value={otpToCheck}
                            inputType={"number"}
                            inputStyle={styles.key_inp}
                            renderInput={(props) => <input {...props} type={"text"}/>}
                            shouldAutoFocus={true}
                        />
                    </div>
                </div>
                {
                    language === "RU"
                        ?
                        <button
                            className={styles.login_popup_btn}
                            onClick={() => {
                                if (otpToCheck.length === 5) {
                                    if(closePopUp) {
                                        if(!loading) callback();
                                    }else {
                                        onSubmit();
                                    }
                                }
                            }}
                        >
                            {lang.confirmBtn}
                        </button>
                        :
                        <button
                            className={styles.login_btn}

                        >
                            <div className={styles.plate_on_flour}>
                                <div className={styles.flour_img}/>
                                <div
                                    className={styles.plate_img}
                                    onClick={() => {
                                        if (otpToCheck.length === 5) {
                                            if(closePopUp) {
                                                if(!loading) callback();
                                            }else {
                                                onSubmit();
                                            }
                                        }
                                    }}
                                />
                                <div className={styles.login_text} onClick={() => {
                                    if (otpToCheck.length === 5) {
                                        if(closePopUp) {
                                            if(!loading) callback();
                                        }else {
                                            onSubmit();
                                        }
                                    }
                                }}>
                                    {lang.confirmBtn}
                                </div>
                            </div>
                        </button>
                }
                <div className={styles.register_btn}>
                    <span>
                       {lang.resendCode.regular}
                    </span>
                    <button disabled={btnDisabled} onClick={() => {
                        asyncSendFunction()
                        setBtnDisabled(true);
                        setTimeout(() => {
                            setBtnDisabled(false);
                        }, 30000);
                    }}>
                        {lang.resendCode.red}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default VerificationSection;