import styles from './style.module.scss';
import FaqDropdown from "../FaqDropdown";
import {useSelector} from "react-redux";


const FaqSection = ({lang}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    if(isMobile){
        return (
            <div className={styles.main_section}>
                <div className={styles.section_question_mobile}>
                    {lang.sectionQuestion}
                </div>
                <div className={styles.question_section_mobile}>
                        <FaqDropdown heading={lang.question1} paragraph={lang.answer1}/>
                        <FaqDropdown heading={lang.question2} paragraph={lang.answer2}/>
                        <FaqDropdown heading={lang.question3} paragraph={lang.answer3}/>
                        <FaqDropdown heading={lang.question4} paragraph={lang.answer4}/>
                </div>
            </div>
        )
    }
    return(
        <div className={styles.main_section}>
            <div className={styles.section_question}>
                {lang.sectionQuestion}
            </div>
            <div className={styles.question_section}>
                <div className={styles.question_section_two_divs}>
                    <FaqDropdown heading={lang.question1} paragraph={lang.answer1}/>
                    <FaqDropdown heading={lang.question2} paragraph={lang.answer2}/>
                </div>
                <div className={styles.question_section_two_divs + ' ' + styles.second_question_div}>
                    <FaqDropdown heading={lang.question3} paragraph={lang.answer3}/>
                    <FaqDropdown heading={lang.question4} paragraph={lang.answer4}/>
                </div>
            </div>
        </div>
    );
};

export default FaqSection;