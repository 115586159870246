import styles from "./style.module.scss";

import dropIcon from '../../../assets/icons/catalogPage/downArrow.svg'

import upArrow from '../../../assets/icons/catalogPage/upArrow.svg'

import {useEffect, useState} from "react";

const OptionalDropdown = ({label, options, changeState, value}) => {
    const [isOpen, setIsOpen] = useState(options.find(element => value.categories.includes(element.value)));
    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleOptionClick = (option) => {
        if (value.categories.includes(option.value)) {
            let arr = value && value.categories && typeof(value.categories === "object") ? value.categories : [];
            arr = arr.filter(el => el !== option.value);
            changeState({
                ...value,
                categories: arr
            });
        } else {
            let arr = value && value.categories && typeof(value.categories === "object") ? value.categories : [];
            arr.push(option.value);
            changeState({
                ...value,
                categories: arr
            });
        }
    };
    useEffect(() => {
        setIsOpen(options.find(element => value.categories.includes(element.value)));
    }, [value])

    return (
        <div className={styles.dropdown}>
            <button
                className={isOpen ? styles.dropdown__toggle + ' ' + styles.dropdown_toggled_btn : styles.dropdown__toggle}
                onClick={toggleDropdown}>
                <label>{label}</label>
                <div className={isOpen ? styles.toggle_icon_dropped : styles.toggle_icon}>
                    {
                        isOpen ?
                            <img src={upArrow} alt={'dropIcon'}/>
                            :
                            <img src={dropIcon} alt={'dropIcon'}/>
                    }
                </div>
            </button>
            {isOpen && (
                <div className={styles.dropdown__option__div}>
                    {options.map((option, id) => (
                        <div
                            key={id}
                            className={id + 1 === options.length ? styles.dropdown_option + " " + styles.dropdown_last_option : styles.dropdown_option}
                            onClick={() => {
                                handleOptionClick(option)

                            }}
                        >
                            <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={value.categories.includes(option.value)}
                                readOnly
                            />
                            <span>
                                {option.name}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OptionalDropdown;
