import styles from './style.module.scss';
import nextIcon from "../../../../../assets/icons/homePage/commonIcons/nextIcon.png";
import reviewPlate from "../../../../../assets/backgronds/home/reviewSection/plateImg.webp"

import {useState} from "react";
import {useSelector} from "react-redux";
import prevIcon from "../../../../../assets/icons/homePage/commonIcons/prevIcon.png";

import commentIcon from "../../../../../assets/icons/homePage/commonIcons/commentIcon.png"

import {Rating} from "@mui/material";
import ReviewInputField from "../../../../atom/ProductReviewInputField";
import {gql, useQuery} from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {makeStyles} from "@mui/styles";


const GET_REVIEWS = gql`
  query($limit: Int!, $skip: Int!) {
    storeReviews(limit: $limit, skip: $skip){
      _id
      userName
      rating
      date
      comment
      collectionLength
    }
  }
`;

const useStyles = makeStyles((theme) => ({

    emptyStar: {
        color: "#730101"
    }
}));


const ReviewsSectionDesktop = ({lang}) => {
    const classes = useStyles();

    const [limit, setLimit] = useState(0);


    const {loading, error, data, refetch} = useQuery(GET_REVIEWS, {
        variables: {
            limit: 3,
            skip: limit,
        }
    })


    if (error) {
        return (
            <div>
                error :(
            </div>
        );
    } else if (!loading && !error && data.storeReviews.length < 3) {
        return (
            <div>
                there is no reviews yet
                <ReviewInputField setSkip={() => {
                    setLimit(0);
                }}/>
            </div>
        )
    }
    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h2>
                        {lang.sectionHeading}
                    </h2>
                </div>
            </div>
            <div className={styles.review_centered_section}>
                <h2>
                    {lang.sectionQuestion}
                </h2>
                <div className={styles.heading_btn_div}>
                    <button className={styles.swiper_nav_btn} onClick={() => {
                        if (limit - 3 >= 3) {
                            setLimit(limit - 3);
                        } else if (limit - 3 >= 0 && limit - 3 < 3) {
                            setLimit(0);
                        } else {
                            setLimit(data.storeReviews[0].collectionLength - 3);
                        }
                    }}>
                        <img src={prevIcon} alt="prev"/>
                    </button>
                    <div className={styles.swiper_div}>

                        {!loading && !error ?
                            <div className={styles.sliderDiv}>
                                <img src={commentIcon} alt="icon"/>
                                <p> {data.storeReviews[0].comment} </p>
                                <Rating
                                    name="rating"
                                    value={data.storeReviews[0].rating}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }
                                />
                                <span className={styles.username}>{data.storeReviews[0].userName}</span>
                                <span>{data.storeReviews[0].date}</span>
                            </div>
                            :
                            <div className={styles.sliderDiv}>
                                <img src={commentIcon} alt="icon"/>
                                <p><Skeleton/></p>
                                <Rating
                                    name="rating"
                                    value={0}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }
                                />
                                <span> <Skeleton/></span>
                            </div>
                        }
                        {!loading && !error ?
                            <div className={styles.sliderDiv + ' ' + styles.center_slide}>
                                <img src={commentIcon} alt="icon"/>
                                <p> {data.storeReviews[1].comment} </p>
                                <Rating
                                    name="rating"
                                    value={data.storeReviews[1].rating}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }/>
                                <span className={styles.username}>{data.storeReviews[1].userName}</span>
                                <span>{data.storeReviews[1].date}</span>
                            </div>
                            :
                            <div className={styles.sliderDiv + ' ' + styles.center_slide}>
                                <img src={commentIcon} alt="icon"/>
                                <p><Skeleton/></p>
                                <Rating
                                    name="rating"
                                    value={0}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }
                                />
                                <span> <Skeleton/></span>
                            </div>
                        }
                        {!loading && !error ?
                            <div className={styles.sliderDiv}>
                                <img src={commentIcon} alt="icon"/>
                                <p> {data.storeReviews[2].comment} </p>
                                <Rating
                                    name="rating"
                                    value={data.storeReviews[2].rating}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }
                                />
                                <span className={styles.username}>{data.storeReviews[2].userName}</span>
                                <span className={styles.date}>{data.storeReviews[2].date}</span>
                            </div>
                            :
                            <div className={styles.sliderDiv}>
                                <img src={commentIcon} alt="icon"/>
                                <p><Skeleton/></p>
                                <Rating
                                    name="rating"
                                    value={0}
                                    readOnly={true}
                                    sx={{
                                        color: '#730101',
                                        borderColor: '#730101'
                                    }}
                                    emptyIcon={
                                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                                    }
                                />
                                <span> <Skeleton/></span>
                            </div>
                        }
                    </div>
                    <div className={styles.swiper_btn_div}>

                        <button className={styles.swiper_nav_btn}
                                onClick={async () => {
                                    if (!loading && !error && limit + 6 <= data.storeReviews[0].collectionLength) {
                                        setLimit(limit + 3);
                                    } else if(!loading && !error){
                                        if (limit + 3 === data.storeReviews[0].collectionLength) {
                                            setLimit(0);
                                        } else {
                                            setLimit(data.storeReviews[0].collectionLength - 3);
                                        }
                                    }
                                }}>
                            <img src={nextIcon} alt="next"/>
                        </button>
                    </div>
                </div>
                <div className={styles.comment_input}>
                    <img src={reviewPlate} alt={'plate'}/>
                    <h3>
                        {lang.inputLabel}
                    </h3>
                    <ReviewInputField
                        lang={lang}
                        refetch={() => {
                            refetch()
                        }}
                        setSkip={async () => {
                            setLimit(0);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewsSectionDesktop;