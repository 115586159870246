import "./assets/style.scss";
import {useRoutes} from "./routes";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeScreenType} from "./redux/features/isMobileSlice";
import CustomNotificationBar from "./component/atom/CustomNotificationBar";
import {logoutSuccess} from "./redux/features/user/userSlice";

function App() {
    const routes = useRoutes();
    const dispatch = useDispatch();

    const notifyBarOpen = useSelector(state => state.globalNotification.notify.barOpen);

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const _id = isAuthenticated ? stateUser.user._id : '';
    const fetchData = async () => {
        const res = _id && await fetch('https://server.meathouse.am/api/userData', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: _id,
            }),
        })
        return res && res.json();
    }
    useEffect(() => {
        fetchData()
            .then(userData => {
                if(userData && (userData.email !== stateUser.user.email || userData.tel !== stateUser.user.tel) ){
                    dispatch(logoutSuccess());
                }else if (!userData){
                    dispatch(logoutSuccess());
                }
            })
    }, []);

    const handleResize = (event) => {
        const width = event.target.innerWidth
        if (width > 1024) {
            dispatch(changeScreenType(false));
        } else {
            dispatch(changeScreenType(true));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    return (
        <div>
            {routes}
            {notifyBarOpen &&
                <CustomNotificationBar/>
            }
        </div>
    );
}

export default App;
