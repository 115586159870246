import styles from "./style.module.scss";

import filledCookedFavoriteIcon from "../../../assets/icons/homePage/commonIcons/filledCookedFavoriteIcon.png";
import cookedFavoriteIcon from "../../../assets/icons/homePage/commonIcons/cookedFavoriteIcon.png";

import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {addRecentlyViewedItem} from "../../../redux/features/user/addRecentlyViewedSlice";
import {openPopup} from "../../../redux/features/popup/openLoginPopupSlice";

import {useNavigate} from "react-router-dom";

import {useSelector} from 'react-redux';

import PriceCalculator from '../../atom/PriceCalculator'

import useAddFavoriteItem from '../../../hooks/product/favorite/useAddFavoriteItem';
import useRemoveFavoriteItem from '../../../hooks/product/favorite/useRemoveFavoriteItem';
import {LazyLoadImage} from "react-lazy-load-image-component";

const ProductCart = ({
                         state,
                         preparationPrice,
                         productImages,
                         productId,
                         productTitle,
                         userCartProducts,
                         userFavoriteProducts,
                         loading,
                         defaultPrice,
                         saleType
                     }) => {
    const {addItemToFavorite, addFavoriteError, addFavoriteLoading} = useAddFavoriteItem();
    const {removeFavoriteItem, removeFavoriteError, removeFavoriteLoading} = useRemoveFavoriteItem();


    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';
    const lang = useSelector(state => state.globalLang.lang);
    const [favoriteProductId, setFavoriteProductId] = useState('');

    const [idArray, setIdArray] = useState([]);

    const navigate = useNavigate();

    const handleClick = (productId) => {
        navigate(`/catalog/${productId}`, {
            state: {
                productState: state,
            }
        });
    }

    const findArrayInArray = (array, includedArray) => {
        const searchArr = array.map(JSON.stringify);
        const arr = JSON.stringify(includedArray);
        const ifIncludes = searchArr.includes(arr);
        return ifIncludes;
    }

    useEffect(() => {
        if (!loading && userFavoriteProducts) {
            const elements = userFavoriteProducts ? userFavoriteProducts : [];
            setIdArray(elements.map(obj => [obj.productId, obj.state]));
            if (productId && elements && elements.length >= 1) {
                const item = elements.find(item => item.productId === productId && item.state === state);
                if (item) {
                    setFavoriteProductId(item._id);
                }
            }
        }

    }, [userFavoriteProducts, loading])


    return (
        <div
            className={styles.product_div}>
            <div className={styles.product_background_div + ' '}>
            </div>
            <div className={styles.product_img_div}>
                <LazyLoadImage
                    src={productImages}
                    alt={"img"}
                    className={styles.product_img}
                    loading={"lazy"}
                    width={"225"}
                    height={"225"}
                    onClick={() => {
                        handleClick(productId);
                        dispatch(addRecentlyViewedItem({
                            productId: productId,
                            productState: state
                        }));
                    }}/>
            </div>
            <div className={styles.product_description}>
                <div className={styles.title_favorite_div}>
                    <h3
                        className={styles.product_title}>
                        {lang === "ENG" ? productTitle.en : lang === "RU" ? productTitle.ru : productTitle.am}
                    </h3>
                    {findArrayInArray(idArray, [productId, state]) ?
                        <button
                            className={styles.favorite_btn}
                            onClick={async () => {
                                if (isAuthenticated && !addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError) {
                                    await removeFavoriteItem({
                                        variables: {
                                            removeFromFavoriteInput: {
                                                favoriteItemId: favoriteProductId,
                                            },
                                        },
                                    });
                                }
                            }}>

                            <img width="19" height="17" src={filledCookedFavoriteIcon} alt={"favorite"}/>

                        </button>
                        :
                        <button
                            className={styles.favorite_btn}
                            onClick={async () => {
                                if (isAuthenticated) {
                                    if (!addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError) {
                                        await addItemToFavorite({
                                            variables: {
                                                addToUserFavoriteInput: {
                                                    userId: userId,
                                                    productId: productId,
                                                    state: state,
                                                },
                                            },
                                        });
                                    }
                                } else {
                                    dispatch(openPopup({
                                        popupOpen: true,
                                        fromNavbar: ''
                                    }));
                                }
                            }}>

                            <img width="19" height="17" src={cookedFavoriteIcon} alt={"favorite"}/>


                        </button>
                    }
                </div>
                <PriceCalculator
                    saleType={saleType}
                    defaultPrice={state === 'raw' ? defaultPrice : defaultPrice + preparationPrice}
                    id={productId}
                    state={state}
                    cartProducts={userCartProducts}
                    loading={loading}
                    productImages={productImages}
                    productTitle={productTitle}
                    openPopup={() => {
                        dispatch(openPopup({
                            popupOpen: true,
                            fromNavbar: ''
                        }));
                    }}
                />


            </div>
        </div>
    );

};

export default ProductCart;