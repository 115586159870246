import EmptyPageDesktop from "./Desktop";
import EmptyPageMobile from "./Mobile";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/EmptyPage/en.json";
import am from "../../../assets/lang/am/EmptyPage/en.json";
import ru from "../../../assets/lang/ru/EmptyPage/en.json";

const EmptyPage = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    return (
        <div>
            {
                isMobile
                    ?
                    <EmptyPageMobile lang={langObj}/>
                    :
                    <EmptyPageDesktop lang={langObj}/>
            }
        </div>
    );
};

export default EmptyPage;