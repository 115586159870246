import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";
import {useDispatch, useSelector} from "react-redux";

const SEND_OTP = gql`
    mutation Mutation(
        $telInput: TelInput
    ) {
        sendOtp(
            telInput: $telInput
        ) {
            message
        }
    }
`;


const useSendOtp = ({tel, callback}) => {
    const lang = useSelector(state => state.globalLang.lang);
    const dispatch = useDispatch();
    const [sendOtp, {loading: smsLoading}] = useMutation(SEND_OTP, {
        update(proxy) {
            callback();
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {
            telInput: {
                tel: tel,
            },
        },
    });

    return{
        sendOtp,
        smsLoading
    };
};


export default useSendOtp;