import styles from './style.module.scss';
import en from "../../../assets/lang/en/OrdersPage/en.json";
import am from "../../../assets/lang/am/OrdersPage/en.json";
import ru from "../../../assets/lang/ru/OrdersPage/en.json";

import priceBg from "../../../assets/backgronds/orders/priceBg.png";

import {gql, useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import {useEffect, useState} from "react";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";

import {Grid} from "swiper";
import prevIcon from "../../../assets/icons/homePage/commonIcons/prevIcon.png";
import nextIcon from "../../../assets/icons/homePage/commonIcons/nextIcon.png";
import OrderPopUp from "../../molecul/OrdersPage/PopUp";
import EmptyPage from "../../molecul/EmptyPage";


const GET_USER_ORDERS = gql`
  query userOrders($userId: ID!) {
    userPendingOrders(userId: $userId){
        totalPrice
        orderNumber
        date
        productData{
            productTitle{
                en
                am
                ru
            }
            defaultPrice
            price
            productImages
            quantity
        }
    }
    userDeliveredOrders(userId: $userId){
        totalPrice
        orderNumber
        date
         productData{
            productTitle{
                en
                am
                ru
            }
            defaultPrice
            price
            productImages
            quantity
        }
      }
  }
`;


const OrdersSection = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const _id = isAuthenticated ? stateUser.user._id : '';

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState([]);

    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const [swiper, setSwiper] = useState();

    const {data, error, loading, refetch} = useQuery(GET_USER_ORDERS, {
        variables: {
            userId: _id,
        }
    });

    const lang = useSelector(state => state.globalLang.lang);

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    const [ordersData, setOrdersData] = useState([]);
    useEffect(()=>{
        refetch();
        window.scrollTo(0 , 0);
    }, [])
    useEffect(() => {
        if (!loading, !error, data) {
            setOrdersData([...data.userPendingOrders, ...data.userDeliveredOrders]);
        }
    }, [loading, error, data])
    if (loading) {
        return (
            <div>
                <Skeleton/>
            </div>
        );
    } else if (error) {
        return (
            <div>
                error :(
            </div>
        )
    }

    if(ordersData.length === 0){
        return (
            <>
                <EmptyPage />
            </>
        )
    }

    return (
        <div className={ isMobile ? styles.main_section_mobile : styles.main_section}>
            {
                isMobile
                    ?
                    <div className={styles.section_heading_mobile}>
                        <div>
                            <hr/>
                            <h1>
                                {langObj.sectionHeading}
                            </h1>
                            <hr/>
                        </div>
                    </div>
                    :
                    <div className={styles.section_heading}>
                        <div>
                            <hr/>
                            <h1>
                                {langObj.sectionHeading}
                            </h1>
                        </div>
                    </div>
            }

            <div>
                <Swiper
                    onSwiper={(swiper) => {
                        setSwiper(swiper);
                    }}

                    threshold={50}
                    spaceBetween={30}
                    wrapperClass={styles.swiper_wrapper}
                    className={isMobile ? styles.swiper_mobile : styles.swiper}
                    loop={false}
                    modules={[Grid]}
                    grid={{
                        rows: 2,
                        fill: 'row'
                    }}
                    breakpoints={
                        {
                            0: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                                grid: {

                                    rows: 1,
                                    fill: 'row'
                                }
                            },
                            871: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                                grid: {

                                    rows: 2,
                                    fill: 'row'
                                }
                            },
                            1100: {
                                slidesPerView: 2,
                                slidesPerGroup: 1,
                                grid: {

                                    rows: 2,
                                    fill: 'row'
                                }
                            }
                        }
                    }
                >
                    {ordersData.map((item, id) => {
                        if (isMobile) {
                            return (
                                <SwiperSlide className={styles.swiper_slide} key={id}>
                                    <div key={id} className={styles.order_div_mobile}>
                                        <div className={styles.order_info_div}>
                                            <div className={styles.order_number_mobile}>
                                                <span>{langObj.orderNumber + item.orderNumber}</span>
                                                <span className={styles.date_span}>{item.date}</span>
                                            </div>
                                            <div className={styles.price_div_mobile}>
                                                <span>{item.totalPrice + " ֏"}</span>
                                                <img src={priceBg} alt={'bg'}/>
                                            </div>
                                        </div>
                                        <div className={styles.product_img_div_mobile}>
                                            {item.productData.slice(0, 3).map((item, id1) => {
                                                return (
                                                    <img src={item.productImages} alt={'productImage'} key={id1}/>
                                                )
                                            })}
                                        </div>
                                        <div className={styles.btn_div_mobile} onClick={() => {
                                            setPopupData(item.productData)
                                            setIsPopupOpen(true);
                                        }}>
                                            <button>{langObj.detailsBtn}</button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        } else {
                            return (
                                <SwiperSlide className={styles.swiper_slide} key={id}>
                                    <div key={id} className={styles.order_div}>
                                        <div className={styles.order_info_div}>
                                            <div className={styles.order_number}>
                                                <span>{langObj.orderNumber + item.orderNumber}</span>
                                                <span className={styles.date_span}>{item.date}</span>
                                            </div>
                                            <div className={styles.price_div}>
                                                <span>{item.totalPrice + " ֏"}</span>
                                                <img src={priceBg} alt={'bg'}/>
                                            </div>
                                        </div>
                                        <div className={styles.product_img_div}>
                                            {item.productData.slice(0, 3).map((item, id1) => {
                                                return (
                                                    <img src={item.productImages} alt={'productImage'} key={id1}/>
                                                )
                                            })}
                                        </div>
                                        <div className={styles.btn_div} onClick={() => {
                                            setPopupData(item.productData)
                                            setIsPopupOpen(true);
                                        }}>
                                            <button>{langObj.detailsBtn}</button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        }
                    })}
                </Swiper>
            </div>
            <div className={styles.swiper_btn_div}>
                <button className={styles.swiper_nav_btn} onClick={() => {
                    swiper.slidePrev();
                }}>
                    <img src={prevIcon} alt="prev"/>
                </button>
                <button className={styles.swiper_nav_btn} onClick={() => {
                    swiper.slideNext();
                }}>
                    <img src={nextIcon} alt="next"/>
                </button>
            </div>

            {isPopupOpen &&
                <OrderPopUp data={popupData} closePopup={() => {
                    setIsPopupOpen(false);
                }}/>
            }
        </div>
    );
};

export default OrdersSection;
