import styles from "./style.module.scss";
import {useState} from "react";

import {useSelector} from "react-redux";

import en from '../../../assets/lang/en/HomePage/ProductSection/en.json';
import am from '../../../assets/lang/am/HomePage/ProductSection/en.json';
import ru from '../../../assets/lang/ru/HomePage/ProductSection/en.json';

import deliveryIcon from "../../../assets/icons/ProductPage/CommonIcons/deliveryIcon.png"


import buyItNowIcon from "../../../assets/icons/homePage/commonIcons/buyItNowIcon.svg";

import {useEffect} from "react";
import useAddToCart from "../../../hooks/product/cart/useAddToCart";


import useUpdateCartItem from "../../../hooks/product/cart/useUpdateCartItem";
import useRemoveCartItem from "../../../hooks/product/cart/useRemoveCartItem";
import {useNavigate} from "react-router-dom";
import minusIcon from "../../../assets/icons/homePage/commonIcons/minusIcon.svg";
import plusIcon from "../../../assets/icons/homePage/commonIcons/plusIcon.svg";
import cookedRemoveCartIcon from "../../../assets/icons/homePage/commonIcons/removeCartIconCooked.png";
import cookedAddCartIcon from "../../../assets/icons/homePage/commonIcons/cookedAddCartIcon.webp";
import {gql, useQuery} from "@apollo/client";
import ProductDescription from "../../molecul/ProductPage/ProductDescrpition";

const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
        saleType
    }
  }
`;

const ProductPagePriceCalculator = ({
                                        description,
                                        defaultPrice,
                                        id,
                                        productImages,
                                        productTitle,
                                        openPopup,
                                        state,
                                        saleType
                                    }) => {
    const changePrice = saleType === "kg" ? defaultPrice / 2 : defaultPrice;
    const changeQuantity =  saleType === "kg" ? 0.5 : 1;
    const [cartItemId, setCartItemId] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(defaultPrice);
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const lang = useSelector(state => state.globalLang.lang);

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const {loading, data} = useQuery(GET_CART_ITEMS, {
        variables: {userId: user._id}
    });

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);


    const findCartElement = () => {
        if (!loading && data.userCartProducts && data.userCartProducts.length >= 1) {
            const elements = data.userCartProducts ? data.userCartProducts : [];
            if (id && elements && elements.length >= 1) {
                return elements.find(item => item.productId === id && item.state === state);
            }
        }
    }
    const [isAdded, setIsAdded] = useState(isAuthenticated && !!findCartElement());

    const {addItemToCart, addItemError, addItemLoading} = useAddToCart();
    const {updateCartItem, updateCartError, updateCartLoading} = useUpdateCartItem();
    const {removeCartItem, removeItemError, removeItemLoading} = useRemoveCartItem();

    const resetValues = () => {
        setIsAdded(false);
        setQuantity(1);
        setPrice(defaultPrice);
    }
    useEffect(() => {
        if (!loading && isAuthenticated && data.userCartProducts) {
            setIsAdded(!!findCartElement());
            const elements = data.userCartProducts ? data.userCartProducts : [];
            if (id && elements && elements.length >= 1) {
                const item = elements.find(item => item.productId === id && item.state === state);
                if (item) {
                    setCartItemId(item._id);
                    setQuantity(item.quantity);
                    setPrice(item.price);
                } else {
                    resetValues();
                }
            } else {
                resetValues();
            }
        } else {
            resetValues();
        }
    }, [data, id, loading, state, isAdded]);

    useEffect(() => {
        if (!isAuthenticated) {
            resetValues();
        }
    }, [isAuthenticated, defaultPrice]);


    const handleChangeQuantity = async (type) => {
        if (!isButtonDisabled && type === "+") {
            if (!loading) {
                if (!isButtonDisabled
                    && !addItemError
                    && !addItemLoading
                    && !updateCartLoading
                    && !updateCartError
                    && !removeItemLoading
                    && !removeItemError
                ) {

                    if (isAdded && quantity) {
                        setIsButtonDisabled(true);
                        await updateCartItem({
                            variables: {
                                updateCartInput: {
                                    cartItemId: cartItemId,
                                    price: price + changePrice,
                                    quantity: quantity + changeQuantity,
                                    state: state,
                                }
                            },

                        });
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                        setTimeout(() => {
                            setIsButtonDisabled(false);
                        }, 150)
                    } else {
                        setQuantity(quantity + changeQuantity);
                        setPrice(price + changePrice);
                    }

                }
            }

        } else {
            if (!isButtonDisabled && quantity > changeQuantity) {
                if (quantity !== 1 && !loading) {
                    if (!isButtonDisabled
                        && !updateCartLoading
                        && !updateCartError
                        && !addItemError
                        && !addItemLoading
                        && !removeItemLoading
                        && !removeItemError
                    ) {
                        if (isAdded && quantity) {
                            setIsButtonDisabled(true);
                            await updateCartItem({
                                variables: {
                                    updateCartInput: {
                                        cartItemId: cartItemId,
                                        price: quantity === 1 ? price : price - changePrice,
                                        quantity: quantity === 1 ? quantity : quantity - changeQuantity,
                                        state: state,
                                    }
                                },
                            });
                            setQuantity(quantity - changeQuantity);
                            setPrice(price - changePrice);
                            setTimeout(() => {
                                setIsButtonDisabled(false);
                            }, 150);
                        } else {
                            setQuantity(quantity === 1 ? quantity : quantity - changeQuantity,);
                            setPrice(quantity === 1 ? price : price - changePrice);
                        }
                    }
                }

            }
        }

    };

    return (
        <div className={styles.calculator_section}>
            <div className={styles.calculator_div}>
                <div className={styles.quantityDiv} onKeyDown={(event) => {
                    event.stopPropagation();
                }}>
                    <button className={styles.btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("-");
                    }}>
                            <span>
                               <img src={minusIcon} alt={"-"}/>
                            </span>
                    </button>
                    <div
                        className={styles.quantity}>{quantity} {changeQuantity !== 1 ?  langObj.kg : langObj.piece}
                    </div>
                    <button className={styles.btn} onKeyDown={(event) => {
                        event.stopPropagation();
                    }} onClick={(e) => {
                        e.preventDefault();
                        handleChangeQuantity("+");
                    }}>
                            <span>
                                 <img src={plusIcon} alt={"+"}/>
                            </span>
                    </button>

                </div>
                <h4 className={styles.price}>
                    {price} <span>֏</span>
                </h4>

                <span className={styles.delivery_price}>
                    <img src={deliveryIcon} alt={'delivery'} className={styles.delivery_icon}/>
                    {price >= 30000 ?
                        langObj.free
                        :
                        1000 + " ֏"
                    }
                </span>
            </div>
            {isMobile &&
                <div className={styles.product_img_div_mobile}>
                    <img src={productImages} alt={"product Img"} className={styles.product_img_mobile}/>
                </div>
            }

            <ProductDescription
                isMobile={isMobile}
                description={description}
            />
            {
                isMobile
                    ?
                    <div className={styles.btn_flex_div_mobile}>
                        {isAdded ?
                            <button
                                className={styles.remove_item_btn_mobile}
                                onClick={() => {
                                    if (
                                        !isButtonDisabled
                                        && !addItemLoading
                                        && !addItemLoading
                                        && !updateCartLoading
                                        && !updateCartError
                                        && !removeItemLoading
                                        && !removeItemError
                                    ) {
                                        removeCartItem({
                                            variables: {
                                                removeCartItemInput: {
                                                    cartItemId: cartItemId,
                                                },
                                            },
                                        });
                                    }
                                }}
                            >
                                <img src={cookedRemoveCartIcon} alt="icon"/>
                                <span>{langObj.removeFromCart}</span>
                            </button>
                            :
                            <button
                                className={styles.add_btn_mobile}
                                onClick={async () => {
                                    if (isAuthenticated) {
                                        if (
                                            !isButtonDisabled
                                            && !addItemLoading
                                            && !addItemLoading
                                            && !updateCartLoading
                                            && !updateCartError
                                            && !removeItemLoading
                                            && !removeItemError
                                        ) {
                                            setIsButtonDisabled(true);
                                            await addItemToCart({
                                                variables: {
                                                    addToCartInput: {
                                                        userId: user._id,
                                                        productId: id,
                                                        price: price,
                                                        quantity: quantity,
                                                        state: state,
                                                        defaultPrice: defaultPrice,
                                                        productImages: productImages,
                                                        productTitle: productTitle,
                                                        saleType: saleType
                                                    },
                                                },
                                            });
                                        }
                                    } else {
                                        openPopup();
                                    }
                                    setTimeout(() => {
                                        setIsButtonDisabled(false);
                                    }, 300);
                                }}
                            >
                                <span>{langObj.addBtn}</span>
                                <img src={cookedAddCartIcon} alt="icon"/>
                            </button>
                        }
                        <button className={styles.buy_now_btn_mobile} onClick={() => {
                            navigate("/checkout", {
                                state: [{
                                    state: state,
                                    productTitle: productTitle,
                                    productId: id,
                                    quantity: quantity,
                                    productImages: productImages,
                                    defaultPrice: defaultPrice,
                                    price: price
                                }]
                            })
                        }}>
                            <span>{langObj.buyItNowBtn}</span>
                            <img src={buyItNowIcon} alt="icon"/>
                        </button>
                    </div>
                    :
                    <div className={styles.btn_flex_div}>
                        {isAdded ?
                            <button
                                className={styles.remove_item_btn}
                                onClick={() => {
                                    if (
                                        !isButtonDisabled
                                        && !addItemLoading
                                        && !addItemLoading
                                        && !updateCartLoading
                                        && !updateCartError
                                        && !removeItemLoading
                                        && !removeItemError
                                    ) {
                                        removeCartItem({
                                            variables: {
                                                removeCartItemInput: {
                                                    cartItemId: cartItemId,
                                                },
                                            },
                                        });
                                    }
                                }}
                            >
                                <img src={cookedRemoveCartIcon} alt="icon"/>
                                <span>{langObj.removeFromCart}</span>
                            </button>
                            :
                            <button
                                className={styles.add_btn}
                                onClick={async () => {
                                    if (isAuthenticated) {
                                        if (
                                            !isButtonDisabled
                                            && !addItemLoading
                                            && !addItemLoading
                                            && !updateCartLoading
                                            && !updateCartError
                                            && !removeItemLoading
                                            && !removeItemError
                                        ) {
                                            setIsButtonDisabled(true);
                                            await addItemToCart({
                                                variables: {
                                                    addToCartInput: {
                                                        userId: user._id,
                                                        productId: id,
                                                        price: price,
                                                        quantity: quantity,
                                                        state: state,
                                                        defaultPrice: defaultPrice,
                                                        productImages: productImages,
                                                        productTitle: productTitle,
                                                        saleType: saleType
                                                    },
                                                },
                                            });
                                        }
                                    } else {
                                        openPopup();
                                    }
                                    setTimeout(() => {
                                        setIsButtonDisabled(false);
                                    }, 300);
                                }}
                            >
                                <span>{langObj.addBtn}</span>
                                <img src={cookedAddCartIcon} alt="icon"/>
                            </button>
                        }
                        <button className={styles.buy_now_btn} onClick={() => {
                            navigate("/checkout", {
                                state: [{
                                    state: state,
                                    productTitle: productTitle,
                                    productId: id,
                                    quantity: quantity,
                                    productImages: productImages,
                                    defaultPrice: defaultPrice,
                                    price: price
                                }]
                            })
                        }}>
                            <span>{langObj.buyItNowBtn}</span>
                            <img src={buyItNowIcon} alt="icon"/>
                        </button>
                    </div>
            }
        </div>

    );
};

export default ProductPagePriceCalculator;