import styles from './style.module.scss';

import React, {useEffect, useState} from "react";
import closeIcon from "../../../../../assets/icons/catalogPage/sideBarClose.png";

import en from "../../../../../assets/lang/en/Categories/en.json";
import am from "../../../../../assets/lang/am/Categories/en.json";
import ru from "../../../../../assets/lang/ru/Categories/en.json";

import OptionalDropdown from "../../../OptionalDropdownField";
import {useSelector} from "react-redux";

const MobileCatalogSideBar = ({closeSidebar, isOpen, changeState, value, lang}) => {
    const checkArray = {
        ...value,
        searchOptions: '',
        specialOffers: false,
        categories: [],
    }

    const compareTwoObject = (obj1, obj2) => {
        const objString1 = JSON.stringify(obj1);
        const objString2 = JSON.stringify(obj2);

        return objString1 === objString2;
    }
    const backBtnFunc = (event) => {
        event.preventDefault();
        document.body.style.overflow = "auto"; // re-enable scrolling on the page
        document.body.style.height = "auto";
        document.body.style.top = "0";
        document.body.style.position = "unset";
        closeSidebar();
    }

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('popstate', backBtnFunc);
            document.body.style.overflow = "hidden"; // disable scrolling on the page
            document.body.style.height = "100vh";
            document.body.style.top = "0";
            document.body.style.position = "fixed";

        } else {
            document.body.style.overflow = "auto"; // re-enable scrolling on the page
            document.body.style.height = "auto";
            document.body.style.top = "0";
            document.body.style.position = "unset";
            window.removeEventListener('popstate', backBtnFunc)
        }
    }, [isOpen]);

    const stateLang = useSelector(state => state.globalLang.lang);

    const [categoriesObj, setCategoriesObj] = useState(en);
    useEffect(() => {
        if (stateLang === "ARM") {
            setCategoriesObj(am);
        } else if (stateLang === "ENG") {
            setCategoriesObj(en);
        } else {
            setCategoriesObj(ru);
        }
    }, [stateLang]);

    return (
        <div className={isOpen ? styles.sidebar_open : styles.sidebar}>
            <div className={styles.close_sidebar_btn_div}>
                <button onClick={closeSidebar} className={styles.close_sidebar_btn}>
                    <img src={closeIcon} alt={"closeIcon"}/>
                </button>
            </div>
            <div className={styles.sidebar_navMenu}>
                <div className={styles.sidebar_heading}>
                    <span>{lang.sidebarHeading}</span>
                </div>

            </div>
            <div className={styles.sidebar_inner_div}>
                <OptionalDropdown
                    options={categoriesObj[0].subcategories}
                    label={categoriesObj[0].name}
                    value={value}
                    changeState={changeState}
                />
                <OptionalDropdown
                    options={categoriesObj[1].subcategories}
                    label={categoriesObj[1].name}
                    value={value}
                    changeState={changeState}
                />
                <OptionalDropdown
                    options={categoriesObj[2].subcategories}
                    label={categoriesObj[2].name}
                    value={value}
                    changeState={changeState}
                />
                <OptionalDropdown
                    options={categoriesObj[3].subcategories}
                    label={categoriesObj[3].name}
                    value={value}
                    changeState={changeState}
                />
            </div>
            {
                !compareTwoObject(checkArray, value) &&
                <button className={styles.clear_filters_btn} onClick={() => {
                    changeState({
                        ...value,
                        searchOptions: '',
                        specialOffers: false,
                        categories: [],
                    })
                }}>
                    <img src={closeIcon} alt={"closeIcon"}/>
                    {lang.clearAllFilters}
                </button>
            }
        </div>
    )
}

export default MobileCatalogSideBar;