import {createSlice} from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";



const userSlice = createSlice({
        name: 'auth',
        initialState: {
            isAuthenticated: !!localStorage.getItem('token'),
            user: !!localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')).user : null,
        },
        reducers: {
            loginSuccess(state, action) {
                state.isAuthenticated = true;
                state.user = jwt_decode(action.payload).user;
            },
            logoutSuccess(state) {
                state.isAuthenticated = false;
                state.user = null;
                localStorage.removeItem('token');
            },
        },
    })
;

export const {loginSuccess, logoutSuccess} = userSlice.actions;

export default userSlice.reducer;