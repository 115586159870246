import styles from "./style.module.scss";

import prevIcon from '../../../../../assets/icons/homePage/commonIcons/prevIcon.png';
import nextIcon from '../../../../../assets/icons/homePage/commonIcons/nextIcon.png';

import {useEffect, useState} from "react";


import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {useSelector} from "react-redux";
import en from "../../../../../assets/lang/en/HomePage/PartChoosingSection/en.json";
import am from "../../../../../assets/lang/am/HomePage/PartChoosingSection/en.json";
import ru from "../../../../../assets/lang/ru/HomePage/PartChoosingSection/en.json";
import {BeefSvg,PorkSvg,LambSvg, ChickenSvg} from "../../../../atom/Animals";
import categories from "../../../../../assets/lang/en/Categories/en.json";


const PartChoosingSectionMobile = ({changeState, value, scrollPage, activeIndex}) => {
    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(lang === "ARM" ? am : lang === "ENG" ? en : ru);
    const [swiper, setSwiper] = useState({});
    const [sectionHeading, setSectionHeading] = useState(swiper && swiper.realIndex ? swiper.realIndex : langObj.beefHeading);


    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
            changeHeading(swiper.realIndex, am, false);
        } else if (lang === "ENG") {
            setLangObj(en);
            changeHeading(swiper.realIndex, en, false);
        } else {
            setLangObj(ru);
            changeHeading(swiper.realIndex, ru, false);
        }

    }, [lang]);
    const changeCategory = (input) => {
        const category = categories.find(item => item.name === input);
        const subcategories = category.subcategories.map(item => item.value);

        changeState({
            ...value,
            searchOptions: '',
            categories: subcategories,
        });
    };



    useEffect(() => {
        if(activeIndex && Object.keys(swiper).length > 0){
            swiper.slideTo(activeIndex, 0);
        }
    }, [swiper, activeIndex])
    const changeHeading = (index, langPack, changeStateBool) => {
        switch (index) {
            case 0:
                changeStateBool && changeCategory("Beef")
                return setSectionHeading(langPack.beefHeading);

            case 1:
                changeStateBool && changeCategory("Pork")
                return setSectionHeading(langPack.porkHeading);
            case 2:
                changeStateBool && changeCategory("Lamb")
                return setSectionHeading(langPack.lambHeading);
            case 3:
                changeStateBool && changeCategory("Chicken")
                return setSectionHeading(langPack.chickenHeading);
            default:
                break

        }
    }


    return (
        <div className={styles.part_choosing_section}>
                <Swiper
                    onSwiper={(swiper) => {
                        setSwiper(swiper);
                    }}
                    slidesPerView={1}
                    spaceBetween={1}
                    slidesPerGroup={1}
                    loop={true}

                    modules={[Autoplay]}

                    className={styles.mySwiper}

                    onSlideChange={(swiper) => {
                        changeHeading(swiper.realIndex, langObj, true)
                    }}
                >
                    <SwiperSlide>
                        <div className={styles.sliderDiv}>
                            <BeefSvg
                                scrollPage={scrollPage}
                                value={value}
                                changeState={changeState}
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiper_slide}>
                        <div className={styles.sliderDiv}>
                             <PorkSvg
                                 scrollPage={scrollPage}
                                 value={value}
                                 changeState={changeState}
                             />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiper_slide}>
                        <div className={styles.sliderDiv}>
                                <LambSvg
                                    scrollPage={scrollPage}
                                    value={value}
                                    changeState={changeState}
                                />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiper_slide}>
                        <div className={styles.sliderDiv}>
                            <ChickenSvg
                                scrollPage={scrollPage}
                                value={value}
                                changeState={changeState}
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h1>
                        {sectionHeading}
                    </h1>
                </div>
            </div>
            <div className={styles.swiper_btn_div}>
                <button className={styles.swiper_nav_btn} onClick={() => {
                    swiper.slidePrev();
                }}>
                    <img src={prevIcon} alt="prev"/>
                </button>
                <button className={styles.swiper_nav_btn} onClick={() => {
                    swiper.slideNext();
                }}>
                    <img src={nextIcon} alt="next"/>
                </button>
            </div>
        </div>
    )
};

export default PartChoosingSectionMobile;



