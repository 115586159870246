import styles from "./style.module.scss";
import warningIcon from "../../../assets/icons/NotificationBar/warning.png";
import successIcon from "../../../assets/icons/NotificationBar/success.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {closeBar} from "../../../redux/features/popup/notifyMessage";

const CustomNotificationBar = () => {
    const dispatch = useDispatch();
    const notifyConf = useSelector(state => state.globalNotification.notify);

    useEffect(() => {
        setTimeout(() => {
            dispatch(closeBar())
        }, 2000)
    }, [])

    return (
        <div className={styles.main_fixed_div}>
            {notifyConf.type === 'success'
                ?
                <div className={styles.notification_bar + " " + styles.success}>
                    <img src={successIcon} alt={"icon"}/>
                    <span>
                        {notifyConf.message}
                    </span>
                </div>
                :
                <div className={styles.notification_bar + " " + styles.warning}>
                    <img src={warningIcon} alt={"icon"}/>
                    <span>
                        {notifyConf.message}
                    </span>
                </div>
            }

        </div>
    );
};

export default CustomNotificationBar;