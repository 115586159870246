import styles from "./style.module.scss";
import backIcon from "../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/Login/en.json";
import am from "../../../assets/lang/am/Login/en.json";
import ru from "../../../assets/lang/ru/Login/en.json";
import {useDispatch, useSelector} from "react-redux";
import CustomLoginInput from "../../atom/CustomLoginInput";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

const RECOVER_PASSWORD = gql`
    mutation Mutation(
        $recoverPassInput: RecoverPassInput
    ) {
        recoverPass(
            recoverPassInput: $recoverPassInput
        ) {
            message
        }
    }
`;

const ResetPasswordSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);

    const [changeData, setChangeData] = useState({
        newPassword: '',
        confirmPassword: '',
    })
    const id = useParams().emailId;

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    const [recoverPassword, {loading}] = useMutation(RECOVER_PASSWORD, {
        update(proxy) {
            dispatch(notifyUser({
                barOpen: true,
                type: "success",
                message: lang === "ARM"
                    ? "Ձեր գաղտնաբառը փոխված է"
                    : lang === "RU"
                        ? "Ваш пароль был изменен"
                        : "Your password has been changed",
            }));
            navigate("/");
        },
        onError({graphQLErrors}) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {
            recoverPassInput: {
                emailId: id,
                newPassword: changeData.newPassword,
            }
        }
    });

    const onSubmit = async () => {
        const containsFalsy = Object.values(changeData).some(item => !item || (Array.isArray(item) && item.length === 0));
        if (containsFalsy) {
            dispatch(notifyUser({
                barOpen: true,
                message: langObj.emptyFieldWarning,
            }));
        } else {
            if (changeData.confirmPassword === changeData.newPassword) {
                if (!loading) await recoverPassword();
            } else {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.unlikePassword,
                }));
            }
        }
    }
    return (
        <div className={styles.signin_section}>
            <div className={styles.login_section}>
                {
                    isMobile &&
                    <div className={styles.back_btn_div_mobile}>
                        <div
                            className={styles.back_btn }
                            onClick={() => {
                                navigate("/")
                            }}
                        >
                            <img src={backIcon} alt={"icon"}/>
                            <span>{langObj.backBtn}</span>
                        </div>
                    </div>
                }
                {
                    isMobile &&
                    <h1 className={styles.heading_mobile_ru + " " + styles.verification_heading_size}
                        style={{whiteSpace: 'break-spaces'}}>
                        {langObj.forgotHeading}
                    </h1>
                }
                {
                    !isMobile &&
                    <div className={styles.heading_div}>
                        <button className={styles.back_btn} onClick={() => {
                            navigate("/");
                        }}>
                            <img src={backIcon} alt={"icon"}/>
                            <span>{langObj.backBtn}</span>
                        </button>
                        <h1 className={styles.heading}>
                            {langObj.forgotHeading}
                        </h1>
                    </div>
                }


                <div className={styles.input_div}>
                    <label htmlFor="name" className={styles.label}>
                        {langObj.newPasswordLabel}
                    </label>
                    <CustomLoginInput
                        type="password"
                        name="password"
                        onChange={(event) => {
                            setChangeData({
                                ...changeData,
                                newPassword: event.target.value,
                            })
                        }}
                        customInputHeight={"50px"}
                        maxLength={"12"}
                    />
                </div>
                <div className={styles.input_div}>
                    <label htmlFor="pass" className={styles.label}>
                        {langObj.confirmPassLabel}
                    </label>
                    <CustomLoginInput
                        type="password"
                        name="confirmPassword"
                        customInputHeight={"50px"}
                        maxLength={"12"}
                        onChange={(event) => {
                            setChangeData({
                                ...changeData,
                                confirmPassword: event.target.value,
                            })
                        }}
                    />
                </div>
                <button
                    className={styles.login_popup_btn}
                    onClick={onSubmit}
                >
                    {langObj.changePassBtn}
                </button>
            </div>
        </div>
    );
};

export default ResetPasswordSection;