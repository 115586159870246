import styles from "../../style.module.scss"

import CustomLoginInput from "../../../../atom/CustomLoginInput";

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../../../redux/features/popup/notifyMessage";
import backIcon from "../../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";
import {useMutation} from "@apollo/react-hooks";

import {gql} from "graphql-tag";

const CHECK_USER = gql`
    mutation Mutation(
        $checkInput: CheckInput
    ) {
        checkUser(
            checkInput: $checkInput
        ) {
            message
        }
    }
`;
const RegistrationSection = ({lang, changeSection, changeHandler, values, setInputValue, asyncSendFunction}) => {
    const language = useSelector(state => state.globalLang.lang);
    const [confirmPassword, SetConfirmPassword] = useState('');
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const [numberInputValue, setNumberInputValue] = useState();
    const dispatch = useDispatch();


    const [checkUser, {loading}] = useMutation(CHECK_USER, {
        update (proxy) {
            asyncSendFunction();
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: language === "ARM" ? message.am : language === "RU" ? message.ru : message.en,
                }));
            }
        },
    });

    const changeNumber = (telNumber) => {
        if (telNumber.length === 9 && telNumber.slice(0, 1) === "0") {
            return "+374" + telNumber.slice(1);
        } else if (telNumber.length === 8 && telNumber.slice(0, 1) !== "0" && telNumber.slice(0, 4) !== "+374" && telNumber.slice(0, 2) !== "00") {
            return "+374" + telNumber;
        } else if (telNumber.length === 11 && telNumber.slice(0, 3) === "374") {
            return "+" + telNumber;
        } else if (telNumber.length === 12 && telNumber.slice(0, 4) === "+374") {
            return telNumber;
        } else if (telNumber.length === 13 && telNumber.slice(0, 5) === "00374") {
            return "+" + telNumber.slice(2);
        }
        return telNumber;
    }
    const handleSubmit = async () => {
        const checkArray = {...values};
        delete checkArray['otpCode'];
        const containsFalsy = Object.values(checkArray).some(item => !item || (Array.isArray(item) && item.length === 0));
        const telNumberString = changeNumber(values.tel.toString());

        const isValidEmail = (email) => {
            return /\S+@\S+\.\S+/.test(email);
        }

        if (containsFalsy) {
            dispatch(notifyUser({
                barOpen: true,
                message: lang.emptyFieldWarning,
            }));
        } else {
            if (!isValidEmail(values.email)) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang.wrongEmailWarning,
                }));
            } else if (telNumberString.length !== 12 || telNumberString.slice(0, 4) !== "+374") {
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang.wrongTelWarning,
                }));
            } else {
                if (!confirmPassword && !values.password) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: lang.emptyFieldWarning,
                    }));
                } else {
                    if (confirmPassword === values.password && !loading) {
                        setInputValue("tel", telNumberString);
                        await checkUser({
                            variables: {
                                checkInput: {
                                    email: values.email,
                                    tel: telNumberString,
                                    password: values.password,
                                },
                            }
                        });
                    } else {
                        dispatch(notifyUser({
                            barOpen: true,
                            message: lang.unlikePassword,
                        }));
                    }
                }
            }
        }
    };




    if (isMobile) {
        return (
            <form className={styles.main_section_div_mobile} onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <div className={styles.login_section_mobile}>
                    <div className={styles.back_btn_div_mobile}>
                        <button
                            className={styles.back_btn + " " + styles.position_relative + " " + styles.no_margin}
                            onClick={() => {
                                changeSection(1);
                            }}
                        >
                            <img src={backIcon} alt={"icon"}/>
                            <span>{lang.backBtn}</span>
                        </button>
                    </div>
                    <h1 className={language === "RU" ? styles.heading_mobile_ru : styles.heading_mobile}>
                        {lang.signUpBtn}
                    </h1>
                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label_mobile}>
                            {lang.nameLabel}
                        </label>
                        <CustomLoginInput
                            type="text"
                            name="fullName"
                            value={values.fullName}
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={(event) => {
                                const input = event.target.value;
                                setInputValue("fullName", input.replace(/[^A-Za-zА-Яа-яԱ-Ֆա-ֆ\s]/g, ''));
                            }}
                            customInputHeight={"50px"}  maxLength={"50"}/>
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.email_input}>
                        <label htmlFor="email" className={styles.label_mobile}>
                            {lang.emailLabel}
                        </label>
                        <CustomLoginInput type="text" name="email"
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={changeHandler} customInputHeight={"50px"} maxLength={50}/>
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.tel_input}>
                        <label htmlFor="tel" className={styles.label_mobile}>
                            {lang.phoneLabel}
                        </label>
                        <CustomLoginInput
                            className={styles.loginInput + " " + styles.registration_input}
                            customInputHeight={"50px"}
                            type="text"
                            name="tel"
                            inputMode={"numeric"}
                            maxlength="13"
                            placeholder={"+374XXXXXXXX"}
                            value={numberInputValue}
                            onChange={(event) => {
                                const input = event.target.value;
                                setNumberInputValue(input.replace(/[^0-9+]/g, ''));
                                setInputValue("tel", input.replace(/[^0-9+]/g, ''));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === '.' ) e.preventDefault();
                                else if (
                                    e.target.value.length > 12
                                    && e.key !== 'Backspace'
                                    && e.key !== 'Control'
                                    && e.key !== 'a'
                                    && e.key !== 'ArrowLeft'
                                    && e.key !== 'ArrowRight'
                                ) e.preventDefault();
                            }}
                        />
                    </div>
                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label_mobile}>
                            {lang.passwordLabel}
                        </label>
                        <CustomLoginInput
                            type="password"
                            name="password"
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            maxLength={"25"}
                        />
                    </div>
                    <div className={styles.inputdiv}>
                        <label htmlFor="pass" className={styles.label_mobile}>
                            {lang.confirmPassLabel}
                        </label>
                        <CustomLoginInput
                            type="password"
                            name="confirmPassword"
                            customInputHeight={"50px"}
                            maxLength={"25"}
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={(event) => {
                                SetConfirmPassword(event.target.value);
                            }}
                        />
                    </div>
                    {
                        language === "RU"
                            ?
                            <button
                                className={styles.login_popup_btn_mobile}
                                onClick={() => {
                                    handleSubmit()
                                }}>
                                {lang.signUpBtn}
                            </button>
                            :
                            <button
                                className={styles.login_btn}
                            >
                                <div className={styles.plate_on_flour_mobile}>
                                    <div className={styles.flour_img_mobile}/>
                                    <div
                                        className={styles.plate_img_mobile}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    />
                                    <div className={styles.login_text_mobile} onClick={() => {
                                        handleSubmit()
                                    }}>
                                        {lang.signUpBtn}
                                    </div>
                                </div>
                            </button>
                    }
                    <div className={styles.register_btn_mobile}>
                    <span>
                       {lang.navigateSignIn.regular}
                    </span>
                        <button onClick={() => {
                            changeSection(1);
                        }}>
                            {lang.navigateSignIn.red}
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    return (
        <form className={styles.main_section_div} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}
              onKeyDown={(e) => {
                  if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                  }
              }}
        >
            <div className={styles.login_section}>
                <div className={styles.heading_div}>
                    <button className={styles.back_btn} onClick={() => {
                        changeSection(1);
                    }}>
                        <img src={backIcon} alt={"icon"}/>
                        <span>{lang.backBtn}</span>
                    </button>
                    <h1 className={styles.heading}>
                        {lang.signUpHeading}
                    </h1>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="name" className={styles.label}>
                        {lang.nameLabel}
                    </label>
                    <CustomLoginInput
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        className={styles.loginInput + " " + styles.registration_input}
                        onChange={(event) => {
                            const input = event.target.value;
                            setInputValue("fullName", input.replace(/[^A-Za-zА-Яа-яԱ-Ֆա-ֆ\s]/g, ''));
                        }}
                        customInputHeight={"50px"}
                        maxLength={"50"}/>
                </div>
                <div className={styles.row_input_div}>
                    <div className={styles.inputdiv + ' ' + styles.email_input}>
                        <label htmlFor="email" className={styles.label}>
                            {lang.emailLabel}
                        </label>
                        <CustomLoginInput
                            type="text"
                            name="email"
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            maxLength={50}
                        />
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.tel_input}>
                        <label htmlFor="tel" className={styles.label}>
                            {lang.phoneLabel}
                        </label>
                        <CustomLoginInput
                            className={styles.loginInput + " " + styles.registration_input}
                            customInputHeight={"50px"}
                            type="text"
                            name="tel"
                            inputMode={'numeric'}
                            placeholder={"+374XXXXXXXX"}
                            maxlength="13"
                            value={numberInputValue}
                            onChange={(event) => {
                                const input = event.target.value;
                                setNumberInputValue(input.replace(/[^0-9+]/g, ''));
                                setInputValue("tel", input.replace(/[^0-9+]/g, ''));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === '.' ) e.preventDefault();
                                else if (
                                    e.target.value.length > 12
                                    && e.key !== 'Backspace'
                                    && e.key !== 'Control'
                                    && e.key !== 'a'
                                    && e.key !== 'ArrowLeft'
                                    && e.key !== 'ArrowRight'
                                ) e.preventDefault();
                            }}
                        />
                    </div>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="pass" className={styles.label}>
                        {lang.passwordLabel}
                    </label>
                    <CustomLoginInput
                        type="password"
                        name="password"
                        maxLength={"25"}
                        className={styles.loginInput + " " + styles.registration_input}
                        onChange={changeHandler} customInputHeight={"50px"}
                    />
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="pass" className={styles.label}>
                        {lang.confirmPassLabel}
                    </label>
                    <CustomLoginInput
                        type="password"
                        name="confirmPassword"
                        maxLength={"25"}
                        customInputHeight={"50px"}
                        className={styles.loginInput + " " + styles.registration_input}
                        onChange={(event) => {
                            SetConfirmPassword(event.target.value);
                        }}/>
                </div>
                {
                    language === "RU"
                        ?
                        <button
                            className={styles.login_popup_btn}
                            onClick={() => {
                                handleSubmit()
                            }}>
                            {lang.signUpBtn}
                        </button>
                        :
                        <button
                            className={styles.login_btn}

                        >
                            <div className={styles.plate_on_flour}>
                                <div className={styles.flour_img}/>
                                <div
                                    className={styles.plate_img}
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className={styles.login_text} onClick={() => {
                                    handleSubmit()
                                }}>
                                    {lang.signUpBtn}
                                </div>
                            </div>
                        </button>
                }

                <div className={styles.register_btn}>
                    <span>
                       {lang.navigateSignIn.regular}
                    </span>
                    <button onClick={() => {
                        changeSection(1);
                    }}>
                        {lang.navigateSignIn.red}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default RegistrationSection;
