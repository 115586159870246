import {useDispatch, useSelector} from "react-redux";
import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import en from "../../../../assets/lang/en/Login/en.json";
import am from "../../../../assets/lang/am/Login/en.json";
import ru from "../../../../assets/lang/ru/Login/en.json";
import {VerificationSection} from "../../LoginPage";
import {useMutation} from "@apollo/client";
import {gql} from "graphql-tag";
import {notifyUser} from "../../../../redux/features/popup/notifyMessage";

const VERIFY_USER = gql`
    mutation Mutation(
        $verifyInput: VerifyInput
    ) {
        verifyUser(
            verifyInput: $verifyInput
        ) {
            message
        }
    }
`;
const VerificationPopUp = ({closePopUp, tel, callback, asyncSendFunction}) => {
    const dispatch = useDispatch();

    const [otpCode, setOtpCode] = useState('');

    useEffect(() => {
        document.body.style.overflow = "hidden"; // disable scrolling on the page
        const enableScroll = () => {
            document.body.style.overflow = "auto";
        }
        return enableScroll;// re-enable scrolling on the page

    }, []);

    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);


    const [verifyUser, {loading}] = useMutation(VERIFY_USER, {
        update(proxy) {
            callback();
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {
            verifyInput: {
                tel: tel,
                otpCode: otpCode,
            }
        }
    });

    return (
        <div className={styles.popupContainer} onClick={closePopUp}>
            <div className={styles.popupBody} onClick={(e) => {
                e.stopPropagation()
            }}>
                <VerificationSection
                    closePopup={closePopUp}
                    lang={langObj}
                    closePopUp={closePopUp}
                    callback={verifyUser}
                    loading={loading}
                    setOtpCode={(e) => {
                        setOtpCode(e);
                    }}
                    otpCode={otpCode}
                    asyncSendFunction={asyncSendFunction}
                />
            </div>
        </div>
    )
}

export default VerificationPopUp;