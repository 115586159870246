import CheckoutSection from "../../component/organism/Checkout";
const CheckoutPage = () => {

    return (
        <div>
            <CheckoutSection/>
        </div>
    );
};

export default CheckoutPage;