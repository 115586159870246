import styles from './style.module.scss';

import meatBg from "../../../../assets/backgronds/aboutUs/meatBgMobile.png";
import chili from "../../../../assets/backgronds/aboutUs/chiliMobile.png";

const AboutSectionMobile = ({lang}) => {
    return (
        <div className={styles.aboutUs_main_section}>
            <div className={styles.section_heading}>
                <img src={meatBg} alt={"meat"} className={styles.about_img}/>
                <h2>
                    {lang.sectionHeading}
                </h2>
            </div>
            <div className={styles.paragraph_div}>
                <p>
                    {lang.paragraph}
                </p>
                <img src={chili} alt={"chili"} className={styles.chili_img}/>
            </div>
        </div>
    );
};

export default AboutSectionMobile;