import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from  '@apollo/client/link/error';
import { createStore, combineReducers} from '@reduxjs/toolkit';
import userReducer from './redux/features/user/userSlice';
import recentlyViewedReducer from './redux/features/user/addRecentlyViewedSlice';
import openPopupReducer from "./redux/features/popup/openLoginPopupSlice";
import isMobileReducer from "./redux/features/isMobileSlice";
import changeLangReducer from "./redux/features/lang/changeLangSlice";
import notifyUserReducer from "./redux/features/popup/notifyMessage";

const httpLink = createHttpLink({
  uri: "https://server.meathouse.am/api/graphql",
});
// const httpLink = createHttpLink({
//   uri: "http://localhost:5000/graphql",
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return message;
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({ addTypename: false }),
});

const rootReducer = combineReducers({
  user: userReducer,
  userViewedItems: recentlyViewedReducer,
  isPopupOpen: openPopupReducer,
  screenVersion: isMobileReducer,
  globalLang: changeLangReducer,
  globalNotification: notifyUserReducer
});


const store = createStore( rootReducer );

export { store, client };
