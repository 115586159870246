import styles from "../style.module.scss";
import backIcon from "../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";
import {useDispatch, useSelector} from "react-redux";
import CustomLoginInput from "../../../atom/CustomLoginInput";
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {notifyUser} from "../../../../redux/features/popup/notifyMessage";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const SEND_EMAIL = gql`
    mutation Mutation(
        $emailInput: VerifyEmailInput
    ) {
        sendVerifyEmail(
            emailInput: $emailInput
        ) {
            message
        }
    }
`;

const ForgotPassword = ({
                            changeSection,
                            lang,
                        }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const language = useSelector(state => state.globalLang.lang);
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const navigate = useNavigate();

    const isValidEmail = (mail) => {
        return /\S+@\S+\.\S+/.test(mail);
    }

    const [sendEmail, {loading}] = useMutation(SEND_EMAIL, {
        update(proxy) {
            dispatch(notifyUser({
                barOpen: true,
                type: "success",
                message: language === "ARM"
                    ? "Ձեր նամակը ուղարկված է"
                    : language === "RU"
                        ? "Ваше сообщение отправлено "
                        : "Your message has been sent",
            }));
        },
        onError({graphQLErrors}) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: language === "ARM" ? message.am : language === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {
            emailInput: {
                email: email,
            },
        },
    });

    const onSubmit = async () => {
        if (email.length ===0 || email === "") {
            dispatch(notifyUser({
                barOpen: true,
                message: lang.emptyFieldWarning,
            }));
        } else {
            if (!isValidEmail(email)) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang.wrongEmailWarning,
                }));
            } else {
                if (!loading) {
                    await sendEmail();
                    navigate("/");
                }
            }
        }
    }


    if (isMobile) {
        return (
            <form className={styles.main_section_div_mobile} onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <div className={styles.login_section_mobile}>
                    <div className={styles.back_btn_div_mobile}>
                        <div
                            className={styles.back_btn + " " + styles.position_relative_left + " " + styles.no_margin}
                            onClick={() => {
                                changeSection(1);
                            }}
                        >
                            <img src={backIcon} alt={"icon"}/>
                            <span>{lang.backBtn}</span>
                        </div>

                    </div>
                    <h1 className={styles.heading_mobile_ru + " " + styles.verification_heading_size} style={{whiteSpace: 'break-spaces'}}>
                        {lang.forgotHeading}
                    </h1>

                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label}  style={{whiteSpace: 'break-spaces'}}>
                            {lang.forgotPassLabel}
                        </label>
                        <CustomLoginInput
                            type="email"
                            name="email"
                            customInputHeight={"50px"}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </div>
                    <button
                        className={styles.login_popup_btn}
                        onClick={onSubmit}
                    >
                        {lang.forgotPassBtn}
                    </button>

                    {/*<div className={styles.register_btn_mobile}>*/}
                    {/*<span>*/}
                    {/*   {lang.resendCode.regular}*/}
                    {/*</span>*/}
                    {/*    <button onClick={() => {*/}
                    {/*    }}>*/}
                    {/*        {lang.resendCode.red}*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </form>
        );
    }
    return (
        <form
            className={styles.main_section_div}
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onKeyDown={async (e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                   await onSubmit()
                }
            }}
        >
            <div className={styles.login_section + " " + styles.forgot_pass_gap}>
                <div className={styles.heading_div}>
                    <button className={styles.back_btn} onClick={() => {
                        changeSection(1);
                    }}>
                        <img src={backIcon} alt={"icon"}/>
                        <span>{lang.backBtn}</span>
                    </button>
                    <h1 className={styles.heading}>
                        {lang.forgotHeading}
                    </h1>
                </div>

                <div className={styles.inputdiv}>
                    <label htmlFor="name" className={styles.label}>
                        {lang.forgotPassLabel}
                    </label>
                    <CustomLoginInput
                        type="email"
                        name="email"
                        customInputHeight={"50px"}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                </div>
                <button
                    className={styles.login_popup_btn}
                    onClick={onSubmit}
                >
                    {lang.forgotPassBtn}
                </button>

                {/*<div className={styles.register_btn}>*/}
                {/*    <span>*/}
                {/*       {lang.resendCode.regular}*/}
                {/*    </span>*/}
                {/*    <button onClick={() => {*/}

                {/*    }}>*/}
                {/*        {lang.resendCode.red}*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </form>
    );
};

export default ForgotPassword;