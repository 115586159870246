import {useSelector} from 'react-redux';

import ProductSlider from "../../../../molecul/ProductSlider";
import styles from "../style.module.scss";


import en from "../../../../../assets/lang/en/HomePage/ProductSection/en.json";
import am from "../../../../../assets/lang/am/HomePage/ProductSection/en.json";
import ru from "../../../../../assets/lang/ru/HomePage/ProductSection/en.json";
import {useEffect, useState} from "react";

const RawProductsSection = ({loading, error, data, userCartProducts, userFavoriteProducts}) => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);




    return(
        <div className={styles.main_section_raw}>
            <div className={isMobile ? styles.raw_section_heading_mobile : styles.raw_section_heading}>
                <div>
                    <h2>
                        {langObj.headingRawSection}
                    </h2>
                    <hr/>
                </div>
            </div>
            <ProductSlider
                data={data}
                loading={loading}
                error={error}
                products={data ? data : undefined}
                favorites={userFavoriteProducts ? userFavoriteProducts : undefined}
                cartProducts={userCartProducts ? userCartProducts : undefined}
                sliderHeading={langObj.headingRawSection}
                state={"raw"}

            />
        </div>
    );
};

export default RawProductsSection;