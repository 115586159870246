import styles from "./style.module.scss";

import img1 from "../../../../assets/backgronds/contactUs/img1.webp";
import img2 from "../../../../assets/backgronds/contactUs/img2.png";
import img3 from "../../../../assets/backgronds/contactUs/img3.png";

import callIcon from "../../../../assets/icons/contactUs/Call.svg";
import {useNavigate} from "react-router-dom";
import {useForm} from "../../../../hooks/useForm";
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../../redux/features/popup/notifyMessage";
import {useRef} from "react";


const SEND_EMAIL = gql`
    mutation Mutation(
        $emailInput: EmailInput
    ) {
        sendEmail(
            emailInput: $emailInput
        ) {
            text
        }
    }
`;

const ContactUsSectionDesktop = ({lang}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formRef = useRef();

    const user = useSelector(state => state.user).user;
    const {changeHandler, values} = useForm(null, {
        fullName: user ? user.fullName : '',
        email: user ? user.email : '',
        text: '',
    });

    const isValidEmail = (email) => {
        return  /\S+@\S+\.\S+/.test(email);
    }

    const [sendEmail, {loading}] = useMutation(SEND_EMAIL, {
        update(proxy, { data: { sendEmail: text }}) {
            dispatch(notifyUser({
                barOpen: true,
                message: lang.successMessage,
                type: "success"
            }));
        },
        variables: { emailInput: values}
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        const containsFalsy = Object.values(values).some(item => !item || (Array.isArray(item) && item.length === 0));
        if(isValidEmail(values.email) && !containsFalsy){
            await sendEmail()
            formRef.current.reset();
        }else{
            dispatch(notifyUser({
                barOpen: true,
                message: lang.badInput,
            }));
        }
    }
    return (
        <div className={styles.main_section}>
            <div className={styles.first_section}>
                <div className={styles.section_heading}>
                    <div>
                        <hr/>
                        <span>
                            {lang.sectionHeading}
                        </span>
                    </div>
                </div>
                <form className={styles.inputs_div} ref={formRef} onSubmit={(e)=>{
                    e.preventDefault();
                }}>
                    <div className={styles.input_field_div}>
                        <label>
                            {lang.fullNameLabel}
                        </label>
                        <input type={"text"} name={'fullName'} onChange={changeHandler} defaultValue={user ? user.fullName : ''}/>
                    </div>
                    <div className={styles.input_field_div}>
                        <label>
                            {lang.emailLabel}
                        </label>
                        <input type={"text"} name={'email'} onChange={changeHandler} defaultValue={user ? user.email : ''} className={styles.email}/>
                    </div>
                    <div className={styles.input_field_div}>
                        <label>
                            {lang.textLabel}
                        </label>
                        <textarea  name={'text'} onChange={changeHandler}/>
                    </div>
                    <div className={styles.submit_btn_div}>
                        <button onClick={handleSubmit} disabled={loading}>
                            {lang.sendBtn}
                        </button>
                    </div>
                </form>
                <div className={styles.contacts_div}>
                    <span className={styles.cal_number_heading}>{lang.contacts_heading}</span>
                    <div className={styles.contacts_div_flex}>
                        <div className={styles.call_number_div}>
                            <img src={callIcon} alt={"callIcon"}/>
                            <span>{lang.tel}</span>
                        </div>
                        <span className={styles.viber_div}>{lang.viber}</span>
                    </div>
                </div>
            </div>
            <div className={styles.second_section}>
                <img src={img1} alt={"img1"} className={styles.first_img}/>
                <div className={styles.second_img_section}>
                    <img src={img2} alt={"img2"} className={styles.second_imgs} onClick={() => {
                        navigate('/catalog', {
                            state: {productState: "cooked"},
                        });
                    }}/>
                    <img src={img3} alt={"img3"} className={styles.second_imgs} onClick={() => {
                        navigate('/catalog', {
                            state: {productState: "raw"},
                        })
                    }}/>
                </div>
            </div>
        </div>
    );
};

export default ContactUsSectionDesktop;