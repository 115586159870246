 import React, {useEffect, useState, useRef} from "react";

import {useLocation, useNavigate} from "react-router-dom";

import {useQuery, gql} from "@apollo/client";


import searchIcon from "../../../../assets/icons/layout/navbar/Search.png";

import styles from './style.module.scss';
import {useSelector} from "react-redux";
import am from "../../../../assets/lang/am/layout/Navbar/en.json";
import en from "../../../../assets/lang/en/layout/Navbar/en.json";
import ru from "../../../../assets/lang/ru/layout/Navbar/en.json";


const GET_PRODUCTS_SEARCH_TAGS = gql`
query {
  products{
       searchTags
   }
 }
`;


function InputWIthSearch({value, changeState, navigateSearchOptions}) {
    const direction = useLocation().pathname;
    const searchContainerRef = useRef(null);
    const selectRef = useRef(null);
    const {loading, data} = useQuery(GET_PRODUCTS_SEARCH_TAGS);

    const [inputValue, setInputValue] = useState(navigateSearchOptions ? navigateSearchOptions : '' );
    const [searchOptions, setSearchOptions] = useState([]);
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [menuOptions, setMenuOptions] = useState([]);


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();

    const pathname = useLocation().pathname;

    useEffect(() => {
            setIsMenuOpen(false);
            setInputValue("");
        }, [pathname]);



    useEffect(()=> {
        if(navigateSearchOptions){
            setInputValue(navigateSearchOptions);
        }
    }, [navigateSearchOptions]);

    useEffect(() => {
        if(value && value.searchOptions === ''){
            selectRef.current.value = ""
        }
    }, [value])

    const handleDesktopSearch = () => {
        if (inputValue.length >= 2) {
            document.activeElement.blur();
            if (direction === "/catalog"){
                setIsMenuOpen(false)
                changeState({
                    ...value,
                    categories: [],
                    searchOptions: inputValue,
                })
            }else{
                navigate('/catalog', {
                    state: {
                        searchOptions: inputValue,
                    }
                });
            }
        }else if(direction ==='/catalog' && inputValue.length === 0){
            changeState({
                ...value,
                searchOptions: '',
            })
        }else{
            selectRef.current.focus();
        }
    };
    const handleOptionClick = (clickValue) => {
        setIsMenuOpen(false);
        selectRef.current.value = clickValue;
        if (inputValue.length >= 2) {
            document.activeElement.blur();
            if (direction === "/catalog"){
                changeState({
                    ...value,
                    categories: [],
                    searchOptions: clickValue,
                })
            }else{
                navigate('/catalog', {
                    state: {
                        searchOptions: clickValue,
                    }
                });
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (inputValue.length >= 2) {
                document.activeElement.blur();
                setIsMenuOpen(false)
                if (direction === "/catalog"){
                    changeState({
                        ...value,
                        categories: [],
                        searchOptions: inputValue,
                    })
                }else{
                    navigate('/catalog', {
                        state: {
                            searchOptions: inputValue,
                        }
                    });
                }

            }else if(direction ==='/catalog' && inputValue.length === 0){
                changeState({
                    ...value,
                    searchOptions: '',
                })
            }
        }
    };


    useEffect(() => {
        if (!loading && data) {
            let arr = [];
            const elements = data.products ? data.products : [];
            const dataOptions = elements.map(obj => obj.searchTags);
            dataOptions.map((tagArr) => {
                return tagArr.map((tag) => arr.push(tag));
            });
            const uniqueArr = arr.filter((value, index) => {
                // Returns true if the first index of the value is equal to the current index
                return arr.indexOf(value) === index;
            });

            const newOptions = uniqueArr.map((value) => ({value: value, label: value}));
            setSearchOptions(newOptions);
        }

    }, [loading, data]);


    useEffect(() => {
        if (inputValue.length > 1) {
            const findOptions = searchOptions.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()));
            setMenuOptions(findOptions);
            if (findOptions.length > 0) {
                setIsMenuOpen(true);
            }else{
                setIsMenuOpen(false);
            }
        }else {
            setIsMenuOpen(false);
        }
    }, [inputValue])

    const hasCommonOptions = searchOptions.some((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
    );


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchContainerRef.current &&
                !searchContainerRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchContainerRef]);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);

    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    return (
        <div ref={searchContainerRef} >
            <div className={isMobile ? styles.search_input_div_mobile : styles.search_input_div}>
                <button className={styles.second_section_second_btn} onClick={handleDesktopSearch}>
                    <img width="18" height="18" src={searchIcon} alt="search"/>
                </button>

                <input
                    key={navigateSearchOptions ? 'notLoadedYet' : 'loaded'}
                    placeholder={langObj.search}
                    ref={selectRef}
                    className={styles.input_desktop}
                    type={'text'}
                    onChange={(event) => {
                        setInputValue(event.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                    value={inputValue}
                />
            </div>
            {isMenuOpen &&
                <div
                    className={ isMobile ? styles.dropdown_menu_mobile : styles.dropdown_menu }
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    style={{
                        width: !isMobile ? searchContainerRef.current.offsetWidth : " ",
                    }}
                >
                    {menuOptions.map((item, id) => {
                        return (
                            <div className={styles.search_suggestions} key={id} onClick={() => {
                                handleOptionClick(item.label);
                            }} >
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            }

        </div>
    );
}

export default InputWIthSearch;