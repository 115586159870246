import styles from './style.module.scss';

import {logoutSuccess} from '../../../../redux/features/user/userSlice';


import {useLocation, useNavigate} from 'react-router-dom';




import {useDispatch} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import MobileSideBar from "../../../../component/molecul/Layout/SideBar/Mobile";
import callIcon from "../../../../assets/icons/layout/navbar/Call.png";

import wishIcon from "../../../../assets/icons/layout/navbar/HeartMobile.webp";
import cartIcon from "../../../../assets/icons/layout/navbar/Cart.webp";
import ordersIcon from "../../../../assets/icons/layout/navbar/Orders.webp";
import mobileMenuIcon from "../../../../assets/icons/layout/navbar/mobileMenu.svg";
import profileIcon from "../../../../assets/icons/layout/navbar/profileIconMobile.svg";
import settingsIcon from "../../../../assets/icons/layout/navbar/Settings.webp";
import logoutIcon from "../../../../assets/icons/layout/navbar/Logout.webp";


const MobileNavbar = ({isAuthenticated, data, loading, error, lang}) => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [myProfileDropped, setMyProfileDropped] = useState(false);

    const direction = useLocation().pathname;

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const profileBtnRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState(profileBtnRef.current && profileBtnRef.current.getBoundingClientRect().x - 67);
    const [isScrolled, setIsScrolled] = useState("");

    const setClassHandler = (e)=>{
        if(window.scrollY >= 1){
            setIsScrolled("_scrolled");
        }
        else if (window.scrollY < 1){
            setIsScrolled("");
        }
    };


    useEffect(() => {
        window.addEventListener("scroll", setClassHandler);
        const handleClickOutside = (event) => {
            if (!dropdownRef?.current?.contains(event.target) && !profileBtnRef?.current?.contains(event.target)) {
                setMyProfileDropped(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener("scroll", setClassHandler);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, dropdownRef.current]);

    const handleResize = () => {
        const profileBtnPosition = profileBtnRef.current && profileBtnRef.current.getBoundingClientRect();
        setDropdownPosition(profileBtnPosition && profileBtnPosition.x);

        setMyProfileDropped(false);
    }
    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);


    return (
        <div className={styles["mobile_navbar_home" + isScrolled]}>
            <div className={styles["nav_section_first_div"]}>
                <div className={styles["navbar_logo" + isScrolled]} onClick={() => {
                    if(direction === "/") {
                        window.scrollTo(0,0);
                    }else{
                        navigate('/');
                    }
                }}/>
                <div className={styles["contact_section" + isScrolled]}>
                    <img width="15" height="15" src={callIcon} alt={"call"}/>
                    <p> {lang.tel} </p>
                </div>
            </div>

            <div className={styles.navbar_second_section}>
                {!isAuthenticated ?
                    <div className={styles.navbar_second_section}>
                        <button className={styles.login_btn} onClick={() => {
                            navigate('/sign_in');
                        }}>
                            {lang.signInBtn}
                        </button>
                    </div>
                    :
                    <div className={styles.my_profile_div} ref={profileBtnRef}>
                        <button className={styles.profile_btn} onClick={() => {
                            setMyProfileDropped(!myProfileDropped);
                        }}>
                            <img width="18" height="18" src={profileIcon} alt={'profile'}/>
                        </button>
                        {myProfileDropped &&
                            <div
                                className={styles.dropdown_menu}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                ref={dropdownRef}
                                style={{
                                    left: dropdownPosition ? dropdownPosition - 67  : profileBtnRef.current.getBoundingClientRect().x -  67,
                                }}
                            >
                                <button onClick={() => {
                                    if (isAuthenticated) {
                                        navigate('/Cart');
                                        setMyProfileDropped(!myProfileDropped);
                                    }
                                }}>
                                    <img width="19" height="16" src={cartIcon} alt={"cart"}/>
                                    <span>  {lang.cart} </span>
                                </button>
                                <button onClick={() => {
                                    if (isAuthenticated) {
                                        navigate('/Wishlist');
                                        setMyProfileDropped(!myProfileDropped);
                                    }
                                }}>
                                    <img  width="16" height="14" src={wishIcon} alt={"wishlist"}/>
                                    <span> {lang.wishlist} </span>
                                </button>
                                <button onClick={() => {
                                    navigate('/my_orders');
                                    setMyProfileDropped(!myProfileDropped)
                                }}>
                                    <img  width="18" height="16" src={ordersIcon} alt={"ordersIcon"}/>
                                    <span>{lang.orders}</span>
                                </button>
                                <button onClick={() => {
                                    navigate('/profile_settings');
                                    setMyProfileDropped(!myProfileDropped)
                                }}>
                                    <img  width="18" height="18" src={settingsIcon} alt={"settingIcon"}/>
                                    <span>{lang.settings}</span>
                                </button>
                                <button onClick={() => {
                                    dispatch(logoutSuccess());
                                    setMyProfileDropped(!myProfileDropped);
                                }}>
                                    <img  width="20" height="21" src={logoutIcon} alt={"logOutIcon"}/>
                                    <span> {lang.logOut}</span>
                                </button>
                            </div>
                        }
                    </div>
                }
                <button
                    className={styles.menuBtn}
                    onClick={() => {
                        setIsSidebarOpen(true);
                    }}
                >
                    <img  width="30" height="23" src={mobileMenuIcon} alt={'menu'}/>
                </button>
                <MobileSideBar isOpen={isSidebarOpen} closeSidebar={() => {
                    setIsSidebarOpen(false)
                }}/>
            </div>
        </div>
    );
};

export default MobileNavbar;