import {DesktopCatalogSection, MobileCatalogSection} from "../../component/organism/Catalog";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Helmet} from "react-helmet";
import am from "../../assets/lang/am/PagesTitles/en.json";
import ru from "../../assets/lang/ru/PagesTitles/en.json";
import en from "../../assets/lang/en/PagesTitles/en.json";

const Catalog = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const lang = useSelector(state => state.globalLang.lang);
    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, [])
    return (
        <div>
            <Helmet>
                <title>{lang === "ARM" ? am.menu : lang === "RU" ? ru.menu : en.menu}</title>
                <meta name="description" content="
                    The Meat House-ը ցուցադրում է մի շարք մսամթերքներ, որոնք հասանելի են գնման համար՝ մանրամասնելով դրանց անվանումները,
                    նկարագրությունները, կտրվածքները, կշիռները, գները և պատկերները: Հաճախորդները կարող
                    են հեշտությամբ փնտրել և պատվիրել իրենց նախընտրած միսը առցանց:
                "/>
            </Helmet>
            {isMobile ? <MobileCatalogSection/> : <DesktopCatalogSection/>}
        </div>
    );
};

export default Catalog;