import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import categories from "../../../../../assets/lang/en/Categories/en.json";
import styles from "./style.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";


import en from "../../../../../assets/lang/en/HomePage/CategorySection/en.json";
import am from "../../../../../assets/lang/am/HomePage/CategorySection/en.json";
import ru from "../../../../../assets/lang/ru/HomePage/CategorySection/en.json";

import beefImg from "../../../../../assets/backgronds/home/categorySection/beefCategory.webp";
import lambImg from "../../../../../assets/backgronds/home/categorySection/lambCategory.webp";
import chickenImg from "../../../../../assets/backgronds/home/categorySection/chickenCategory.webp";
import porkImg from "../../../../../assets/backgronds/home/categorySection/porkCategory.webp";

import {useSelector} from "react-redux";

const MobileSlider = () => {
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const [slideActiveIndex, setSlideActiveIndex] = useState(6);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if(lang === "ARM"){
            setLangObj(am);
        }else if(lang === "ENG"){
            setLangObj(en);
        }else {
            setLangObj(ru);
        }
    }, [lang]);

    const handleClick = (input) => {
        const category = categories.find(item => item.name === input);
        const subcategories = category.subcategories.map(item => item.value);

        navigate("/catalog", {
            state: {
                subcategory: subcategories,
                activeIndex: input === "Beef" ? 1 : input === "Pork" ? 2 : input === "Lamb" ? 3 : 4,
            }
        })
    }

    return (
            <Swiper
                autoplay={
                    {
                        delay: 3000,
                        disableOnInteraction: true,
                    }
                }
                watchOverflow={true}
                effect={'fade'}
                fadeEffect={{
                    crossFade: true
                }}
                slidesPerView={2}
                slidesPerGroup={1}
                loop={true}
                centeredSlides={false}
                threshold={5}
                spaceBetween={0.5}
                className={styles.mySwiper}
                modules={[Autoplay]}
                ref={swiperRef}
                onSlideChange={(swiperCore) => {
                    if (swiperCore.activeIndex !== 2){
                        setSlideActiveIndex(swiperCore.activeIndex);
                    }
                }}

            >

                <SwiperSlide
                    onMouseEnter={() => {
                        setSlideActiveIndex(6);
                        swiperRef.current.swiper.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                        swiperRef.current.swiper.autoplay.start();
                    }}
                >
                    <div className={slideActiveIndex === 6 ? styles.sliderDiv : styles.sliderDiv_inactive}>
                        <button className={styles.category_btn} onClick={() => {
                            handleClick("Pork")
                        }}>
                            <img width="144" height="216" src={porkImg} alt={"pork"} />
                            <h3>
                                {langObj.pork}
                            </h3>
                        </button>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    onMouseEnter={() => {
                        setSlideActiveIndex(3);
                        swiperRef.current.swiper.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                        swiperRef.current.swiper.autoplay.start();
                    }}
                >
                    <div className={slideActiveIndex === 3 ? styles.sliderDiv : styles.sliderDiv_inactive}>
                        <button className={styles.category_btn} onClick={() => {
                            handleClick("Beef")
                        }}>
                            <img width="144" height="216" src={beefImg} alt={'beef'} />
                            <h3>
                                {langObj.beef}
                            </h3>
                        </button>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    onMouseEnter={() => {
                        console.log(swiperRef.current.swiper.activeIndex)
                        setSlideActiveIndex(4);
                        swiperRef.current.swiper.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                        swiperRef.current.swiper.autoplay.start();
                    }}
                >
                    <div className={slideActiveIndex === 4 ? styles.sliderDiv : styles.sliderDiv_inactive}>
                        <button className={styles.category_btn} onClick={() => {
                            handleClick("Lamb")
                        }}>
                            <img width="144" height="216" src={lambImg} alt={"lamb"}/>
                            <h3>
                                {langObj.lamb}
                            </h3>
                        </button>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    onMouseEnter={() => {
                        setSlideActiveIndex(5);
                        swiperRef.current.swiper.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                        swiperRef.current.swiper.autoplay.start();
                    }}
                >
                    <div className={slideActiveIndex === 5 ? styles.sliderDiv : styles.sliderDiv_inactive}>
                        <button className={styles.category_btn} onClick={() => {
                            handleClick("Chicken")
                        }}>
                            <img width="144" height="216" src={chickenImg} alt={"chicken"} />
                            <h3>
                                {langObj.chicken}
                            </h3>
                        </button>
                    </div>
                </SwiperSlide>
            </Swiper>
    );
};

export default MobileSlider;