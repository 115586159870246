import styles from './style.module.scss';
import OptionalDropdown from "../../../OptionalDropdownField";
import en from "../../../../../assets/lang/en/Categories/en.json";
import am from "../../../../../assets/lang/am/Categories/en.json";
import ru from "../../../../../assets/lang/ru/Categories/en.json";
import React, {useEffect, useState} from "react";

import specialOffersDisabled from "../../../../../assets/backgronds/catalog/specialOffersDisabled.png";
import specialOffersEnabled from "../../../../../assets/backgronds/catalog/specialOffersEnabled.png";
import closeIcon from "../../../../../assets/icons/catalogPage/sideBarClose.png";
import {useSelector} from "react-redux";

const Sidebar = ({value, changeState, lang}) => {
    const [specialOffersHover, setSpecialOffersHover] = useState(false);
    const checkArray = {
        ...value,
        searchOptions: '',
        specialOffers: false,
        categories: [],
    }

    const stateLang = useSelector(state => state.globalLang.lang);

    const compareTwoObject = (obj1, obj2) => {
        const objString1 = JSON.stringify(obj1);
        const objString2 = JSON.stringify(obj2);

        return objString1 === objString2;
    }

    const [categoriesObj, setCategoriesObj] = useState(en);
    useEffect(() => {
        if (stateLang === "ARM") {
            setCategoriesObj(am);
        } else if (stateLang === "ENG") {
            setCategoriesObj(en);
        } else {
            setCategoriesObj(ru);
        }
    }, [stateLang]);
    return (
        <div className={styles.sidebar_main_div}>

            <div
                onClick={() => {
                    changeState({
                        ...value,
                        specialOffers: !value.specialOffers,
                    });
                }}
                onMouseEnter={() => {
                    setSpecialOffersHover(true);
                }}
                onMouseLeave={() => {
                    setSpecialOffersHover(false);
                }}
                className={value.specialOffers || specialOffersHover ? styles.special_offers : styles.special_offers + " " + styles.enable_font_color}
            >
                {
                    value.specialOffers || specialOffersHover ?
                        <img src={specialOffersDisabled} alt={"specialOffers"}/>
                        :
                        <img src={specialOffersEnabled} alt={"specialOffers"}/>
                }
                <span>
                    {lang.specialOffers}
                </span>
            </div>
            <OptionalDropdown options={categoriesObj[0].subcategories} label={categoriesObj[0].name} value={value}
                              changeState={changeState}/>
            <OptionalDropdown options={categoriesObj[1].subcategories} label={categoriesObj[1].name} value={value}
                              changeState={changeState}/>
            <OptionalDropdown options={categoriesObj[2].subcategories} label={categoriesObj[2].name} value={value}
                              changeState={changeState}/>
            <OptionalDropdown options={categoriesObj[3].subcategories} label={categoriesObj[3].name} value={value}
                              changeState={changeState}/>

            {
                !compareTwoObject(checkArray, value) &&
                <button className={styles.clear_filters_btn} onClick={() => {
                    changeState({
                        ...value,
                        searchOptions: '',
                        specialOffers: false,
                        categories: [],
                    })
                }}>
                    <img src={closeIcon} alt={"closeIcon"}/>
                    {lang.clearAllFilters}
                </button>
            }
        </div>
    );
};

export default Sidebar;