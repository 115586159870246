import styles from './style.module.scss';


import dropDownPlusIcon from '../../../assets/icons/homePage/commonIcons/dropdownPlusIcon.png';
import dropDownMinusIcon from '../../../assets/icons/homePage/commonIcons/dropdownMinusIcon.png';

import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

const FaqDropdown = ({heading, paragraph}) => {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useSelector(state => state.screenVersion.isMobile)
    const dropdownRef = useRef(null);

    const toggleBtnRef = useRef(null);
    const toggleDropdown = () => setIsOpen(!isOpen);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!dropdownRef?.current?.contains(event.target) && !toggleBtnRef?.current?.contains(event.target)) {
                setTimeout(() => {setIsOpen(false)}, 100);
            }
        };


        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, dropdownRef.current]);

    if(isMobile){
        return (
            <div className={styles.dropdown_mobile}>
                <button className={styles.dropdown__toggle_mobile} onClick={toggleDropdown} ref={toggleBtnRef}>
                    <p>{heading}</p>
                    {isOpen ?
                        <img src={dropDownMinusIcon} alt={'dropIcon'} className={styles.minus_mobile} width="11.63" height="2.06"/>
                        :
                        <img src={dropDownPlusIcon} alt={'dropIcon'} className={styles.plus_mobile}  width="14.36" height="15.91"/>
                    }

                </button>
                {isOpen && (
                    <div
                        ref={dropdownRef}
                        className={styles.dropdown__paragraph__div_mobile}
                    >
                        <p>{paragraph}</p>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.dropdown}>
            <button className={styles.dropdown__toggle} onClick={toggleDropdown} ref={toggleBtnRef}>
                <p>{heading}</p>
                {isOpen ?
                    <img src={dropDownMinusIcon} alt={'dropIcon'}/>
                    :
                    <img src={dropDownPlusIcon} alt={'dropIcon'}/>
                }

            </button>
            {isOpen && (
                <div
                    ref={dropdownRef}
                    className={styles.dropdown__paragraph__div}
                >
                    <p>{paragraph}</p>
                </div>
            )}
        </div>
    );
};

export default FaqDropdown;
