import React from "react";
import styles from './style.module.scss';
import siteLogo from "../../../../assets/logo/footerLogoMobile.png";
import callIcon from "../../../../assets/icons/layout/navbar/Call.png";

const MobileFooter = ({lang}) => {

    return (
        <div className={styles.footer}>
            <div className={styles.footer_first_section}>
                <img  width="112" height="11" src={siteLogo} alt={"logo"}/>

                <div className={styles.contact_section}>
                    <img  width="12" height="12.63" src={callIcon} alt={"call"}/>
                    <p> {lang.tel} </p>
                </div>
            </div>
            <div className={styles.footer_second_section}>
                © 2023 The Meat House | All rights reserved
            </div>
        </div>
    );
};

export default MobileFooter;