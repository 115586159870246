import styles from './style.module.scss';

import meatBg from "../../../../assets/backgronds/aboutUs/meatBg.png";
import chili from "../../../../assets/backgronds/aboutUs/chili.png";

const AboutSectionDesktop = ({lang}) => {
    return (
        <div className={styles.collect_section}>
            <div className={styles.aboutUs_main_section}>
                    <img src={meatBg} alt={"meat"} className={styles.about_img}/>
                <div className={styles.second_section}>
                    <div className={styles.section_heading}>
                        <div>
                            <h2>
                                {lang.sectionHeading}
                            </h2>
                            <hr/>
                        </div>
                    </div>
                    <div className={styles.paragraph_div}>
                        <p>
                            {lang.paragraph}
                        </p>
                        <img src={chili} alt={"chili"} className={styles.chili_img}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSectionDesktop;