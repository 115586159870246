import CountUp from 'react-countup';

const AnimatedNumber = ({endNumber, className}) => {

    return(
       <CountUp end={endNumber} duration={5}  enableScrollSpy={true} scrollSpyOnce={true} className={className}/>
    );

};

export default AnimatedNumber;